<template>
  <div>
    <AlertDialog ref="alertDialog" name="scalertdialog" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import Ably from "ably";
import { useRoute, useRouter } from 'vue-router';
import AlertDialog from "@/components/modals/AlertDialog.vue";

const store = useStore();
const isBooking = computed(() => store.getters['auth/isBooking']);

const showNavbar: any = inject("showNavbar");
if (showNavbar) showNavbar.value = false;

const router = useRouter();
const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const alertDialog = ref(null);

const bookingid = router.currentRoute.value.params.bookingid;
const selectedbookingid = ref(null);

const showUnsavedChangesAlert = (message: string) => {
  const modal = alertDialog.value as any;
  if (modal) {
    modal.init("Warning", message, "Ok");
    modal.show();
  }
};

onMounted(() => {
  // If user is authenticated, redirect to the booking form
  if(isBooking.value) {
    channel.subscribe(
      "unsavedChangesOnFormForExistingBooking",
      (message: any) => {
        if (message?.data) {
          if (message.data.deviceid != deviceid) return;
          selectedbookingid.value = message.data.bookingid;
          showUnsavedChangesAlert(
            "There are unsaved changes on the open booking form. Please first save all the changes to load the selected booking"
          );
        }
      }
    );

    channel.subscribe("unsavedChangesOnFormForNewBooking", (message: any) => {
      if (message?.data) {
        if (message.data.deviceid != deviceid) return;
        selectedbookingid.value = message.data.bookingid;
        showUnsavedChangesAlert(
          "There are unsaved changes on the open booking form. Please first save all the changes before starting a new booking"
        );
      }
    });

    channel.subscribe("appointmentLoading", (message: any) => {
      if (message?.data) {
        if (message.data.deviceid != deviceid) return;
        selectedbookingid.value = message.data.bookingid;
        window.close();
      }
    });

    channel.publish("appointmentSelected", {
      deviceid: deviceid,
      bookingid: bookingid,
    });

    // Wait for 2 seconds, to check if there is form open on another tab and the appointment loaded
    
    setTimeout(() => {
      if (bookingid != selectedbookingid.value) {
        // Open a new tab with this booking loaded
        window.open(`/diary/${bookingid}`, "_blank");
        window.close();
      }
    }, 2000);


  }
});
</script>