import { createStore } from "vuex";

import "@/assets/sass/bootstrap/theme/compact/bootstrap.min.css";

// Modules
import app from "./app";
import auth from "./auth";
import customers from "./customers";
import inspectors from "./inspectors";
import dictionary from "./dictionary";
import customerdictionary from "./customerdictionary";
import offices from "./offices";
import reports from "./reports";
import feedback from "./feedback";
import sysadmin from "./sysadmin";
import diary from "./diary";
import emailtemplates from "./emailtemplates";
import smstemplates from "./smstemplates";
import audit from "./audit";
import property from "./property";
import pvbookings from "./pvbookings";
import remotesupport from "./remotesupport";
import usermanagement from "./usermanagement";
import autoreports from "./autoreports";


const store = createStore({
  modules: {
    app,
    auth,
    customers,
    inspectors,
    dictionary,
    customerdictionary,
    offices,
    reports,
    feedback,
    sysadmin,
    diary,
    emailtemplates,
    smstemplates,
    audit,
    property,
    pvbookings,
    remotesupport,
    usermanagement,
    autoreports
  },
});

export default store;
