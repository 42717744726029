<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="input-group">
      <label for="report-search" class="sr-only">Search</label>
      <input id="report-search" type="search" class="form-control" v-model="search" :placeholder=placeholder autocomplete="off" />
      <span class="input-group-btn ml-2">
        <button type="submit" class="btn btn-outline-primary">
          <i class="fas fa-search"></i>
        </button>
      </span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// Define the placeholder prop.
const props = defineProps<{
  placeholder: string;
}>();

const store = useStore();
const router = useRouter();

// Computed setter and getter for the search value that uses the store
const search = computed({
  get: () => store.state.autoreports.search,
  set: (val: string | undefined) => store.commit('autoreports/setSearch', val),
});

// Actions using the store.dispatch
const resetCurrentPage = async () => {
  await store.dispatch('autoreports/resetCurrentPage');
};

// Methods converted to arrow functions
const onSubmit = async () => {
  await router.push({ path: 'autoreports', query: { search: search.value }});
  await resetCurrentPage();
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
