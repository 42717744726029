<template>
  <div
    :id="name"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`accessnotes-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="accessnotes-modal-label">
            Access confirmation
          </h5>
          <button
            id="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <fieldset>
              <div>
                <tiptap-editor
                  :key="tiptapeditorkey"
                  :content="tiptapaccessnotes"
                  @blur="setAccessnotes"
                  style="min-height: 50vh;"
                />
              </div>
            </fieldset>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click.prevent="save()"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, inject, watch, defineProps, defineEmits, defineExpose } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { Booking } from '@/models';
import { useToast } from "vue-toastification";
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import TiptapEditor from '@/components/editor/TiptapEditor.vue';
import { emitEvent } from '@/eventBus';

// defineProps to specify the type of props
const props = defineProps<{
  name: string;
  booking: Booking;
}>();

// States
const tiptapeditorkey = ref<string>('');
const tiptapaccessnotes = ref<string>('');

const store = useStore();
const route = useRoute();

// Actions
const setBookingDeep = async (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('diary/setBookingDeep', payload);
};

const saveBooking = async (booking: Booking): Promise<any> => {
  return store.dispatch('diary/saveBooking', booking);
};

// Mutations
const resetUnsavedChanges = () => {
  store.commit('diary/resetUnsavedChanges');
};

// Injected properties
const actProperty: any = inject('actProperty');
const toasted = useToast();
const emit = defineEmits(['hide']);

// Watches
watch(() => props.booking, (newValue) => {
  tiptapaccessnotes.value = newValue.accessnotes;
});

// Methods
const init = () => {
  tiptapeditorkey.value = uuidv4();
  tiptapaccessnotes.value = props.booking.accessnotes;
};

const setAccessnotes = (val: string) => {
  tiptapaccessnotes.value = val;
};

const save = async () => {
  await setBookingDeep({path: 'accessnotes', data: tiptapaccessnotes.value });
  if (!isNewBooking()) {
    // call checkAndSave from parent component
    emitEvent("checkAndSave");
    // await saveBooking(props.booking)
    //   .then(() => resetUnsavedChanges())
    //   .catch((err: any) => toasted.error(err.message));
  }
  hide();
};

const isNewBooking = (): boolean => {
  return route.params.id === "new";
};

const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = () => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
  }
};
defineExpose({init, show, hide});
</script>

<style scoped lang="scss">
</style>