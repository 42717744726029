<template>
  <div
    :id="id"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered" role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sms-modal-label">
            <span>PV Bookings Scheduler</span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div
          class="modal-body"
          :key="bookingid"
          style="  height: 80vh;      /* Set the height to 80% of the viewport height */
            overflow-y: auto;  /* Enable vertical scrollingif content overflows vertically */
            overflow-x: hidden;"
        >
          <div class="col-sm-12">
            <div>
              <DxScheduler
                id="pv-diary-scheduler"
                :key="`pv-diary-scheduler-${bookings.length}`"
                ref="schedulerRef"
                time-zone="Etc/UTC"
                class="diary-scheduler"
                :data-source="bookings"
                :current-date="currentdate"
                :views="views"
                :groups="groups"
                :height="'100%'"
                :width="'100%'"
                :show-all-day-panel="true"
                :all-day-panel-mode="'isAllDay'"
                :first-day-of-week="6"
                :start-day-hour="5"
                :end-day-hour="23.5"
                :cell-duration="15"
                :allow-adding="true"
                :allow-updating="false"
                :editing="editing"
                :show-current-time-indicator="false"
                :indicator-time="new Date()"
                recurrence-exception-expr="recurrenceExclDates"
                recurrence-edit-mode="model"
                current-view="day"
                text-expr="summary"
                start-date-expr="startdatefordiary"
                end-date-expr="enddatefordiary"
                appointment-template="bookingTemplate"
                resource-cell-template="inspectorTemplate"
                appointment-tooltip-template="bookingTooltipTemplate"
                time-cell-template="timeCellTemplate"
                max-appointments-per-cell="4"
                :cross-scrolling-enabled="true"
                :on-content-ready="schedulerContentReady"
                :on-appointment-updated="onAppointmentUpdated"
              :on-appointment-rendered="onAppointmentRendered"
              @appointment-click="onAppointmentClick"
              @appointment-dbl-click="onAppointmentDblClick"
              >
                <DxResource
                  :data-source="employees"
                  :allow-multiple="false"
                  label="Inspectors"
                  field-expr="employeeId"
                />

                <template #timeCellTemplate="{ data: cellData }">
                  <TimeCellTemplate :cell-data="cellData" />
                </template>

                <template #inspectorTemplate="{ data: employee }">
                  <InspectorTemplate :employee="employee" :pidiary="true" />
                </template>
                <template #bookingTemplate="{ data }">
                  <BookingTemplate
                  :key="`pidiary-scheduler-${bookings.length}`"
                  :template-model="data"
                  :highlight="selectedbookingid"
                  @show-booking-cancel-modal="showCancelBookingModal"
                  @locate-booking="locatebooking"
                  :showtraveltime="showtraveltime"
                />
                </template>

                <template #bookingTooltipTemplate="{ data }">
                  <BookingTooltipTemplate
                    :template-model="data"
                  />
                </template>
                <DxScrolling mode="standard" />
              </DxScheduler>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>    
    <BookingCancelModal
      name="pv-scheduler-cancel-booking-modal"
      ref="bookingCancelModal"
      @hide="hideBookingCancelModal"
    />
    <BookingDetailModal
          id="pvschedular-booking-detail-model"
         ref="bookingDetailModal"
      />
</template>

<script setup lang="ts">
import $ from "jquery";
import _get from "lodash/get";
import {
  ref,
  computed,
  defineEmits,
  inject,
  defineProps,
  defineExpose,
  nextTick,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import TimeCellTemplate from "../../pages/Diary/TimeCellTemplate.vue";
import InspectorTemplate from "../../pages/Diary/InspectorTemplate.vue";
import BookingTemplate from "../../pages/Diary/BookingTemplate.vue";
import BookingTooltipTemplate from "../../pages/Diary/BookingTooltipTemplate.vue";
import BookingCancelModal from "@/components/modals/BookingCancelModal.vue";
import BookingDetailModal from "@/components/modals/BookingDetailModal.vue";
import {
  DxScheduler,
  DxResource,
  DxScrolling,
} from "devextreme-vue/scheduler";
import { Booking, BookingBlock, Customer, Inspector } from "@/models";
import moment from "moment";
import { debounce } from "lodash";
const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  showAuditLogs: {
    type: Boolean,
    default: true,
  },
  id: String,
});
const setBooking = (booking: any) => {
  store.commit("diary/setBooking", booking);
};
const updateBooking = (booking: Booking) => {
  return store.dispatch("diary/updateBooking", booking);
};
const setCurrentdate = (currentdate: Date) => {
  store.commit("diary/setCurrentdate", currentdate);
};
const getBooking = (id: string) => {
  return store.dispatch("diary/getBooking", id);
};
const saveBooking = (booking: Booking) => {
  return store.dispatch("diary/saveBookingFromScheduler", booking);
};
const email = computed(() => store.getters["auth/email"]);
const emit = defineEmits(["selectBooking","onAppointmentUpdated"]);
const employees = computed(() => [booking.value.inspector]);
const store = useStore();
const realtime: any = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();
const schedulerRef = ref(null);
const booking = ref(new Booking());
const deletebookingEmailModal = ref(null);
const selectedbookingid = ref("");
const showtraveltime = ref(false);
const bookingid = computed(() => booking.value.id);
const bookingCancelModal = ref(null);
const bookingDetailModal = ref(null);
const editing = ref({
  allowAdding: true,
  allowDeleting: true,
  allowUpdating: true,
  allowResizing: true,
  allowDragging: true,
});
// Methods
const init = (bookingData: BookingBlock) => {
  booking.value = bookingData;
};
const _currentdate = computed(() => store.getters["diary/currentdate"]);
const statebookings = computed((): Booking[] => store.getters["diary/list"]);
const bookings = computed(() => {
  return statebookings.value.filter((f:Booking) => f.inspector.id === booking.value?.inspector?.id && !f.cancelled);
});
const getBookingWithoutStoringInState = (payload: {
  id: string;
  cancelled: string;
}) => {
  return store.dispatch("diary/getBookingWithoutStoringInState", payload);
};
const show = () => {
  if ($(`#${props.id}` + "Backdrop").length == 0) {
    const backdropDiv = $(
      '<div class="modal-backdrop fade show" id="' +
        props.id +
        'Backdrop"></div>'
    );
    $("body").append(backdropDiv);
    let el = $(`#${props.id}`);
    el.show();
  }
};

const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + "Backdrop").remove();
  }
  $(`#${props.id}`).hide();
};
const groups = computed(() => {
  return ["employeeId"];
});

const views = computed(() => {
  return [];
});

const onAppointmentRendered = (e: any) => {
  if (e.appointmentData?.isAllDay) {
    e.appointmentElement.style.height = `30px`;
    const cssText = e.appointmentElement.style.cssText;
    const startIndex = cssText.indexOf("translate(");
    if (startIndex >= 0) {
      const endIndex = cssText.indexOf(")", startIndex);
      if (endIndex >= startIndex) {
        const translate = cssText.substring(startIndex + 10, endIndex);
        if (translate) {
          const splits = translate.split(",");
          if (splits?.length) {
            const translateY = splits[1].trim();
            if (translateY === "11.5px") {
              e.appointmentElement.style.marginTop = "20px";
            }
          }
        }
      }
    }
  }
};


onMounted(() => {
  channel.subscribe("bookinglocked", (message: any) => {
    if (message?.data?.bookingid) {
      bookings.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          b.locked = true;
          b.lockedby = message.data.lockedby;
        } else if (b.lockedby === message.data.lockedby) {
          b.locked = false;
          b.lockedby = "";
        }
      });
    }
  });
  channel.subscribe("appointmentChanged", async (message: any) => {
    const index = bookings.value.findIndex(
      (b: Booking) => b.id === message.data.bookingid
    );
    if (message?.data && message.data.bookingid) {
        await getBookingWithoutStoringInState({
          id: message.data.bookingid,
          cancelled: "false",
        }).then(async (b: Booking) => {
          if (b.id && index != -1) {
            await updateBooking(b);
            bookings.value[index] = b;
          }
        });
    }
  });

  channel.subscribe("bookingunlocked", (message: any) => {
    if (message?.data?.bookingid) {
      bookings.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          bookings.value[i].locked = false;
          bookings.value[i].lockedby = "";
        } else if (b.lockedby === message.data.lockedby) {
          bookings.value[i].locked = false;
          bookings.value[i].lockedby = "";
        }
      });
    }
  });
});

const schedulerContentReady = (e: any) => {
  e.component.getWorkSpaceScrollable().option('showScrollbar', 'always');
  e.component.getWorkSpaceScrollable().option('useNative', 'true');
};
const onAppointmentClick = (e: any) => {
  e.cancel = true;
  dbonAppointmentClick(e);
};



const dbonAppointmentClick = debounce((e: any) => {
  if (e.appointmentData || e.data) {
    let booking: Booking | undefined = undefined;
    if (e.appointmentData) booking = e.appointmentData;
    else if (e.data) booking = e.data;
    if (booking && booking?.subtype != Booking.PERSONAL) {
      let modal = undefined;
      modal = bookingDetailModal.value as any;
      modal.init(booking);
      modal.show();
    }
  }
}, 250);
const currentdate = computed({
  get: () => _currentdate.value,
  set: (val) => {
    // Assuming setCurrentdate is an adapted method for Vue 3
    setCurrentdate(val);
  },
});
const onAppointmentUpdated = async (e: any) => {
  if (e.appointmentData) {
    let updatedBooking = statebookings.value.find(
      (b: any) => b.id === e.appointmentData.id
    );
    if (
      !booking.value.locked ||
      (booking.value.locked && booking.value.lockedby === email.value)
    ) {
      if (updatedBooking && updatedBooking.jobtype === 'property visit') {
        const appointmentDataBooking: Booking = new Booking({...e.appointmentData, inspector: new Inspector({id: e.appointmentData.employeeId})});         
        appointmentDataBooking.startdate = actProperty.roundDate(
          appointmentDataBooking.startdate
        );
        appointmentDataBooking.enddate = actProperty.roundDate(
          appointmentDataBooking.enddate
        );
        // Check if inspector has changed
        saveBooking(appointmentDataBooking).then((b: Booking) => {
          channel.publish("appointmentChanged", {
            deviceid: deviceid,
            bookingid: b.id,
            source: "Scheduler",
          });
        });
      } else {
        emit("onAppointmentUpdated", e, "");
      }
    }
  }
};
const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};


const locatebooking = async (payload: { bookingid: string; date: string }) => {
  const locateDate = moment(payload.date).utc(true).format("DD-MM-YYYY");
  const currDate = formatDate(currentdate.value);
  if (currDate != locateDate && payload.bookingid) {
    await getBooking(payload.bookingid).then(async (booking: Booking) => {
      setCurrentdate(booking.startdateAsDate);
    });
  } else {
    const booking: Booking | undefined = bookings.value.find(
      (b: Booking) => b.id === payload.bookingid
    );
  }
};

const showCancelBookingModal = (book: Booking) => {
  const modal = bookingCancelModal.value as any;
  modal.init(book);
  modal.show();
};



const selectBooking = (booking: Booking, selectedfrom: string) => {  
  emit("selectBooking", booking, "");
  // if (booking.googleid || !booking.jobtype) {
  //   locatebooking({ bookingid: booking.id, date: booking.startdate });
  //   selectedbooking.value = booking;
  //   highlightbooking(booking?.id, booking?.inspector?.id, "");
  // } else if (booking.subtype) {
  //   locatebooking({ bookingid: booking.id, date: booking.startdate });
  //   selectedbooking.value = booking;
  //   highlightbooking(booking?.id, booking?.inspector?.id, "");
  // } else {
  //   unsavedchangesonform.value = false;
  //   locatebooking({ bookingid: booking.id, date: booking.startdate });
  //   channel.publish("appointmentSelected", {
  //     deviceid: deviceid,
  //     bookingid: booking.id,
  //     inspectorid: booking.inspector?.id,
  //     googleid: booking.googleid,
  //     subtype: booking.subtype,
  //     jobtype: booking.jobtype,
  //   });
  //   selectedbooking.value = booking;
  //   if (selectedbooking.value.id != selectedbookingid.value) {
  //     // Open a new tab with this booking loaded
  //     // Wait for 2 seconds, to check if there is form open on another tab and the appointment loaded
  //     setTimeout(() => {
  //       if (selectedbooking.value.id != selectedbookingid.value) {
  //         // Open a new tab with this booking loaded
  //         window.open(`/diary/${selectedbooking.value.id}`, "_blank");
  //         highlightbooking(
  //           selectedbooking.value?.id,
  //           selectedbooking.value?.inspector?.id,
  //           ""
  //         );
  //       }

  //       if (unsavedchangesonform.value) {
  //         selectedbookingid.value = "";
  //         unsavedchangesonform.value = false;
  //       }
  //     }, 2000);
  //   }
  // }
};

const onAppointmentDblClick = (e: any) => {
  e.cancel = true;
  dbonAppointmentClick.cancel();
  if (e.appointmentData || e.data) {
    let booking: Booking | undefined = undefined;
    let selectedfrom: string = "";
    if (e.appointmentData) {
      selectedfrom = "scheduler";
      booking = e.appointmentData;
    } else if (e.data) {
      selectedfrom = "searchtable";
      booking = e.data;
    }
    if (
      !booking.locked ||
      (booking.locked && booking.lockedby === email.value)
    ) {
      if (booking) {
        if (!booking.subtype) {
          selectBooking(booking, selectedfrom);
        }
      }
    }
  }
};
const hideBookingCancelModal = (val: boolean, book: Booking) => {
  if (
    val &&
    book.jobtype != Booking.PERSONAL &&
    book.subtype != Booking.PERSONAL &&
    !book.googleid
  ) {
    setBooking(book);
    // nextTick(() => {
    //   showDeleteBookingEmailDialog();
    // });
  }
};
const showDeleteBookingEmailDialog = () => {
  const modal = deletebookingEmailModal.value as any;
  modal.init();
  modal.show();
};
defineExpose({ init, show, hide });
</script>
<style scoped lang="scss">
.modal-body {
  max-height: 80vh;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.cancelled-booking {
  background: repeating-linear-gradient(
    135deg,
    rgb(136, 8, 8, 0.2),
    rgb(136, 8, 8, 0.2) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}

.cancelled-booking:hover {
  background: repeating-linear-gradient(
    135deg,
    rgb(217, 218, 219),
    rgb(217, 218, 219) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}
</style>

<style lang="scss">
.dx-scheduler-group-header,
.dx-scheduler-date-table-cell {
  position: relative;
}

.dx-scheduler-group-header-content > .selected {
  background-color: rgb(197, 197, 197);
}

.datebox .dx-texteditor-input {
  width: 200px !important;
}

.today-button {
  min-width: 73px;
}

.dx-scheduler-date-table-other-month.dx-scheduler-date-table-cell {
  opacity: 1;
  color: rgba(0, 0, 0, 0.3);
}

.dx-scheduler-date-table-cell .dx-template-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 6px;
}

.dx-scheduler-group-header,
.dx-scheduler-cell-sizes-horizontal {
  width: 150px;
}

.dx-scheduler-cell-sizes-vertical {
  height: 1.1669vh !important;
}

.dx-scheduler-time-panel-cell {
  border: none;
}

.dx-scheduler-date-table-row:nth-child(4n + 1) {
  border-top: 3px solid #25374699;
}

.dx-scheduler-date-table-row:nth-child(4n + 2) {
  border-top: hidden !important;
}

.dx-scheduler-date-table-row:nth-child(4n + 4) {
  border-top: hidden !important;
}

.dx-template-wrapper {
  overflow: hidden !important;
}

.dx-scrollable-container {
  overflow-y: auto !important;
}

.datebox ::v-deep .dx-datebox-wrapper-calendar > .dx-calendar {
  min-width: 282px;
}

.dx-scheduler-appointment {
  background-color: transparent !important;
}

.dx-overlay-content {
  max-width: 100vw !important;
}

.dx-scheduler-header {
  display: none !important;
}

.dx-scheduler-header-panel-container {
  border-top: 1px solid #ebebeb;
}

.datebox {
  border: none !important;
}

tr {
  cursor: pointer;
}

.search-table-container {
  max-height: 220px;
  overflow-y: scroll;
}

.selectedbooking {
  //background-color: #c7e0f5;
  font-weight: 700;
}

.selectall-button {
  height: 40px;
}

.dx-scheduler *::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dx-scheduler-work-space-flex-container ::-webkit-scrollbar:vertical,
.dx-scheduler-work-space-flex-container ::-webkit-scrollbar-thumb:vertical {
  display: none !important;
}

.dx-datagrid {
  border: 1px solid #253746 !important;
  border-radius: 0.25rem;
}

.dx-datagrid-headers {
  padding-right: 0 !important;
}

.dx-header-row {
  color: #eadb40;
  background-color: #253746 !important;
}

.dx-row-focused.cancelled-booking,
.dx-row-focused.cancelled-booking:hover {
  color: #ffffff !important;
  background: repeating-linear-gradient(
    135deg,
    #5c96c5,
    #5c96c5 5px,
    #052c4c 5px,
    #052c4c 10px
  );
  background-color: transparent !important;
  font-weight: bolder;
}

.dx-row-focused,
.dx-row-focused:hover {
  font-weight: bolder;
}

.dx-row-focused > .transparent-background,
.dx-row-focused > .transparent-background:hover {
  //color: #212529 !important;
}

.dx-scheduler-group-header {
  text-align: center !important;
}

.transparent-background {
  background: transparent !important;
}
</style>

<style>
.dx-scheduler-all-day-table,
.dx-scheduler-all-day-title,
.dx-scheduler-all-day-table-row {
  height: 70px !important;
  line-height: 70px !important;
}

.dx-scheduler-all-day-appointmentx {
  height: 2.4669vh !important;
}

.dx-scheduler-appointment-reduced-icon {
  display: none !important;
}
</style>
