import { Module } from 'vuex';
import { RootState } from '../types';
import { AutoReportsState } from './types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

import { Report, Room } from '@/models';

// Default state
export const state: AutoReportsState = {
  current: new Report(),
  currentoffice: "",
  photos: [],
  linked: [],
  list: [],
  month: undefined,
  monthlyFeesTotal: null,
  monthlyReportCount: null,
  search: undefined,
  updates: [],
  currentPage: 1,
  pageLimit: 20,
  gallerydisplaymode: '',
  dataentrystatus: undefined,
  filter: undefined,
};

// Use namespaced Vuex modules
const namespaced: boolean = true;

// Module export
const autoreports: Module<AutoReportsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default autoreports;
