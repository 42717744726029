import _ from 'lodash';

import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AutoReportsState } from './types';

import { Report } from '@/models/report.model';
import { Fee, Item, Room, Section, Type } from '@/models';

/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
export const getters: GetterTree<AutoReportsState, RootState> = {

  /**
   * The currently-queried list of reports, returned from GET {API_URL}/reports
   */
  list: (state: AutoReportsState): Report[] => state.list,

  /**
   * The Linked Autoreports of the currently queried Report, returned from GET {API_URL}/reports?linked=XXXX
   */
  linked: (state: AutoReportsState): Report[] => state.linked,

  /**
   * The current Report, created fresh or returned from GET {API_URL}/report
   */
  current: (state: AutoReportsState): Report => state.current,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   */
  updates: (state: AutoReportsState): string[] => state.updates,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   */
  hasUnsavedChanges: (state: AutoReportsState): boolean => state.updates.length > 0,

  /**
   * The list of rooms which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportRooms: (state: AutoReportsState): Room[] => _.uniq(state.current.rooms),
  /**
   * The list of sections which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportSections: (state: AutoReportsState): Section[] => {
    return state.current.rooms.flatMap((room: Room) => room.sections);
  },

  /**
   * The list of types which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportTypes: (state: AutoReportsState, getters: any): Type[] => {
    return getters.currentReportSections.flatMap((section: Section) => section.types);
  },

  /**
   * The list of items which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportItems: (state: AutoReportsState, getters: any): Item[] => {
    return getters.currentReportTypes.flatMap((type: Type) => type.items);
  },

  /**
   * The number (order) of the Item in the Report
   */
  itemNumber: (state: AutoReportsState, getters: any) => (item: any): number => {
    return getters.currentReportItems.findIndex((x: Item) => x._uuid === item._uuid) + 1;
  },

  /**
   * The current office
   */
  currentoffice: (state: AutoReportsState): string => state.currentoffice,

  /**
   * The current office
   */
  gallerydisplaymode: (state: AutoReportsState): string => state.gallerydisplaymode,


};
