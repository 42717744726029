import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import store from "./store";
// import './registerServiceWorker';

import Ably from "ably";

import { Amplify, Auth } from "aws-amplify";
import aws_exports from "@/aws-exports";

import VueMultiselect from "vue-multiselect";

import Toast, {
  useToast,
  TYPE,
} from "vue-toastification";

import VueScrollTo from "vue-scrollto";
import CKEditor from "@ckeditor/ckeditor5-vue";

import { svgSpriteDirectivePlugin } from "vue-svg-sprite";

import * as utilities from "./utilities";
import { createVfm } from "vue-final-modal";

import "sweetalert2/dist/sweetalert2.min.css";

import Avatar from "vue3-avatar";
//Vue.config.productionTip = false;

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import Popper from "vue3-popper";

import "floating-vue/dist/style.css";
import FloatingVue from "floating-vue";
import { options } from "floating-vue";

const app = createApp(App);

app.component("multiselect", {
  extends: VueMultiselect,
  props: {
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: "id",
    },
  },
});

app.component("multiselect-text", {
  extends: VueMultiselect,
  props: {
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    tagPlaceholder: {
      type: String,
      default: "<enter> to add",
    },
  },
});

app.component("multiselect-multiple", {
  extends: VueMultiselect,
  props: {
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "name",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    preserveSearch: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: "<enter> to select",
    },
    showNoResults: {
      type: Boolean,
      default: false,
    },
    tagPlaceholder: {
      type: String,
      default: "<enter> to add new",
    },
    trackBy: {
      type: String,
      default: "id",
    },
  },
});

app.component("singleselect-number", {
  extends: VueMultiselect,
  props: {
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
});

app.component("singleselect-text", {
  extends: VueMultiselect,
  props: {
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
});

app.component("avatar", Avatar);
const ablyapikey: string = process.env.VUE_APP_ABLY_API_KEY || "";
const realtime = new Ably.Realtime(ablyapikey);
realtime.connection.once("connected");

app.use(router);
app.use(store);

app.use(Toast, {
  maxToasts: 3,
  newestOnTop: true,
  position: "bottom-center",
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      timeout: 3000,
      closeButton: false,
      hideProgressBar: true,
    },
    [TYPE.WARNING]: {
      timeout: 3000,
      hideProgressBar: true,
      closeButton: false,
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
      hideProgressBar: true,
      closeButton: false,
    },
  },
});

app.provide("actProperty", utilities);
app.provide("realtime", realtime);

app.config.errorHandler = (err: any, vm, info) => {
  console.log(err);
  const toast = useToast();
  toast.error(err.message, { timeout: 3000 });
};

Amplify.configure(aws_exports);
app.config.globalProperties.$Amplify = Amplify;
app.config.globalProperties.$realtime = realtime;

app.use(VueScrollTo, {
  duration: 250,
  offset: -65,
});

app.use(CKEditor);

app.use(svgSpriteDirectivePlugin, {
  url: "/assets/remixicon.symbol.svg",
  class: "remixicon",
});

const vfm = createVfm();
app.use(vfm);

app.use(VueViewer);

app.component("Popper", Popper);

options.themes.tooltip.html = true;

const tooltipOptions = {
  themes: {
    "act-tooltip": {
      $extend: "tooltip",
      triggers: ["hover"],
      placement: "bottom",
      html: true,
    },
  },
};
app.use(FloatingVue, tooltipOptions);

app.mount("#app");

// Log all Vuex mutations during development
if (process.env.NODE_ENV === "development") {
  store.subscribe((mutation, state) => {
    console.log("☢", mutation.type, mutation.payload);
  });
}

export default app;
