<template>
  <div class="file-upload mt-2" :class="{ drag: isDragging }" @dragover.stop.prevent="onDragOver"
    @dragleave.stop.prevent="onDragLeave" @drop.stop.prevent="onDrop">
    <div v-if="props.path != 'emailattachmentdocuments'">
      <DocumentComponent v-for="(document, $index) in _documents" :readonly="readonly"
        :key="actProperty.hashObj(document)" :document="document" :showdocument="entity === 'customer'"
        @click.stop="onClick($index)" @delete="deleteDocument($index)" />
    </div>

    <span v-if="uploadingCount"><i class="fas fa-spinner fa-spin"></i></span>

    <label v-if="props.path != 'emailattachmentdocuments' && !readonly && !uploadingCount && (!_documents || !_documents.length)">
      <span class="btn btn-sm btn-outline-secondary document-upload-button" :class="buttonclass">
        <div>
          <i class="fas fa-plus"></i>
          {{ buttontext }}
        </div>
      </span>
      <input type="file" :accept="validtypes.join(',')" multiple ref="file" v-on:change="onFileInputChange" />
    </label>
    <label v-if="props.path == 'emailattachmentdocuments'">
      <span class="btn btn-sm btn-outline-secondary document-upload-button">
        <div>
          <i class="fas fa-plus"></i>
          {{ buttontext }}
        </div>
      </span>
      <input type="file" :accept="validtypes.join(',')" multiple ref="file" v-on:change="onFileInputChange" />
    </label>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import moment from "moment-timezone";
import { computed, ref, onMounted, inject, defineEmits, defineProps , PropType} from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import sanitize from "sanitize-filename";
import slugify from "slugify";
import DocumentComponent from "@/components/document/Document.vue";
import { Document, Customer, Booking } from "@/models";
import { Storage } from 'aws-amplify';

const props = defineProps({
  path: {type: String as PropType<string>, default: ''},
  documents: {type: Object as PropType<Document[]>, default: []},
  buttontext: {type: String as PropType<string>, default: 'Document'},
  entity: {type: String as PropType<string>, default: 'customer'},
  readonly: { type: Boolean as PropType<boolean>, required: false, default: false },
  buttonclass: { type: String as PropType<string>, required: false, default: '' },
});

const { path, documents, buttontext, entity, readonly } = props;

const actProperty: any = inject('actProperty');
const toasted = useToast();
const file = ref(null);
const store = useStore();

const emit = defineEmits(['documentadded', 'documentdeleted', 'clicked'])

const customer = computed((): Customer => store.getters['customers/current']);
const deviceKey = computed((): string => store.getters['auth/deviceKey']);
const booking = computed((): Booking => store.getters['diary/booking']);

const isDragging = ref(false);
const uploadingCount = ref(0);
const validtypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  '.doc',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/jpeg',
  'image/png',
  'image/jpg',
  '.xls',
  '.xlsx',
];

// Computed properties
const _documents = computed(() => documents);

const setCustomerDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('customers/setCustomerDeep', payload);
}

const setBookingDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('diary/setBookingDeep', payload);
}

// Methods converted to the Composition API style
const onDragOver = (event: DragEvent) => {
  isDragging.value = true;
};

const onDragLeave = (event: MouseEvent) => {
  if (event.currentTarget === event.target) {
    isDragging.value = false;
  }
};

const onDrop = (event: DragEvent) => {
  isDragging.value = false;
  const files: FileList | null = _.get(event, "dataTransfer.files", null);
  uploadFiles(files);
};

const onFileInputChange = (event: Event) => {
  const files = _.get(file.value, "files", null);
  uploadFiles(files);
};

const onClick = (index: number) => {
  emit('clicked', index);
};

const uploadFiles = async (files: FileList | null) => {
  try {
    if (null === files || 0 === files.length) {
      return;
    }

    uploadingCount.value = 0;

    // S3 options
    const options: any = {
      contentType: "application/pdf",
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
      level: "public",
    };

    for (let i = 0; i < files.length; i++) {
      let file: File | null = files.item(i);

      if (file === null) {
        continue;
      }

      const { name, type, lastModified } = file;

      if (validtypes.indexOf(type) < 0) {
        actProperty.displayError(`${sanitize(name)} is not a valid type.`);
        continue;
      }

      // Increase uploadingCount (displays loading spinner)
      ++uploadingCount.value;

      // Add timestamp to make each device upload unique, and sanitise input filename
      let deviceKeyPrefix = `${deviceKey.value}`;
      if (!deviceKey.value) deviceKeyPrefix = `${entity}documents`;
      else deviceKeyPrefix = `${deviceKey.value}`;
      const s3Filepath = `${deviceKeyPrefix}/${moment().format(
        "x"
      )}/${slugify(sanitize(name))}`;

      if (file?.type) {
        options.contentType = file.type;
      }

      // Save the File to S3
      await Storage.put(s3Filepath, file, options)
        .then(({ key }: any) => {
          if (!key || !key.length) {
            --uploadingCount.value;
            throw Error("Could not determine uploaded URL");
          }

          // Create the Document from the S3 response
          const document = new Document({
            src: actProperty.s3Origin(key),
            createdAt: actProperty.datetimeToUTC(lastModified),
          });

          // Add Document to documents array
          let documents: Document[] = [];

          if (entity === "customer") {
            documents = _.get(customer.value, path).concat([document]);
            // Update Vuex
            setCustomerDeep({ path: path, data: documents });
          } else if (entity === "booking") {
            documents = _.get(booking.value, path).concat([document]);
            // Update Vuex
            setBookingDeep({ path: path, data: documents });
          }
          --uploadingCount.value;
        })
        .catch((error: Error) => {
          // Adjust uploadingCount and rethrow error
          --uploadingCount.value;
          throw error;
        });
    }
  } catch (error) {
    actProperty.displayError(error);
  }
};

const deleteDocument = async (index: number) => {
  actProperty.confirmPrompt().then(() => {
    // Update documents array
    _documents.value.splice(index, 1);

    if (entity === "customer") {
      // Update Vuex
      setCustomerDeep({ path: path, data: _documents.value })
        .then(() => toasted.success("Document removed"))
        .catch((err: any) => actProperty.displayError(err));
    } else if (entity === "booking") {
      // Update Vuex
      setBookingDeep({ path: path, data: _documents.value })
        .then(() => toasted.success("Document removed"))
        .catch((err: any) => actProperty.displayError(err));
    }
    emit("documentdeleted");
  }).catch(e => {});
};

// Lifecycle hooks
onMounted(() => {
  uploadingCount.value = 0;
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.drag * {
  pointer-events: none;
}

.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;

  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;

  input[type="file"] {
    display: none;
  }
}

.blink-red {
  animation: blinker 1s linear infinite;
  color: red;
  border-color: red;
}
</style>