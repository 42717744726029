<template>
  <div class="report">
    <div class="jumbotron">
      <div v-if="errormessage">
        <h2><b>Please give our office a call. 0208 896 2232.</b></h2>
        <p class="lead">
          {{ errormessage }}
        </p>
      </div>
      <div v-else-if="message">
        <h1 class="display-4">Thank you!</h1>
        <p class="lead">
          {{ message }}
        </p>
      </div>
      <div v-else>
        <h1 class="display-4">Loading</h1>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import moment from "moment-timezone";
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Booking, Address, EmailLog, EmailLogs, Tenant, Inspector } from "@/models";
import {
  differenceInMinutes,
  bookingdateformat,
  datetimeToUTC,
} from "@/utilities";
import Ably from "ably";
import AddressVue from "@/components/customerdictionary/Address.vue";
const store = useStore();
const route = useRoute();
const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty"); // Injected property
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const invcivmap: Map<string, string> = new Map([
  ["yes", "yes"],
  ["no", "no"],
  ["option1", "yes"],
  ["option2", "no"],
]);

const cosocmap: Map<string, string> = new Map([
  ["yes", "yes"],
  ["option1", "yes"],
  ["no", "no"],
  ["option2", "no"],
  ["option3", "no"],
  ["option4", "callback"],
  ["callback", "option4"],
]);

const pvmap: Map<string, string> = new Map([
  ["option1", "yes"],
  ["option2", "yes"],
  ["option3", "no"],
  ["option4", "no"],
]);

const getBookingUnauthenticated = async (id: string): Promise<Booking> => {
  return await store.dispatch("diary/getBookingUnauthenticated", id);
};

const getBookingsUnauthenticatedForInspectorWithDate = async (payload?: {
  date?: string;
  inspectorid?: string;
}): Promise<Booking[]> => {
  return await store.dispatch(
    "diary/getBookingsUnauthenticatedForInspectorWithDate",
    payload
  );
};

const saveBooking = async (booking: Booking): Promise<Booking> => {
  return await store.dispatch("diary/saveTenantResponse", booking);
};

const sendRequestCallbackEmail = async (bookingid: string): Promise<any> => {
  return await store.dispatch("diary/sendRequestCallbackEmail", bookingid);
};

const mess = ref("");

const message = computed({
  get: () => mess.value,
  set: (val: string) => (mess.value = val),
});

const em = ref("");

const errormessage = computed({
  get: () => em.value,
  set: (val: string) => (em.value = val),
});
const saveBookingWithLog = async (
  booking: Booking,
  tenantresponse: string,
  responseemailaddress: string
) => {
  const unread = tenantresponse === "callback";
  let log = new EmailLog({
    inout: "in",
    tenantresponse: route.params.response as string,
    responsedescription: route.query.response as string,
    responseemailaddress,
    logdate: moment().utc().format(bookingdateformat),
    unread: unread,
  });
  booking.emaillogs.tenantemaillogs.push(log);
  await saveBooking(booking);
  channel.publish("bookingConfirmResponse", {
    deviceid: deviceid,
    bookingid: booking.id,
    unread: unread,
  });
};

const setMessage = (emailresponse: string) => {
  let val = "";
  if (emailresponse) {
    if (emailresponse === "yes")
      val = "Thank you, all confirmed. Good luck with the move!";
    else if (emailresponse === "callback")
      val =
        "Thank you, one of our team will be in touch shortly. Our open hours are Monday-Friday 9am-5.30pm. Saturdays 9am-3.30pm";
    else
      val =
        "Thank you for confirming. We have made a note of your response and we will process the appointment accordingly";
  }
  return val;
};

onMounted(() => {
  if (route.params.bookingid) {
    if (route.params.response) {
      let swapbooking: Booking | undefined = undefined;
      getBookingUnauthenticated(route.params.bookingid as string).then(
        async (booking: Booking) => {
          // Check map to interprete the new response from existing
          let map: Map<string, string> = undefined;
          let emailresponse = route.params.response as string;
          if (
            booking?.jobtype === "inventory" ||
            booking?.jobtype === "checkin"
          ) {
            map = invcivmap;
          } else if (
            booking?.jobtype === "checkout" ||
            booking?.jobtype === "soc"
          ) {
            map = cosocmap;
          } else if (booking?.jobtype === "property visit") {
            map = pvmap;
          }
          if (map && map.has(emailresponse)) {
            emailresponse = map.get(emailresponse);
          }
          if (
            booking &&
            booking.isTenantEmail(route.query.emailaddress as string)
          ) {
            const othertenantsrespondedsame =
              actProperty.checkOtherTenantsResponse(
                booking,
                route.query.emailaddress as string,
                emailresponse
              );
            const tenant = findTenant(booking);
            if (
              tenant.attending === "" ||
              tenant.attending === "unsure" ||
              emailresponse === tenant.attending
            ) {
              if (booking.jobtype === "inventory") {
                if (route.params.response === "yes") {
                  // Tenant has agreed for given date and time
                  booking.tenantattending = "yes";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "no") {
                  // Tenant has not agreed for given date and time
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "callback") {
                  // Tenant has requested a callback
                  booking.callbackrequested = true;
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                  sendRequestCallbackEmail(booking.id);
                } else if (route.params.response === "option1") {
                  // Tenant response - Yes, I will attend
                  booking.tenantattending = "yes";
                  booking.releasekeysto = "Meet Tenant";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option2") {
                  // Tenant response - I am unable to attend, please return to keys to the letting agent
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option3") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "option4") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                }
              } else if (booking.jobtype === "checkin") {
                if (route.params.response === "yes") {
                  // Tenant has agreed for given date and time
                  booking.tenantattending = "yes";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "no") {
                  // Tenant has not agreed for given date and time
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "callback") {
                  // Tenant has requested a callback
                  booking.callbackrequested = true;
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                  sendRequestCallbackEmail(booking.id);
                } else if (route.params.response === "option1") {
                  // Tenant response - Yes, I will attend
                  booking.tenantattending = "yes";
                  booking.releasekeysto = "Meet Tenant";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option2") {
                  // Tenant response - I am unable to attend, please return to keys to the letting agent
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option3") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "option4") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                }
              } else if (booking.jobtype === "checkout") {
                if (route.params.response === "yes") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "no") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "callback") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "option1") {
                  // Tenant response - Yes, I will provide access @ [Appointment time] [Date]
                  booking.confirmaccess = true;
                  booking.tenantattending = "yes";
                  booking.keypickup = "Meet Tenant";
                  booking.keypickupfromaddress = new Address();
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option2") {
                  // Tenant response - I am unable to attend, please Collect keys from [Client] [Branch]
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  booking.keypickup = "From Agency";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option3") {
                  // Tenant response - I am unable to attend I will return my keys to the concierge/porter
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  booking.keypickup = "From Concierge";
                  booking.keypickupfromaddress = new Address();
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option4") {
                  // Tenant response - None of the above, request call back
                  booking.callbackrequested = true;
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                }
              } else if (booking.jobtype === "soc") {
                if (route.params.response === "yes") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "no") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "callback") {
                  errormessage.value =
                    "Invalid response! Please contact ACT team";
                } else if (route.params.response === "option1") {
                  // Tenant response - Yes, I will provide access @ [Appointment time] [Date]
                  booking.confirmaccess = true;
                  booking.tenantattending = "yes";
                  booking.keypickup = "Meet Tenant";
                  booking.keypickupfromaddress = new Address();
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option2") {
                  // Tenant response - I am unable to attend, please Collect keys from [Client] [Branch]
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  booking.keypickup = "From Agency";
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option3") {
                  // Tenant response - I am unable to attend I will return my keys to the concierge/porter
                  if (
                    booking.tenantattending != "yes" &&
                    othertenantsrespondedsame
                  )
                    booking.tenantattending = "no";
                  booking.keypickup = "From Concierge";
                  booking.keypickupfromaddress = new Address();
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                } else if (route.params.response === "option4") {
                  // Tenant response - None of the above, request call back
                  booking.callbackrequested = true;
                  if (!booking.emaillogs) booking.emaillogs = new EmailLogs();
                  booking.emaillogs.tenantconfirmationreceiveddate =
                    datetimeToUTC(new Date());
                  booking.setAccessAfterTenantattendingChange();
                }
              } else if (booking.jobtype === "property visit") {
                // common function
                let bookingdate = moment(booking.startdate).format(
                  "DD-MM-YYYY"
                );
                let insectorBookings =
                await getBookingsUnauthenticatedForInspectorWithDate({
                  date: bookingdate,
                  inspectorid: booking.inspector.id,
                });
                let data = await actProperty.processPvConfimrationResponse(
                  booking,
                  emailresponse,
                  insectorBookings
                );
                if(data?.message) {
                  errormessage.value = data.message;
                } else {
                  booking = data.booking;
                  swapbooking = data.swapbooking;
                  saveBooking(booking);
                  if (swapbooking && swapbooking.id != booking.id){
                    let swapNewBooking = new Booking({...swapbooking, inspector: new Inspector({id: swapbooking.inspector.id})});
                    saveBooking(swapNewBooking);
                  }
                }
                channel.publish("appointmentChanged", {
                  deviceid: deviceid,
                  bookingid: booking.id,
                  source: "DiaryForm",
                });
              }

              // Note tenant response inside each tenant object as well
              // This is so that we can tell which tenant has responded yes or no
              if (emailresponse === "yes" || emailresponse === "no") {
                if (booking?.tenants) {
                  const index = booking.tenants.findIndex(
                    (t) =>
                      t.ttemail.trim() === (route.query.emailaddress as string)
                  );
                  if (index >= 0) {
                    booking.tenants[index].attending = emailresponse;
                  }
                }
              }

              //if(booking.jobtype != "property visit") {
              saveBookingWithLog(
                booking,
                emailresponse,
                route.query.emailaddress as string
              );
              //}
            } else {
              errormessage.value =
                "Sorry, you have already made a selection for this appointment. Please call our office on 0208 896 2232 to discuss. Our offices are open 9:00 till 5:30 Monday to Friday, 9:00 till 3:30 on Saturday.";
            }
          } else {
            errormessage.value = "Booking not found!";
          }
          if (!errormessage.value) {
            mess.value = setMessage(emailresponse);
          }
        }
      );
    }
  } else {
    errormessage.value = "Invalid booking id";
  }
});

const sortBookings = (list: Booking[]): Booking[] => {
  return list?.sort((b1: Booking, b2: Booking) => {
    if (moment(b1.startdate).isAfter(moment(b2.startdate))) {
      return 1;
    } else if (moment(b1.startdate).isBefore(moment(b2.startdate))) {
      return -1;
    } else {
      return 0;
    }
  });
};

const findTenant = (booking: Booking): Tenant => {
  let tenant: Tenant = undefined;
  let paramemail = route.query.emailaddress as string;
  if (booking?.tenants?.length) {
    tenant = booking.tenants.find((t) => {
      return t.ttemail && paramemail && t.ttemail.trim() === paramemail.trim();
    });
  }
  return tenant;
};

const getDuration = (booking: Booking) => {
  let bookingStartTimeMoment = moment.utc(
    booking.startdate,
    actProperty.bookingDateFormat
  );
  let bookingEndTimeMoment = moment.utc(
    booking.enddate,
    actProperty.bookingDateFormat
  );
  return moment
    .duration(bookingEndTimeMoment.diff(bookingStartTimeMoment))
    .asMinutes();
};

const setNewTimeslot = (booking: Booking, sdate: string) => {
  if (booking.startdate && booking.enddate) {
    let duration = getDuration(booking);
    booking.startdate = sdate;
    let startTimeAsMinutes = moment
      .duration(
        moment(sdate, actProperty.bookingDateFormat).utc().format("HH:mm")
      )
      .asMinutes();
    let endTimeAsMinutes = startTimeAsMinutes + duration;
    booking.enddate = moment(sdate, actProperty.bookingDateFormat)
      .utc()
      .startOf("day")
      .add(endTimeAsMinutes, "minutes")
      .format(actProperty.bookingDateFormat);
  }
};
</script>

<style scoped lang="scss"></style>
