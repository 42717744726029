<template>
  <!-- "QC Notes" Modal -->
  <div
    class="modal"
    :id="id"
    data-backdrop="static"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="report-cr-notes-modal-title"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="report-cr-notes-modal-title">
            Client Rules
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hide"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div>
            <textarea
              class="form-control miscnotes-textarea"
              rows="20"
              :value="customer.qcrules.misc"
              readonly="true"
            ></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal"  @click="hide">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, defineExpose, ref, inject,defineProps } from "vue";
import { useStore } from "vuex";
import { Customer } from "@/models";

const store = useStore();
const actProperty: any = inject("actProperty");
const customer = ref(new Customer());

// Getter to access the current report from the Vuex store
const report = computed(() => store.getters["reports/current"]);
const props = defineProps({
  id: String
});
// Action to fetch a customer by ID
const getCustomer = async (id: string): Promise<Customer | undefined> => {
  try {
    return await store.dispatch("customers/getCustomer", id);
  } catch (error) {
    console.error("Error fetching customer:", error);
    return undefined;
  }
};

const init = async() => {
  if (report.value.customerId) {
    await getCustomer(report.value.customerId).then((cust: Customer | undefined) => {
      if (!cust) {
        actProperty.displayError(
          `Could not find customer with ID ${
            report.value.customer.id as string
          }`
        );
      } else {
        customer.value = cust;
      }
    });
  }
}
const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};

defineExpose({ init,show, hide })
</script>

