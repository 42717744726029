<template>
  <div class="report-section property-details">
    <fieldset>
      <legend>Property Address</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          >Address <span class="sr-only">Line 1</span></label
        >
        <div class="col-sm-6">
          <input type="text" class="form-control" v-model.lazy="addressLine1" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          ><span class="sr-only">Address Line 2</span></label
        >
        <div class="col-sm-6">
          <input type="text" class="form-control" v-model.lazy="addressLine2" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">City</label>
        <div class="col-sm-2">
          <input type="text" class="form-control" v-model.lazy="addressTown" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">County</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            v-model.lazy="addressCounty"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Postcode</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            v-model.lazy="addressPostcode"
          />
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>General Info</legend>

      <div
        v-if="!actProperty.isFactoryreport(statereport.type)"
        class="form-group row"
      >
        <label class="col-sm-2 col-form-label">Furnished</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="furnished"
            :value="furnished"
            :options="furnishedOptions"
          >
          </multiselect-text>
        </div>
      </div>
      <div v-if="statereport.type === 'checkout'" class="form-group row">
        <label class="col-sm-2 col-form-label">Check In report date and cleaning status at the beginning of tenancy</label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            rows="5"
            v-model.lazy="historicalCleaningStatus"
          ></textarea>
        </div>
        </div>
        <div class="form-group row">
        <label class="col-sm-2 col-form-label">Internal notes for QC</label>
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            v-model.lazy="InternalNotesForQC"
          />
        </div>
      </div>
      <div v-if="statereport.type === 'checkin'" class="form-group row">
        <label class="col-sm-2 col-form-label">General notes</label>
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-6">
              <div class="card bg-light booking-section">
                <div class="card-header booking-section-header">Current general notes</div>
                <div class="card-body">
                  <textarea
                    class="form-control"
                    rows="20"
                    v-model.lazy="generalNotes"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card bg-light booking-section">
                <div class="card-header booking-section-header">Previous general notes from Check Out report</div>
                <div class="card-body">
                  <textarea
                    class="form-control"
                    rows="20"
                    v-model.lazy="previousGeneralNotes"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="form-group row">
        <label class="col-sm-2 col-form-label">General notes</label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            rows="20"
            v-model.lazy="generalNotes"
          ></textarea>
        </div>
      </div>

      <!-- New Section for Cleanliness Flagged Defects -->
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Cleanliness flagged defects</label>
        <div class="col-sm-9">
          <div class="card">
            <div class="card-body" style="max-height: 300px; overflow-y: auto;">
              <div v-for="(entry, index) in cleanlinessFlaggedNotes" :key="index" class="mb-3">
                <h5>{{ entry.room }} -> {{ entry.section }} -> {{ entry.type }}</h5>
                <ul>
                  <li v-for="note in entry.notes" :key="note._uuid" >
                    {{ note.note }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isDataentry && statereport.type === 'inventory'" class="form-group row">
        <label class="col-sm-2 col-form-label">Tenancy start date</label>
        <div class="col-sm-2">
          <DxDateBox
              v-model="tenancyStartDate"
              display-format="dd/MM/yyyy"
              type="date"
              picker-type="calendar"
              :calendarOptions="{ firstDayOfWeek: 1 }"
              apply-value-mode="instantly"
          />
        </div>
      </div>
      </fieldset>

    <fieldset v-if="!actProperty.isFactoryreport(statereport.type)">
      <legend>Parking</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Garage/Parking location</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            v-model.lazy="parkingLocation"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Parking space number</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            v-model.lazy="parkingSpaceNo"
          />
        </div>
      </div>
    </fieldset>

    <fieldset v-if="!actProperty.isFactoryreport(statereport.type)">
      <legend>Water</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Stopcock location</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            v-model.lazy="stopcockLocation"
          />
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Photos</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Property exterior</label>
        <div class="col-sm-8">
          <div class="row">
            <Photos
              :key="`${locationPhotos.length}`"
              :photos="locationPhotos"
              :path="'propertyDetails.locationPhotos'"
            />
          </div>
        </div>
      </div>

      <div
        v-if="!actProperty.isFactoryreport(statereport.type)"
        class="form-group row"
      >
        <label class="col-sm-2 col-form-label">Cleaning receipt</label>
        <div class="col-sm-8">
          <div class="row">
            <Photos
              :key="`${cleaningReceiptPhotos.length}`"
              :photos="cleaningReceiptPhotos"
              :path="'propertyDetails.cleaningReceiptPhotos'"
            />
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="statereport.type === 'checkout'">
      <legend>Forwarding Address</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            v-model.lazy="forwardingContactName"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          >Address <span class="sr-only">Line 1</span></label
        >
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            v-model.lazy="forwardingContactAddressLine1"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          ><span class="sr-only">Address Line 2</span></label
        >
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            v-model.lazy="forwardingContactAddressLine2"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">City</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            v-model.lazy="forwardingContactAddressTown"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">County</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            v-model.lazy="forwardingContactAddressCounty"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Postcode</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            v-model.lazy="forwardingContactAddressPostcode"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Telephone</label>
        <div class="col-sm-2">
          <input
            type="tel"
            class="form-control"
            v-model.lazy="forwardingContactPhone"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-6">
          <input
            type="email"
            class="form-control"
            v-model.lazy="forwardingContactEmail"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import _get from "lodash/get";
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import Photos from "@/components/photo/Photos.vue";
import { Item, Note, Report, Room, Section } from "@/models";
import { DxDateBox } from "devextreme-vue/date-box";
const store = useStore();
const statereport = computed(() => store.getters['reports/current']);

const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};
const isDataentry = computed(() => store.getters['auth/isDataentry']);

const actProperty: any = inject('actProperty');

const addressLine1 = computed({
  get: () => statereport.value.address.line1,
  set: (data) => setReportDeep({ path: 'address.line1', data })
});

const addressLine2 = computed({
  get: () => statereport.value.address.line2,
  set: (data) => setReportDeep({ path: 'address.line2', data })
});

const addressTown = computed({
  get: () => statereport.value.address.town,
  set: (data) => setReportDeep({ path: 'address.town', data })
});

// Computed property for addressCounty
const addressCounty = computed({
  get: () => statereport.value.address.county,
  set: (data) => setReportDeep({ path: 'address.county', data })
});

// Computed property for addressPostcode
const addressPostcode = computed({
  get: () => statereport.value.address.postcode,
  set: (data) => setReportDeep({ path: 'address.postcode', data })
});

// Computed property for furnished
const furnished = computed({
  get: () => statereport.value.propertyDetails.furnished,
  set: (data) => setReportDeep({ path: 'propertyDetails.furnished', data })
});

// Computed property for InternalNotesForQC
const InternalNotesForQC = computed({
  get: () => statereport.value.propertyDetails.notes,
  set: (data) => setReportDeep({ path: 'propertyDetails.notes', data })
});

// Computed property for generalNotes
const generalNotes = computed({
  get: () => statereport.value.propertyDetails.generalNotes,
  set: (data) => setReportDeep({ path: 'propertyDetails.generalNotes', data })
});

// Computed property for previousgeneralNotes
const previousGeneralNotes = computed({
  get: () => statereport.value.propertyDetails.previousGeneralNotes,
  set: (data) => setReportDeep({ path: 'propertyDetails.previousGeneralNotes', data })
});

// Computed property for historical_cleaning_status
const historicalCleaningStatus = computed({
  get: () => statereport.value.propertyDetails.historicalCleaningStatus,
  set: (data) => setReportDeep({ path: 'propertyDetails.historicalCleaningStatus', data })
});

// Computed property for locationPhotos
const locationPhotos = computed({
  get: () => statereport.value.propertyDetails.locationPhotos,
  set: (data) => setReportDeep({ path: 'propertyDetails.locationPhotos', data })
});

// Computed property for locationPhotos
const tenancyStartDate = computed({
  get: () => statereport.value.propertyDetails.tenancystartdate ?? null,
  set: (data) => setReportDeep({ path: 'propertyDetails.tenancystartdate', data })
});

// Computed property for cleaningReceiptPhotos
const cleaningReceiptPhotos = computed({
  get: () => statereport.value.propertyDetails.cleaningReceiptPhotos,
  set: (data) => setReportDeep({ path: 'propertyDetails.cleaningReceiptPhotos', data })
});

// Computed property for parkingLocation
const parkingLocation = computed({
  get: () => statereport.value.propertyDetails.parkingLocation,
  set: (data) => setReportDeep({ path: 'propertyDetails.parkingLocation', data })
});

// Computed property for parkingSpaceNo
const parkingSpaceNo = computed({
  get: () => statereport.value.propertyDetails.parkingSpaceNo,
  set: (data) => setReportDeep({ path: 'propertyDetails.parkingSpaceNo', data })
});

// Computed property for stopcockLocation
const stopcockLocation = computed({
  get: () => statereport.value.propertyDetails.stopcockLocation,
  set: (data) => setReportDeep({ path: 'propertyDetails.stopcockLocation', data })
});

// Computed property for forwardingContactName
const forwardingContactName = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.name,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.name', data })
});

// Computed property for forwardingContactAddressLine1
const forwardingContactAddressLine1 = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.address.line1,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.address.line1', data })
});

// Computed property for forwardingContactAddressLine2
const forwardingContactAddressLine2 = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.address.line2,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.address.line2', data })
});

// Computed property for forwardingContactAddressTown
const forwardingContactAddressTown = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.address.town,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.address.town', data })
});

// Computed property for forwardingContactAddressCounty
const forwardingContactAddressCounty = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.address.county,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.address.county', data })
});

// Computed property for forwardingContactAddressPostcode
const forwardingContactAddressPostcode = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.address.postcode,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.address.postcode', data })
});

// Computed property for forwardingContactPhone
const forwardingContactPhone = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.phone,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.phone', data })
});

// Computed property for forwardingContactEmail
const forwardingContactEmail = computed({
  get: () => statereport.value.propertyDetails.forwardingContact.email,
  set: (data: string) => setReportDeep({ path: 'propertyDetails.forwardingContact.email', data })
});

/**
 * Returns Active Item Path
 */
const getGeneralCommentsPhotoPath = (report: Report) => {
  let path = `tenantcomments.tenantPhotos`;
  return path;
}

/**
 * Return Check-In Photos from Items
 *
 * @param {Item} item
 */
const getGeneralCommentsPhotos = (report: Report) => {
  let photos = _get(report, "tenantcomments.tenantPhotos", []);
  return photos;
}

const furnishedOptions: string[] = [
  "Unfurnished",
  "Part furnished",
  "Fully furnished",
  "Fully furnished with linen",
];

// Computed property for cleanlinessFlaggedNotes
const cleanlinessFlaggedNotes = computed(() => {
  const rooms = statereport.value?.rooms ?? [];
  const groupedNotes: { room: string, section: string, type: string, notes: Note[] }[] = [];

  rooms.forEach((room: Room) => {
    room.sections.forEach((section: Section) => {
      section.types.forEach((type: any) => {
        const notes = [];
        type.items.forEach((item: Item) => {
          item.condition.in.notes.forEach((note: Note) => {
            if (note.cleanliness_flag) {
              notes.push(note);
            }
          });
          item.condition.out.notes.forEach((note: Note) => {
            if (note.cleanliness_flag) {
              notes.push(note);
            }
          });
        });
        if (notes.length > 0) {
          groupedNotes.push({ room: room.name, section: section.name, type: type.name, notes });
        }
      });
    });
  });

  return groupedNotes;
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tenant-feedback-jumbotron {
  padding: 0rem 2rem;
}

.approvedcomment {
  background-color: #a3e23f;
}

.card {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card-body {
  padding: 15px;
}

.list-group-item {
  border: none;
  padding: 0.5rem 1rem;
}

.font-weight-bold {
  font-weight: bold;
}
</style>
