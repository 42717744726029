<template>
  <div style="height: 85vh" key="diaryform">
    <div class="booking-sections pr-1">
      <search
        @showsearchresult="showingsearchresult = true"
        @hidesearchresult="showingsearchresult = false"
      />
      <what-when
        v-if="!showingsearchresult"
        @showfloorplandocument="showFloorplanDocument"
        @showdataentrydocument="showDataentryDocument"
      />
      <scheduling v-if="!showingsearchresult && isCoreReport" />
      <PvScheduling v-else />
      <who-for-how-much
        v-if="!showingsearchresult"
        @showpricelistdocument="showPricelistDocument"
        @showworksorderdocument="showWorksorderDocument"
      />
      <access
        v-if="!showingsearchresult"
        @showaccessattachementdocument="showAccessattachementDocument"
      />
      <confirmations v-if="!showingsearchresult" />
      <others
        v-if="!showingsearchresult"
        @showinternalnotesdocument="showInternalnotesDocument"
        @showpinotesDocument="showpinotesDocument"
      />
      <IssuingReport v-if="isQCuser || isHistoric" />
    </div>

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!--button
          class="btn btn-light"
          data-toggle="modal"
          title="Add Booking"
          @click="addNewBooking"
        >
          <i class="fas fa-plus"></i> New
        </button-->

        <div
          class="dropup-center dropup ml-1"
          v-if="!booking.subtype && !isNewBooking()"
        >
          <button
            class="btn btn-light dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Create Sub
          </button>
          <ul class="dropdown-menu">
            <li
              v-for="(subbooking, $index) in booking.subbookings"
              :key="$index"
            >
              <a
                class="dropdown-item"
                href="#"
                @click="locateSubjob(subbooking)"
                v-on:click.stop
                data-bs-auto-close="inside"
                ><i class="far fa-calendar-alt" />
                {{ getSubjobPrompt(subbooking) }}</a
              >
            </li>
            <li v-if="!prepjobpresent">
              <a
                class="dropdown-item"
                href="#"
                @click="showSubJobAddModal('Prep')"
                v-on:click.stop
                ><i class="fas fa-columns pr-1" /> Prep Job</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="showSubJobAddModal('Shared')"
                v-on:click.stop
                ><i class="fas fa-user-friends pr-1" /> Shared Job</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="showSubJobAddModal('Key')"
                v-on:click.stop
                ><i class="fas fa-key pr-1" /> Key Collection Job</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="showSubJobAddModal('Revisit')"
                v-on:click.stop
                ><i class="fas fa-retweet pr-1" /> Revisit</a
              >
            </li>
          </ul>
        </div>

        <import-bookings-button class="ml-1" />

        <button
          v-if="!isNewBooking()"
          class="btn btn-light ml-1"
          title="Audit logs"
          @click="showAuditLogsModal"
        >
          <i class="fas fa-clipboard-list"></i> Audit logs
        </button>

        <button
          v-if="booking.id && !booking.cancelled"
          class="btn btn-outline-danger"
          @click="cancelBooking"
          v-on:click.stop
        >
          <i class="fas fa-times-circle"></i> Delete
        </button>
        <button
          v-if="booking.id && booking.cancelled"
          class="btn btn-outline-light"
          @click="restoreBooking"
          v-on:click.stop
        >
          <i class="fas fa-undo"></i> Restore
        </button>
        <button
          class="btn btn-light ml-1"
          title="Send Otp"
          @click="showTOTPDialog"
        >
          <i class="fas fa fa-key"></i> OTP
        </button>

        <ProgressButton
          v-if="isHistoric && booking.targetreport && booking.targetreport.ref"
          @dblclick="generateReportPdfAction(booking.targetreport)"
          class="btn btn-outline-primary"
        >
          <i class="far fa-file-pdf"></i> Download
        </ProgressButton>

        <div id="status-bar-switches" style="margin-left: auto">
          <div class="btn-group" role="group" aria-label="Navigation">
            <button class="btn btn-outline-light" @click="openCalendarInNewTab()" title="Open scheduler in a new tab">
              <i class="fas fa-external-link-alt"></i>
            </button>
          </div>
        </div>
        <div
          class="dropup-center dropup ml-auto"
          v-if="
            booking.jobtype === 'property visit' &&
            !booking.cancelled &&
            !isNewBooking()
          "
        >
          <button
            class="btn btn-light dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Status {{ booking.status ? `- ${booking.status}` : "" }}
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                @click="changeStatus('No show, to be rescheduled')"
                >No show, to be rescheduled</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                @click="changeStatus('Cancelled by client')"
                >Cancelled by client</a
              >
            </li>
            <li>
              <a class="dropdown-item" @click="changeStatus('Issued')"
                >Issued</a
              >
            </li>
          </ul>
        </div>
        <div
          id="status-bar-switches"
          style="margin-left: auto"
          v-if="booking.id"
        >
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              id="issuedCheckBox"
              class="custom-control-input"
              :checked="booking.issued"
              @change="toggleIssued()"
            />
            <label class="custom-control-label text-light" for="issuedCheckBox">
              Issued
            </label>
          </div>
        </div>

        <!-- Status Bar Actions -->
        <div id="status-bar-actions" style="margin-left: auto">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button
                class="btn btn-outline-light"
                :class="{ disabled: !hasUnsavedChanges }"
                @click.prevent="cancel()"
                :disabled="!hasUnsavedChanges"
              >
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button
                class="btn btn-light"
                :class="{
                  disabledbutton:
                    (!hasUnsavedChanges || !canSave()) && isNewBooking(),
                }"
                @click.prevent="checkAndSave()"
                :disabled="isSaving || booking.cancelled || !hasUnsavedChanges"
              >
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
              <button
                class="btn btn-light"
                :class="{
                  disabledbutton:
                    (!hasUnsavedChanges || !canSave()) && isNewBooking(),
                }"
                @click.prevent="checkAndSaveAndNew()"
                :disabled="isSaving || booking.cancelled"
              >
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save and Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <SubJobModal
      ref="subJobAddModal"
      id="add-subjob-modal"
      @subJobCreated="subJobCreated"
      @hide="hideSubJobAddModal"
    />

    <SubJobModal
      id="edit-subjob-modal"
      ref="subJobEditModal"
      @subJobCreated="subJobCreated"
      @hide="hideSubJobEditModal"
    />

    <AuditlogsModal name="mainauditlogs-modal" ref="auditLogsModal" />

    <draggable-modal
      :modal-id="'floorplan-viewer'"
      :document="floorplan[documentnumber]"
      :modalkey="floorplanviewerkey"
      :title="'Floor plan'"
      @update:model-value="updateDraggableModal"
    />
    <draggable-modal
      :modal-id="'rates-viewer'"
      :document="ratedocument"
      :title="'Rates'"
    />
    <draggable-modal
      :modal-id="'dataentrydocument-viewer'"
      :document="dataentrydocuments[selecteddataentrydocument]"
      :title="'Dataentry PDF'"
    />
    <draggable-modal
      :modal-id="'accessattachment-viewer'"
      :modalkey="accessattachmentviewerkey"
      :document="accessattachments[documentnumber]"
      :title="'Access confirmation'"
    />
    <draggable-modal
      :modal-id="'worksorderdocument-viewer'"
      :modalkey="worksorderdocumentviewerkey"
      :document="worksorderdocuments[documentnumber]"
      :title="'Worksorder'"
    />
    <draggable-modal
      :modal-id="'internalnotesdocument-viewer'"
      :modalkey="internalnotesdocumentviewerkey"
      :document="internalnotesdocuments[documentnumber]"
      :title="'Internal notes document'"
    />
    <draggable-modal
      :modal-id="'pinotesdocument-viewer'"
      :modalkey="pinotesdocumentviewerkey"
      :document="pinotesdocuments[documentnumber]"
      :title="'PI notes document'"
    />
    <AlertDialog
      ref="alertDialog2"
      name="alertdialog2"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog3"
      name="alertdialog3"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog4"
      name="alertdialog4"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog5"
      name="alertdialog5"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog6"
      name="alertdialog6"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog7"
      name="alertdialog7"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog8"
      name="alertdialog8"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog9"
      name="alertdialog9"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog10"
      name="alertdialog10"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog11"
      name="alertdialog11"
      @alertDialogClosed="alertDialogClosed"
    />
    <AlertDialog
      ref="alertDialog12"
      name="alertdialog12"
      @alertDialogClosed="alertDialogClosed"
    />

    <BookingCancelModal
      name="main-cancel-booking-modal"
      ref="bookingCancelModal"
      @hide="hideBookingCancelModal"
    />

    <EmailModal
      :id="`deletebooking-email-modal`"
      :name="`deletebooking-email-modal`"
      ref="deletebookingEmailModal"
      title="Email to booking contact"
      :reporttype="booking.jobtype"
      target="Booked By"
      chronorder="Delete Booking"
      :fromaddress="fromaddress"
      :toaddresses="bookedbyaddresses"
      label="Delete Booking"
    />

    <ModalsContainer />
    <BookingFixedTimeModal
      id="fixedtime-booking-modal"
      ref="bookingFixedTimeModal"
    />
    <ShowOtpModal id="show-otp-modal" ref="showOtpModal" />
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  computed,
  onMounted,
  watch,
  inject,
  onBeforeUnmount,
  onUnmounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import Ably from "ably";
import moment from "moment-timezone";
import _get from "lodash/get";
import _set from "lodash/set";
import { v4 as uuidv4 } from "uuid";
import { datetimeToUTC } from "@/utilities";
import { checkBooking } from "@/bookingfilterpresets";
import {
  Address,
  Booking,
  Fee,
  Inspector,
  PropertySpec,
  Report,
  SystemProperty,
  Document,
  PresetError,
  SMSLog,
  EmailLog,
  Contact,
  Customer,
  Bookedby,
  Signature,
  Landlord,
  Tenant,
} from "@/models";

import Search from "./sections/Search.vue";
import WhatWhen from "./sections/WhatWhen.vue";
import WhoForHowMuch from "./sections/WhoForHowMuch.vue";
import Scheduling from "./sections/Scheduling.vue";
import Confirmations from "./sections/Confirmations.vue";
import Access from "./sections/Access.vue";
import Others from "./sections/Others.vue";
import IssuingReport from "./sections/IssuingReport.vue";

import ImportBookingsButton from "@/components/diary/ImportBookingsButton.vue";
import BookingCancelModal from "@/components/modals/BookingCancelModal.vue";
import SubJobModal from "@/components/modals/SubJobModal.vue";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import AuditlogsModal from "@/components/modals/AuditlogsModal.vue";
import EmailModal from "@/components/modals/EmailModal.vue";
import UndoSaveToaster from "@/components/modals/UndoSaveToaster.vue";
import { ModalsContainer, useVfm } from "vue-final-modal";
import DraggableModal from "@/components/modals/DraggableModal.vue";
import ProgressButton from "@/components/ProgressButton.vue";
import ShowOtpModal from "@/components/modals/ShowOtpModal.vue";
import BookingFixedTimeModal from "@/components/modals/BookingFixedTimeModal.vue";
import { useEvent } from "@/eventBus";
import PvScheduling from "./sections/PvScheduling.vue";
const vfm = useVfm();

const store = useStore();
const route = useRoute();
const router = useRouter();
const toasted = useToast();

const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

// State variables
const statusFlag = ref("");
const hasUnsavedChanges = computed(
  () => store.getters["diary/hasUnsavedChanges"]
);
const dictionary = computed(() => store.getters["dictionary/current"]);
const statecurrentdate = computed(() => store.getters["diary/currentdate"]);
const booking = computed((): Booking => store.getters["diary/booking"]);
const systemproperties = computed(
  () => store.getters["dictionary/systemproperties"]
);
const inspectorlist = computed(() => store.getters["diary/inspectorlist"]);
const propertyspec = computed(
  (): PropertySpec => store.getters["diary/propertyspec"]
);
const customer = computed((): Customer => store.getters["diary/customer"]);
const appointmenttime = computed(() => store.getters["diary/appointmenttime"]);
const isQCuser = computed(() => store.getters["auth/isQCUser"]);
const isBookingManagement = computed(
  () => store.getters["auth/isBookingManagement"]
);
const isHistoric = computed(() => {
  let result = false;
  if (booking.value?.id != "new") {
    const startoftoday = moment().utc().startOf("day");
    const bookingdate = moment(booking.value.startdate).utc();
    result = bookingdate.isBefore(startoftoday);
  }
  return result;
});
const originalbooking = ref();
const documentnumber = ref(0);
const setCurrentdate = (currentdate: Date) => {
  store.commit("diary/setCurrentdate", currentdate);
};

const setBookingStartdate = (starttime: string) => {
  store.commit("diary/setStartdate", starttime);
};

const setBookingEnddate = (endtime: string) => {
  store.commit("diary/setEnddate", endtime);
};

const setBookingInspector = (inspector: any) => {
  store.commit("diary/setInspector", inspector);
};

const setBookingDeep = (payload: { path: string; data: any }) => {
  store.commit("diary/setBookingDeep", payload);
};

const setJobtype = (type: any) => {
  store.commit("diary/setJobtype", type);
};

const setInternaljobtype = (jobtype: string) => {
  store.commit("diary/setInternaljobtype", jobtype);
};

const setBooking = (booking: any) => {
  store.commit("diary/setBooking", booking);
};

const setInvoices = (invoices: any[]) => {
  store.commit("diary/setInvoices", invoices);
};

const resetUnsavedChanges = () => {
  store.commit("diary/resetUnsavedChanges");
};

const getBookingWithoutStoringInState = async (payload: {
  id: string;
  cancelled: string;
}) => {
  return await store.dispatch("diary/getBookingWithoutStoringInState", payload);
};

const hasUnsavedChangesAction = async () => {
  return await store.dispatch("diary/hasUnsavedChanges");
};

const getBooking = async (id: string) => {
  return await store.dispatch("diary/getBooking", id);
};

const lockBooking = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch("diary/lockBooking", booking).then((b: Booking) => {
      if (b?.id) {
        channel.publish("bookinglocked", {
          bookingid: b.id,
          lockedby: b.lockedby,
        });
      }
    });
  }
};
const unlockBooking = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch("diary/unlockBooking", booking).then((b: Booking) => {
      if (b?.id) {
        channel.publish("bookingunlocked", { bookingid: b.id });
      }
    });
  }
};

const addBooking = async (booking: any) => {
  return await store.dispatch("diary/addBooking", booking);
};
const sendEmailToDataentry = async (booking: any) => {
  await store.dispatch("diary/sendEmailToDataentry", {
    bookingid: booking.id,
    chronorder: "Initial confirmation",
  });
};

const updateDataentryEmailConfirmationSent = async (
  bookingid: string
): Promise<any> => {
  return await store.dispatch(
    "diary/updateDataentryEmailConfirmationSent",
    bookingid
  );
};

const updateBooking = async (booking: any) => {
  return await store.dispatch("diary/updateBooking", booking);
};

const saveAllBookingChanges = async () => {
  return await store.dispatch("diary/saveAllBookingChanges");
};

const saveBookingOutsideStore = async (booking: any) => {
  return await store.dispatch("diary/saveBookingOutsideStore", booking);
};

const saveBooking = async (booking: any) => {
  return await store.dispatch("diary/saveBooking", booking);
};

const restoreBookingInStore = async (booking: any): Promise<Booking> => {
  return await store.dispatch("diary/restoreBooking", booking);
};

const updateInspector = async (inspector: any) => {
  return await store.dispatch("diary/updateInspector", inspector);
};

const updateCustomer = async (payload: {
  customerid: string;
  newcontacts: any;
  updatedcontacts: any;
}): Promise<Customer> => {
  return await store.dispatch("diary/updateCustomerPOC", payload);
};

const sortInpectorsInState = async (params: any) => {
  return await store.dispatch("diary/sortInpectors", params);
};

const addInvoice = async (invoice: any) => {
  return await store.dispatch("reports/addInvoice", invoice);
};

const updateInvoice = async (invoice: any) => {
  return await store.dispatch("reports/updateInvoice", invoice);
};

const registerReportWithMasterProperty = async (invoice: any) => {
  return await store.dispatch(
    "reports/registerReportWithMasterProperty",
    invoice
  );
};

const addDateentryAction = async (dereport: any) => {
  return await store.dispatch("reports/addDateentry", dereport);
};

const getAllCustomers = (): Promise<any> => {
  return store.dispatch("diary/getAllCustomers");
};

const updateConnectedBooking = (id: string) => {
  return store.dispatch("diary/updateConnectedBooking", id);
};

const generateReportPdfAction = (report: Report) =>
  store.dispatch("reports/generateReportPdf", report);

const isSaving = ref(false);
const showingsearchresult = ref(false);
const floorplanviewerkey = ref("");
const accessattachmentviewerkey = ref("");
const worksorderdocumentviewerkey = ref("");
const internalnotesdocumentviewerkey = ref("");
const pinotesdocumentviewerkey = ref("");
const saveandnew = ref(false);
const subjobbooking = ref<Booking>(new Booking());

const alertDialog2 = ref(null);
const alertDialog3 = ref(null);
const alertDialog4 = ref(null);
const alertDialog5 = ref(null);
const alertDialog6 = ref(null);
const alertDialog7 = ref(null);
const alertDialog8 = ref(null);
const alertDialog9 = ref(null);
const alertDialog10 = ref(null);
const alertDialog11 = ref(null);
const alertDialog12 = ref(null);
const subJobAddModal = ref(null);
const subJobEditModal = ref(null);
const auditLogsModal = ref(null);
const deletebookingEmailModal = ref(null);
const bookingCancelModal = ref(null);
const showOtpModal = ref(null);

const isCoreReport = computed(() => {
  const corereports = ["inventory", "soc", "checkin", "checkout"];
  return corereports.includes(booking.value.jobtype);
});

const getBookingsUnauthenticatedForInspectorWithDate = async (payload?: {
  date?: string;
  inspectorid?: string;
}): Promise<Booking[]> => {
  return await store.dispatch(
    "diary/getBookingsCloneUnauthenticatedForInspectorWithDate",
    payload
  );
};
onMounted(async () => {
  await getAllCustomers();
  channel.subscribe("appointmentSelected", (message: any) => {
    statusFlag.value = "";
    appointmentSelected(message);
  });

  channel.subscribe("bookingConfirmResponse", (message: any) => {
    bookingConfirmResponse(message);
  });

  channel.subscribe("newBooking", (message: any) => {
    startNewBooking(message);
  });

  channel.subscribe("diaryFormOpenCheck", (message: any) => {
    if (message?.data) {
      // Make sure the event is coming from the same pc
      if (message.data.deviceid != deviceid) return;
      if (
        route.fullPath.includes("diary") &&
        !route.fullPath.includes("managementfilters")
      ) {
        channel.publish("diaryFormOpenConfirm", { deviceid: deviceid });
      }
    }
  });

  channel.subscribe("appointmentSelectedInSearch", (message: any) => {
    appointmentSelected(message);
  });

  channel.subscribe("appointmentChanged", (message: any) => {
    if (message.data.bookingid !== booking.value.id) return;
    getBookingWithoutStoringInState({
      id: message.data.bookingid,
      cancelled: "false",
    }).then((b: Booking) => {
      if (
        b.id &&
        message?.data &&
        message?.data.bookingid === booking.value.id &&
        (message.data.source != "DiaryForm" ||
          message.data.target === "ConnectedBooking")
      ) {
        updatecurrentbooking(b);
        clearUnsavedChanges();
      } else if (b?.subtype && b.leadbooking?.id === booking.value.id) {
        let index = booking.value.subbookings.findIndex(
          (sb: Booking) => sb.id === b.id
        );
        if (index >= 0) {
          booking.value.subbookings.splice(index, 1);
          booking.value.subbookings.push(b);
          setBookingDeep({
            path: "subbookings",
            data: [...booking.value.subbookings],
          });
        }
      }
    });
  });

  channel.subscribe("appointmentDeleted", (message: any) => {
    if (message?.data && message?.data === booking.value.id) {
      setBooking(initnewbooking());
      router.push("/diary/new").catch((err) => {});
    }
  });

  channel.subscribe("appointmentCancelled", (message: any) => {
    if (message?.data) {
      if (message?.data === booking.value.id) {
        setBooking(initnewbooking());
        router.push("/diary/new").catch((err) => {});
      } else {
        if (booking.value.subbookings?.length) {
          const sbindex = booking.value.subbookings.findIndex(
            (sb: Booking) => sb.id === message?.data
          );
          if (sbindex >= 0) {
            booking.value.subbookings.splice(sbindex, 1);
            booking.value.subbookings = [...booking.value.subbookings];
            updateBooking(booking.value);
          }
        }
      }

      // Remove this booking from any inspectors
      inspectorlist.value.forEach((i: Inspector) => {
        let index = i.bookings.findIndex((b: Booking) => b.id === message.data);
        if (index >= 0) {
          i.bookings.splice(index, 1);
          updateInspector(i).then(() =>
            sortInpectorsInState({
              previousreport: booking.value.basereport,
              previousbooking: booking.value.previousbooking,
              postcode: booking.value.address.postcode,
            })
          );
        }
      });
    }
  });

  useEvent("checkAndSave", () => checkAndSave());

  window.addEventListener("scroll", () => {
    localStorage.setItem("scrollPosition", `${window.pageYOffset}`);
  });
  document.title = "Booking form";

  if (isNewBooking()) {
    setBooking(initnewbooking());
    clearUnsavedChanges();
    return;
  }

  loadform();
});

onBeforeUnmount(() => {
  channel.unsubscribe("appointmentSelected");
  channel.unsubscribe("bookingConfirmResponse");
  channel.unsubscribe("newBooking");
  channel.unsubscribe("diaryFormOpenCheck");
  channel.unsubscribe("appointmentSelectedInSearch");
  channel.unsubscribe("appointmentChanged");
  channel.unsubscribe("appointmentDeleted");
  channel.unsubscribe("appointmentCancelled");
});

const clearUnsavedChanges = () => {
  setTimeout(() => resetUnsavedChanges(), 500);
};

const updatecurrentbooking = (booking: Booking) => {
  let type;
  if (dictionary.value && dictionary.value.reporttypes) {
    type = dictionary.value.reporttypes.find((t) => t.slug === booking.jobtype);
  }
  if (type) setJobtype(type);

  setInternaljobtype(booking.internaljobtype);
  if (booking.startdate) setBookingStartdate(booking.startdate);
  if (booking.startdate) setBookingEnddate(booking.enddate);
  if (booking.inspector) setBookingInspector(booking.inspector);

  setBookingDeep({
    path: "subbookings",
    data: [...booking.subbookings],
  });

  if (booking.appointmenttime) {
    setBookingDeep({
      path: "appointmenttime",
      data: booking.appointmenttime,
    });
  }

  if (booking.recommendedtime && booking.recommendedtime >= 0)
    setBookingDeep({
      path: "recommendedtime",
      data: booking.recommendedtime,
    });

  if (booking.recommendedappointmenttime >= 0)
    setBookingDeep({
      path: "recommendedappointmenttime",
      data: booking.recommendedappointmenttime,
    });

  if (booking.preferredduration >= 0)
    setBookingDeep({
      path: "preferredduration",
      data: booking.preferredduration,
    });

  if (booking.preferredappointmenttime >= 0)
    setBookingDeep({
      path: "preferredappointmenttime",
      data: booking.preferredappointmenttime,
    });

  setBookingDeep({
    path: "appointmentchanged",
    data: booking.appointmentchanged,
  });

  setBookingDeep({
    path: "auditlogs",
    data: booking.auditlogs,
  });
  setBookingDeep({
    path: "emaillogs",
    data: booking.emaillogs,
  });
};

const currentdate = computed({
  get: () => statecurrentdate.value,
  set: (val: Date) => setCurrentdate(val),
});

const initnewbooking = () => {
  const invoice = new Report({ fees: [new Fee()] });
  const booking = new Booking({
    invoices: [invoice],
  });
  let newbookingdate = moment("2050-12-31", "YYYY-MM-DD").utc().toString();
  setCurrentdate(moment("2050-12-31", "YYYY-MM-DD").utc().toDate());
  // If Booking management or QC then keep the current date as it is
  // Do not change the currentdate to today
  if (isBookingManagement.value || isQCuser.value)
    newbookingdate = currentdate.value;

  const fiveam = moment(newbookingdate)
    .utc()
    .startOf("day")
    .add(5, "hours")
    .format(actProperty.bookingdateformat); // Ensure actProperty is correctly defined or imported

  booking.startdate = fiveam;
  booking.enddate = fiveam;
  booking.appointmenttime = fiveam;

  return booking;
};

const loadBooking = async (id) => {
  if (isNewBooking() && !currentdate.value) {
    //setCurrentdate(new Date());
    clearUnsavedChanges();
  }

  await getBooking(id)
    .then((b: Booking) => {
      if (b === undefined) {
        actProperty.displayError(
          `Could not find booking with ID ${route.params.id}`
        );
        router.push({ name: "newbooking" });
      } else {
        // Lock this booking
        lockBooking(b);
        setBackgroundStyle();
      }
      originalbooking.value = new Booking(b);
    })
    .then(() => clearUnsavedChanges())
    .then(() =>
      sortInpectorsInState({
        previousreport: booking.value.basereport,
        previousbooking: booking.value.previousbooking,
        postcode: booking.value.address.postcode,
      })
    )
    .catch((err: any) => actProperty.displayError(err));
};
watch(
  () => route.params.id,
  async (id: string) => {
    // if there is a value in route.query.parkedbookingid, that means we have unparked a booking
    // In that case do not load booking
    if (
      route.params.id &&
      (!route.query.parkedbookingid || route.query.parkedbookingid === "new")
    ) {
      loadform();
    }
  }
);

const loadform = async () => {
  await loadBooking(route.params.id);
  const scrollPositionString = localStorage.getItem("scrollPosition");
  if (scrollPositionString) {
    const scrollPosition = parseInt(scrollPositionString, 10);
    if (!isNaN(scrollPosition)) {
      nextTick(() => {
        window.scrollTo(0, scrollPosition);
      });
    }
  }
};

const setBackgroundStyle = () => {
  if (booking.value.cancelled) {
    document.documentElement.classList.add("cancelled-booking");
    document.body.classList.add("transparent-background");
    const elements = document.querySelectorAll(".card");
    elements.forEach((element) => {
      element.classList.add("transparent-background");
    });
  } else {
    document.documentElement.classList.remove("cancelled-booking");
    document.body.classList.remove("transparent-background");
    const elements = document.querySelectorAll(".card");
    elements.forEach((element) => {
      element.classList.remove("transparent-background");
    });
  }
};

const openCalendarInNewTab = () => {
  let query = "";
  if (!isNewBooking()) {
    query = `?id=${router.currentRoute.value.params.id}`;
  }
  let cdate = formatDate(currentdate.value);
  if (cdate === "31-12-2050") {
    cdate = formatDate(new Date());
  }
  window.open(`/scheduler/${cdate}${query}`);
};

const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};

const floorplan = computed(() => {
  return propertyspec.value.floorplan;
});

const showFloorplanDocument = (index: number) => {
  if (propertyspec.value.floorplan && propertyspec.value.floorplan.length > 0) {
    documentnumber.value = index;
    floorplanviewerkey.value = uuidv4();
    vfm.open("floorplan-viewer");
  }
};

const updateDraggableModal = (e: any) => {};

const ratedocument = computed((): Document => {
  if (
    customer.value &&
    customer.value.pricelist &&
    customer.value.pricelist.length
  )
    return customer.value.pricelist[0];
  else return new Document();
});

const showPricelistDocument = (index: number) => {
  if (customer.value?.pricelist && customer.value.pricelist?.length > 0) {
    documentnumber.value = index;
    vfm.open("rates-viewer");
  }
};

const dataentrydocuments = computed(() => {
  return booking.value.dataentrydocuments;
});
const selecteddataentrydocument = ref(0);
const showDataentryDocument = (index: number) => {
  if (
    booking.value.dataentrydocuments &&
    booking.value.dataentrydocuments.length > index
  ) {
    selecteddataentrydocument.value = index;
    vfm.open("dataentrydocument-viewer");
  }
};

const accessattachments = computed(() => {
  return booking.value.accessattachments;
});

const showAccessattachementDocument = (index: number) => {
  if (
    booking.value.accessattachments &&
    booking.value.accessattachments.length > 0
  ) {
    documentnumber.value = index;
    accessattachmentviewerkey.value = uuidv4();
    vfm.open("accessattachment-viewer");
  }
};

const worksorderdocuments = computed(() => {
  return booking.value.worksorderdocuments;
});

const changeStatus = (status: string) => {
  statusFlag.value = status;
  setBookingDeep({ path: "status", data: statusFlag.value });
};

const showWorksorderDocument = (index: number) => {
  if (
    booking.value.worksorderdocuments &&
    booking.value.worksorderdocuments.length > 0
  ) {
    documentnumber.value = index;
    worksorderdocumentviewerkey.value = uuidv4();
    vfm.open("worksorderdocument-viewer");
  }
};

const internalnotesdocuments = computed(() => {
  return booking.value.internalnotesdocuments;
});

const pinotesdocuments = computed(() => {
  return booking.value.pinotesdocuments;
});

const showInternalnotesDocument = (index: number) => {
  if (
    booking.value.internalnotesdocuments &&
    booking.value.internalnotesdocuments.length > 0
  ) {
    documentnumber.value = index;
    internalnotesdocumentviewerkey.value = uuidv4();
    vfm.open("internalnotesdocument-viewer");
  }
};

const showpinotesDocument = (index: number) => {
  if (
    booking.value.pinotesdocuments &&
    booking.value.pinotesdocuments.length > 0
  ) {
    documentnumber.value = index;
    pinotesdocumentviewerkey.value = uuidv4();
    vfm.open("pinotesdocument-viewer");
  }
};

const showTOTPDialog = () => {
  const modal = showOtpModal.value as any;
  modal.init();
  modal.show();
};

const isFixedTime = (): boolean => {
  let time =
    booking.value.preferredappointmenttime > 0
      ? booking.value.preferredappointmenttime
      : appointmenttime.value;
  let apptime = time
    ? moment(appointmenttime.value).utc().format("hh:mm A")
    : "";
  return apptime === "12:00 AM";
};

const checkAndSaveAndNew = () => {
  saveandnew.value = true;
  checkAndSave();
};
const checkAndSave = () => {
  if (!isBookingManagement.value && !isQCuser.value) {
    if (commonErrorValues.value.length > 0) {
      const modal = alertDialog6.value as any;
      modal.init("Warning", commonErrorValues.value.join(", "), "Ok", "", "");
      modal.show();
      return;
    }
    if (
      booking.value.managedtype === "Non-managed" &&
      (!booking.value.landlordname || !booking.value.landlordmobile)
    ) {
      const modal = alertDialog7.value as any;
      modal.init(
        "Warning",
        "This is a non-managed booking. Landlords contact (name/mobile number) is missing. Please ensure you get these details.",
        "Yes, I understand",
        "Back",
        "llDetailsMissingDialog"
      );
      modal.show();
    }

    if (!booking.value.bookedbyname || !booking.value.bookedbymobile) {
      const modal = alertDialog2.value as any;
      modal.init(
        "Warning",
        "Booked by contact (name/phone number) is missing. Please ensure you get these details.",
        "Yes, I understand",
        "Back",
        "bbDetailsMissingDialog"
      );
      modal.show();
    }

    if (
      booking.value.dataentrydocuments &&
      booking.value.dataentrydocuments.length > 0 &&
      !booking.value.emaillogs.dataentryconfirmationsentdate
    ) {
      const modal = alertDialog3.value as any;
      modal.init(
        "Warning",
        "You have not emailed the upload report to the typist",
        "Yes, I understand",
        "",
        ""
      );
      modal.show();
    }

    if (booking.value.appointmenttimeoutsidebooking) {
      const modal = alertDialog4.value as any;
      modal.init(
        "Warning",
        "You have changed appointment Timing but not Appointment Time",
        "Yes, I understand",
        "",
        ""
      );
      modal.show();
    }

    if (
      booking.value.jobtype === "checkout" &&
      !booking.value.isAfterpreviousbooking
    ) {
      const modal = alertDialog5.value as any;
      modal.init(
        "Error",
        "You have selected the wrong previous report",
        "Yes, I understand",
        "",
        ""
      );
      modal.show();

      // Can't save this booking, so return from here
      return;
    }
  }

  // Check management filters
  const preseterrors: PresetError[] = checkBooking(booking.value);
  setBookingDeep({ path: "preseterrors", data: preseterrors });
  saveAll();
};

const alertDialogClosed = (id: string, buttontext: string) => {};

const emailvalidation = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const mobileValidation = (mobile) => {
  const regex1 = /^\s*07\d{9}\s*$/; // UK mobile numbers starting with 07
  const regex2 = /^\s*\+\d+\s*$/; // UK mobile numbers with +44 international code
  const regex3 = /^\s*00\d+\s*$/; // International numbers with 00 prefix, followed by one or more digits
  return regex1.test(mobile) || regex2.test(mobile) || regex3.test(mobile);
};

const commonErrorValues = computed(() => {
  let errors = [];
  if (!landlordEmailValidation.value) {
    errors.push("Landlord emails are invalid");
  }
  if (!tenantEmailValidation.value) {
    errors.push("Tenant emails are invalid");
  }
  if (!tenantMobileValidation.value) {
    errors.push("Tenant mobiles are invalid");
  }
  return errors;
});

const landlordEmailValidation = computed(() => {
  let landlordemails = booking.value.landlordsarray
    .filter((f) => f?.llemail?.trim() !== "")
    .map((m: Landlord) => m.llemail?.trim());
  if (landlordemails.length > 0) {
    let invalidEmails = [];
    for (let email of landlordemails) {
      if (!emailvalidation(email)) {
        invalidEmails.push(email);
      }
    }
    if (invalidEmails.length > 0) {
      return false;
    }
  }
  return true;
});

const tenantEmailValidation = computed(() => {
  let tenantemails = booking.value.tenantsarray
    .filter((f) => f?.ttemail?.trim() !== "")
    .map((m: Tenant) => m.ttemail?.trim());
  if (tenantemails.length > 0) {
    let invalidEmails = [];
    for (let email of tenantemails) {
      if (!emailvalidation(email)) {
        invalidEmails.push(email);
      }
    }
    if (invalidEmails.length > 0) {
      return false;
    }
  }
  return true;
});

const tenantMobileValidation = computed(() => {
  let tenantmobiles = booking.value.tenantsarray
    .filter((f) => f?.ttmobile?.trim() !== "")
    .map((m: Tenant) => m.ttmobile?.trim());
  if (tenantmobiles.length > 0) {
    let invalidMobiles = [];
    for (let mobile of tenantmobiles) {
      if (!mobileValidation(mobile)) {
        invalidMobiles.push(mobile);
      }
    }
    if (invalidMobiles.length > 0) {
      return false;
    }
  }
  return true;
});

const saveAll = async () => {
  if (!isFixedTime()) {
    setBookingDeep({ path: "fixedtimenotes", data: "" });
  }
  await saveInvoice();
  let filteredTenants = booking.value.tenants.filter((tenant) => {
    return !(
      tenant.ttname.trim() === "" &&
      tenant.ttmobile.trim() === "" &&
      tenant.ttemail.trim() === ""
    );
  });

  setBookingDeep({ path: "tenants", data: filteredTenants });
  if (isNewBooking() && canSave()) {
    // if(booking.value?.previousbooking?.id) {
    //   booking.value.connectedbooking = booking.value.previousbooking;
    // }
    addBooking(booking.value)
      .then((newbooking: Booking) => {
        warnAboutDateNotChanged();
        return newbooking;
      })
      .then((newbooking: Booking) => {
        warnAboutHistoricReportNotSelected(newbooking);
        return newbooking;
      })
      .then((newbooking: Booking) => {
        // Check if there is new entry in customer point of contact
        // If there is add it to customer
        updateCustomerPOC();
        return newbooking;
      })
      .then(async (newbooking: Booking) => {
        if (newbooking.dataentrydocuments.length) {
          // First add the data entry report and then post it's id inside the booking
          await addNewDataentry(newbooking);

          // Save the booking with dataentry report id (this is saving it right after adding!)
          await saveBookingOutsideStore(newbooking);

          // Send email to data entry
          sendEmailToDataentry(newbooking).then(() =>
            updateDataentryEmailConfirmationSent(newbooking.id)
          );
        }
        resetUnsavedChanges();
        isSaving.value = false;
        toasted.success(`Added new booking`);
        channel.publish("newAppointmentAdded", {
          deviceid: deviceid,
          bookingid: newbooking.id,
        });
        if (saveandnew.value) {
          clearform();
        } else {
          router.push(`/diary/${newbooking.id}`);
        }
      })
      .catch((err: any) => {
        isSaving.value = false;
        saveandnew.value = false;
        actProperty.displayError(err);
      });
  } else if (canSave()) {
    if (statusFlag.value && booking.value.jobtype === "property visit") {
      if (statusFlag.value === "No show, to be rescheduled") {
        setBookingDeep({ path: "confirmaccess", data: false });
        setBookingDeep({ path: "tenantattending", data: "unsure" });
        setBookingDeep({
          path: "emaillogs.clientconfirmationsentdate",
          data: "",
        });
        setBookingDeep({ path: "emaillogs.feedbacksentddate", data: "" });
        setBookingDeep({
          path: "emaillogs.landlordconfirmationsentdate",
          data: "",
        });
        setBookingDeep({
          path: "emaillogs.tenantconfirmationreceiveddate",
          data: "",
        });
        setBookingDeep({
          path: "emaillogs.tenantconfirmationsentdate",
          data: "",
        });
        setBookingDeep({
          path: "smslogs.tenantconfirmationreceiveddate",
          data: "",
        });
        setBookingDeep({
          path: "smslogs.tenantconfirmationsentdate",
          data: "",
        });
        booking.value.tenantsarray.forEach(
          (obj: Tenant) => (obj.attending = "")
        );
      } else if (statusFlag.value === "Cancelled by client") {
        cancelBooking();
        return;
      }
      statusFlag.value = "";
    }
    setBookingDeep({ path: "smslogs.logs", data: booking.value.smslogs.logs });
    setBookingDeep({
      path: "emaillogs.tenantemaillogs",
      data: booking.value.emaillogs.tenantemaillogs,
    });

    // Create any datanentry reports and inform to data entry
    let newdataentry = false;
    if (booking.value?.dataentrydocuments?.length) {
      let report: Report | undefined = booking.value?.dataentryreport;
      if (!report || !report.id) {
        await addNewDataentry(booking.value);
        newdataentry = true;
      }
    }
    const confirmaccessoriginal = booking.value.original("confirmaccess");
    let appointmenttimeoriginal = booking.value.original("appointmenttime");
    // Ensure appointmenttimeoriginal is a valid date
    if (
      !appointmenttimeoriginal ||
      appointmenttimeoriginal === "Invalid date"
    ) {
      appointmenttimeoriginal = ""; // Default empty value
    }

    let apptime = moment(appointmenttime.value).utc().format("hh:mm A");
    const apptimeMap = {
      "12:30 AM": "option1",
      "12:35 AM": "option2",
    };
    apptime = apptimeMap[apptime] || apptime;
    if (
      booking.value.jobtype === "property visit" &&
      confirmaccessoriginal === "No" &&
      appointmenttimeoriginal === "" &&
      ["option1", "option2"].includes(apptime) &&
      booking.value.confirmaccess
    ) {
      await bookingConfirmationFunction(apptime);
    }

    saveAllBookingChanges()
      .then(() => {
        warnAboutHistoricReportNotSelected(booking.value);
      })
      .then(() => {
        // Check if there is new entry in customer point of contact
        // If there is add it to customer
        updateCustomerPOC();
      })
      .then(() => {
        if (booking.value?.connectedbooking?.id) {
          updateConnectedBooking(booking.value.id).then(() => {
            if (booking.value?.connectedbooking?.id) {
              channel.publish("appointmentChanged", {
                deviceid: deviceid,
                bookingid: booking.value.connectedbooking.id,
                source: "DiaryForm",
                target: "ConnectedBooking",
              });
            }
          });
          channel.publish("appointmentChanged", {
            deviceid: deviceid,
            bookingid: booking.value.id,
            source: "DiaryForm",
            target: "ConnectedBooking",
          });
        }
      })
      .then(() => {
        isSaving.value = false;
        unlockBooking(booking.value);
        channel.publish("appointmentChanged", {
          deviceid: deviceid,
          bookingid: booking.value.id,
          source: "DiaryForm",
        });
        toasted.success(UndoSaveToaster, {
          // Define the onClick event handler
          onClick: () => {
            setBooking(originalbooking.value);
            saveAllBookingChanges().then(() => {
              if (booking.value?.connectedbooking?.id) {
                updateConnectedBooking(booking.value.id).then(() =>
                  channel.publish("appointmentChanged", {
                    deviceid: deviceid,
                    bookingid: booking.value.connectedbooking.id,
                    source: "DiaryForm",
                    target: "ConnectedBooking",
                  })
                );
                channel.publish("appointmentChanged", {
                  deviceid: deviceid,
                  bookingid: booking.value.id,
                  source: "DiaryForm",
                  target: "ConnectedBooking",
                });
              }
              channel.publish("appointmentChanged", {
                deviceid: deviceid,
                bookingid: booking.value.id,
                source: "DiaryForm",
              });
            });
          },
          timeout: 10000,
          closeOnClick: true,
        });
        //  If there is a key collection job, change PI of that job
        booking.value.subbookings
          .filter((sb: Booking) => sb.subtype === Booking.KEY)
          .forEach((kb: Booking) => {
            kb.inspector = new Inspector(booking.value.inspector);
            saveBooking(kb);
          });

        // If this is provisional, makesure all the subjobs are provisional as well
        if (booking.value.provisional) {
          booking.value.subbookings
            .filter((sb: Booking) => !sb.provisional)
            .forEach((npb: Booking) => {
              npb.provisional = true;
              saveBooking(npb).then((savednpb: Booking) => {
                // Reaload current booking
                getBooking(booking.value.id);
              });
            });
        } else {
          booking.value.subbookings
            .filter((sb: Booking) => sb.provisional)
            .forEach((pb: Booking) => {
              pb.provisional = false;
              saveBooking(pb).then((savednpb: Booking) => {
                // Reaload current booking
                getBooking(booking.value.id);
              });
            });
        }
        if (saveandnew.value) {
          clearform();
        }
      })
      .then(() => {
        if (newdataentry) {
          // Send email to dataentry
          // Send email to data entry
          sendEmailToDataentry(booking.value).then(() =>
            updateDataentryEmailConfirmationSent(booking.value.id)
          );
        }
      })
      .catch((err: any) => {
        isSaving.value = false;
        actProperty.displayError(err);
      });
  }
};

const bookingConfirmationFunction = async (response: string) => {
  // common function
  let swapbooking: Booking | undefined = undefined;
  let bookingdate = moment(booking.value.startdate).format("DD-MM-YYYY");
  let insectorBookings = await getBookingsUnauthenticatedForInspectorWithDate({
    date: bookingdate,
    inspectorid: booking.value.inspector.id,
  });
  let data = await actProperty.processPvConfimrationResponse(
    booking.value,
    response,
    insectorBookings
  );
  if (data?.booking) {
    swapbooking = data.swapbooking;
    await saveBooking(data.booking);
    channel.publish("appointmentChanged", {
      deviceid: deviceid,
      bookingid: booking.value.id,
      source: "DiaryForm",
    });
    if (swapbooking && swapbooking.id != booking.value.id) {
      let swapNewBooking = new Booking({
        ...swapbooking,
        inspector: new Inspector({ id: swapbooking.inspector.id }),
      });
      await saveBooking(swapNewBooking);
      channel.publish("appointmentChanged", {
        deviceid: deviceid,
        bookingid: swapNewBooking.id,
        source: "DiaryForm",
      });
    }
  }
};

const updateCustomerPOC = () => {
  if (
    booking.value.bookedbyarray?.length > 0 &&
    customer.value.id &&
    booking.value.customer.companyName != "Private Landlord"
  ) {
    const missingcontacts: Bookedby[] = booking.value.bookedbyarray.filter(
      (b) => {
        if (b.bbemail != "") {
          const bbindex = customer.value.policies?.contactlist?.findIndex(
            (c) =>
              c.email?.trim()?.toLocaleLowerCase() ===
              b.bbemail?.trim()?.toLocaleLowerCase()
          );
          return bbindex < 0;
        }
      }
    );

    const updatedcontacts: Bookedby[] = booking.value.bookedbyarray.filter(
      (b) => {
        if (b.bbemail != "") {
          const bbindex = customer.value.policies?.contactlist?.findIndex(
            (c) =>
              c.email?.trim()?.toLocaleLowerCase() ===
              b.bbemail?.trim()?.toLocaleLowerCase()
          );
          let changed = false;
          if (bbindex >= 0) {
            let poc = customer.value.policies?.contactlist[bbindex];
            if (
              poc.name.trim() != b.bbname.trim() ||
              poc.mobile.trim() != b.bbmobile.trim()
            ) {
              changed = true;
            }
          }
          return changed;
        }
      }
    );

    if (missingcontacts?.length || updatedcontacts?.length) {
      updateCustomer({
        customerid: customer.value.id,
        newcontacts: missingcontacts,
        updatedcontacts: updatedcontacts,
      }).then((customer: Customer) => {});
    }
  }
};

const warnAboutDateNotChanged = () => {
  if (
    currentdatesameastoday() &&
    !isBookingManagement.value &&
    !isQCuser.value
  ) {
    const modal = alertDialog9.value as any;
    modal.init(
      "Warning",
      "You have not changed the date",
      "Ok",
      "",
      "postSaveWarningDialog"
    );
    modal.show();
  }
};

const warnAboutHistoricReportNotSelected = (booking: Booking) => {
  if (
    booking.checkoutWithoutPreviousBooking &&
    booking.checkoutWithoutPreviousReport &&
    !isBookingManagement.value &&
    !isQCuser.value
  ) {
    const modal = alertDialog10.value as any;
    modal.init(
      "Warning",
      'This booking must be incorrect. You have chosen "Off ACT report" but not assigned an ACT report',
      "Ok",
      "",
      "postSaveWarningDialog"
    );
    modal.show();
  }
};

const clearform = () => {
  nextTick(() => {
    saveandnew.value = false;
    resetUnsavedChanges();
    const newbooking: Booking = initnewbooking();
    setBooking(newbooking);
    channel.publish("unselectBooking", { deviceid: deviceid });
    router.push("/diary/new").catch((err) => {});
    window.scrollTo(0, 0);
  });
  clearUnsavedChanges();
};

const saveInvoice = async () => {
  let invoices: Report[] = [];
  if (booking.value.invoices && booking.value.invoices.length) {
    for (let i = 0; i < booking.value.invoices.length; i++) {
      let invoice: Report = booking.value.invoices[i];
      // If this invoice report is locked, do not change
      if (invoice.locked) {
        invoices.push(invoice);
        continue;
      }

      if (invoice && invoice.id) {
        invoice = prepareInvoice(invoice, i);
      } else if (invoice?.fees && invoice?.fees.length) {
        let inv: Report = new Report();
        inv.fees = invoice.fees;
        invoice = prepareInvoice(inv, i);
      }

      if (invoice.id) {
        await updateInvoice(invoice)
          .then((report: Report) => {
            invoices.push(report);
          })
          .catch((err: any) => actProperty.displayError(err));
      } else if (invoice.totalFees() > 0) {
        await addInvoice(invoice)
          .then((report: Report) => {
            invoices.push(report);
            return report;
          })
          .then((report: Report) => {
            // Register the main invoice as the main report with the master property record
            if (report.fees.length && !report.fees[0].name) {
              registerReportWithMasterProperty(report);
            }
          })
          .catch((err: any) => actProperty.displayError(err));
      }
    }
  }
  setInvoices(invoices);
};

const prepareInvoice = (invoice: Report, index: number): Report => {
  invoice.type = booking.value.jobtype;
  invoice.address = new Address(booking.value.address);
  if (index === 0) {
    invoice.address.line1 = "PROFORMA INVOICE " + booking.value.address.line1;
  } else {
    let feestitle = invoice.fees[0].name;
    invoice.address.line1 = feestitle + " " + booking.value.address.line1;
    invoice.invoiceentry = true;
  }
  invoice.date = booking.value.startDate;
  invoice.workorderNumber = booking.value.worksorder;
  if (booking.value.customer) {
    invoice.customer.id = booking.value.customer.id;
    invoice.customerId = booking.value.customer.id;
    invoice.customer.office = booking.value.customer.office;
    invoice.customer.co = booking.value.customer.co;
    invoice.customer.companyName = booking.value.customer.companyName;
    invoice.customer.companySlug = booking.value.customer.companySlug;
    invoice.customer.branchName = booking.value.customer.branchName;
    invoice.customer.address.line1 = booking.value.agencyaddress.line1;
    invoice.customer.address.line2 = booking.value.agencyaddress.line2;
    invoice.customer.address.town = booking.value.agencyaddress.town;
    invoice.customer.address.county = booking.value.agencyaddress.county;
    invoice.customer.address.postcode = booking.value.agencyaddress.postcode;
    invoice.customer.acClientCode = booking.value.customer.acClientCode;
    invoice.customer.nominalCode = booking.value.customer.nominalCode;
    invoice.customer.feedbacklinkactive =
      booking.value.customer.feedbacklinkactive;
    invoice.customer.feedbacklinkactivedays =
      booking.value.customer.feedbacklinkactivedays;
    invoice.customer.workordernumberrequired =
      booking.value.customer.workordernumberrequired;
    invoice.customer.accountsettings.llemailrequired =
      booking.value.customer.accountsettings.llnamerequired;
    invoice.customer.hidefrontpageaddress =
      booking.value.customer.hidefrontpageaddress;
    invoice.customer.nominalCode = booking.value.customer.nominalCode;
    invoice.customer.acClientCode = booking.value.customer.acClientCode;

    invoice.customer.reportsettings.cosummaryactive =
      booking.value.customer.reportsettings.cosummaryactive;
    invoice.customer.reportsettings.redlinecoactive =
      booking.value.customer.reportsettings.redlinecoactive;
    invoice.flagged = booking.value.customer.reportsettings.redlinecoactive;
    invoice.customer.reportsettings.cosmredlineactive =
      booking.value.customer.reportsettings.cosmredlineactive;
  }

  invoice.signatures = [];
  const llsignature = new Signature();
  llsignature.type = "landlord";
  if (booking.value.landlordname) {
    invoice.client.name = booking.value.landlordname;
    llsignature.name = booking.value.landlordname;
  }
  if (booking.value.landlordemail) {
    invoice.client.email = booking.value.landlordemail;
    llsignature.email = booking.value.landlordemail;
  }
  invoice.signatures.push(llsignature);

  if (booking.value.tenants) {
    booking.value.tenants.forEach((t) => {
      const ttsignature = new Signature();
      ttsignature.type = "tenant";
      if (t.ttname) ttsignature.name = t.ttname;
      if (t.ttemail) ttsignature.email = t.ttemail;
      invoice.signatures.push(ttsignature);
    });
  }

  // Also populate tenants
  if (!invoice.signatures && !invoice.signatures.length) {
    if (booking.value?.tenants?.length) {
      invoice.signatures = [];
      booking.value.tenants.forEach((t) => {
        let signature = new Signature();
        signature.name = t.ttname;
        signature.email = t.ttemail;
        signature.type = "tenant";
        invoice.signatures.push(signature);
      });
    }
  }
  return invoice;
};

const addNewDataentry = async (book: Booking) => {
  let report = new Report();
  report.type = "inventory";
  report.date = datetimeToUTC();
  report.address = new Address(book.address);
  report.address.line1 = "UPLOAD " + book.address.line1;
  report.user = dataentryemailaddress.value;
  report.booking = book;
  report.dataentrystatus = "Not Acknowledged";
  await addDateentryAction(report)
    .then((r: Report) => {
      book.dataentryreport = r;
      setBookingDeep({ path: "dataentryreport", data: r });
      resetUnsavedChanges();
    })
    .catch((err: any) => actProperty.displayError(err));
};

const dataentryemailaddress = computed(() => {
  const value = systemproperties.value?.find(
    (sp: SystemProperty) => sp.propertykey === "dataentry.email"
  )?.value;
  let emailaddress = "";
  if (value) {
    let spalitarray = value.split(",");
    if (spalitarray && spalitarray.length && spalitarray[0])
      emailaddress = spalitarray[0];
  }
  return emailaddress;
});

const cancel = () => {
  if (booking.value.id) {
    getBooking(booking.value.id).then(() =>
      nextTick(() => {
        resetUnsavedChanges();
      })
    );
  } else {
    const newbooking: Booking = initnewbooking();
    setBooking(newbooking);
    channel.publish("unselectBooking", { deviceid: deviceid });
    router.push("/diary/new").catch((err) => {});
  }
  setTimeout(() => {
    resetUnsavedChanges();
  }, 500);
};

const cancelBooking = () => {
  const modal = bookingCancelModal.value as any;
  modal.init(booking.value);
  modal.show();
  statusFlag.value = "";
};

const restoreBooking = () => {
  restoreBookingInStore(booking.value)
    .then((b: Booking) => {
      booking.value.cancelled = false;
      toasted.success("Booking restored");
      channel.publish("appointmentRestored", {
        deviceid: deviceid,
        bookingid: booking.value.id,
      });
      setBackgroundStyle();
      setBookingDeep({ path: "auditlog", data: b.auditlogs });
      resetUnsavedChanges();
    })
    .catch((err: any) => actProperty.displayError(err));
};

const hideBookingCancelModal = (val: boolean) => {
  if (val) {
    showDeleteBookingEmailDialog();
  }
};

const showDeleteBookingEmailDialog = () => {
  const modal = deletebookingEmailModal.value as any;
  modal.init();
  modal.show();
};

const isNewBooking = () => {
  return route.params.id == "new";
};

const canSave = () => {
  const bookingStartDate = moment(booking.value.startdate);
  const twoYearsLater = moment().add(2, "years");
  let result = false;
  if (
    bookingStartDate.isSameOrBefore(twoYearsLater) &&
    booking.value.inspector &&
    booking.value.inspector.id &&
    booking.value.jobtype &&
    booking.value.internaljobtype &&
    booking.value.startdate &&
    !actProperty.isFiveam(booking.value.startdate) &&
    booking.value.enddate &&
    !actProperty.isFiveam(booking.value.enddate) &&
    booking.value.address?.line1 &&
    booking.value.address?.postcode
  ) {
    result = true;
  }
  return result;
};

const prepjobpresent = computed(() => {
  let prepjob = booking.value.subbookings.find(
    (sb: Booking) => sb.subtype === Booking.PREP
  );
  return prepjob?.id ? true : false;
});

const showSubJobAddModal = (subjobtype: string) => {
  const modal = subJobAddModal.value;
  modal.init(booking.value, subjobtype);
  modal.add();
  modal.show();
};
const hideSubJobAddModal = () => {
  if (route.name === "newbooking" || route.name === "existingbooking") {
    publishCurrentdateChange(
      moment(booking.value.startdate)
        .utc()
        .format(actProperty.bookingdateformat)
    );
  }
};

const showSubJobEditModal = (subbooking: Booking) => {
  subjobbooking.value = subbooking;
  nextTick(() => {
    const modal = subJobEditModal.value as any;
    modal.init(subbooking, subbooking.subtype);
    modal.edit();
    modal.show();
  });
};
const hideSubJobEditModal = () => {
  if (route.name === "newbooking" || route.name === "existingbooking") {
    publishCurrentdateChange(
      moment(booking.value.startdate)
        .utc()
        .format(actProperty.bookingdateformat)
    );
  }
};

const publishCurrentdateChange = (val: string) => {
  channel.publish("currentdateChanged", {
    deviceid: deviceid,
    bookingdate: val,
  });
};

const subJobCreated = (booking: Booking) => {
  channel.publish("subJobCreated", booking.id);
};

const showAuditLogsModal = () => {
  const modal = auditLogsModal.value as any;
  modal.init(booking.value);
  modal.show();
};

const getSubjobPrompt = (s: Booking) => {
  let prompt: string = "";
  if (s.subtype === "Shared") {
    prompt = `Shared job with ${s.inspector?.name}`;
  } else if (s.subtype === "Prep") {
    prompt = `Prep job with ${s.inspector?.name}`;
  } else if (s.subtype === "Key") {
    prompt = `Key collection ${s.inspector?.name}`;
  } else if (s.subtype === "Revisit") {
    prompt = `Revisit ${s.inspector?.name}`;
  }
  return prompt;
};

const locateSubjob = (s: Booking) => {
  channel.publish("locateBooking", {
    deviceid: deviceid,
    bookingid: s.id,
    date: s.startdate,
  });
  showSubJobEditModal(s);
};

const toggleIssued = () => {
  setBookingDeep({ path: "issued", data: !booking.value.issued });
};

const currentdatesameastoday = (): boolean => {
  return moment(currentdate.value).isSame(new Date(), "day");
};

const appointmentSelected = async (message: any) => {
  if (message?.data) {
    // Make sure the event is coming from the same pc
    if (message.data.deviceid != deviceid) return;

    // Only load the booking if this is indeed a booking form!!!
    if (!route.fullPath.startsWith("\/diary\/")) return;

    if (message?.data && message.data?.bookingid) {
      let unsavedChanges = await hasUnsavedChangesAction();
      if (
        unsavedChanges &&
        (route.params.id != message.data?.bookingid ||
          route.params.id === "new")
      ) {
        channel.publish("unsavedChangesOnFormForExistingBooking", message.data);
        const modal = alertDialog12.value as any;
        if (modal) {
          modal.init(
            "Warning",
            "There are unsaved changes. Please first save all the changes to load the selected booking.",
            "OK"
          );
          modal.show();
        }
      } else {
        channel.publish("appointmentLoading", message.data);
        // Do not load google, personal, sub type bookings
        if (
          message.data.googleid ||
          message.data.subtype ||
          message.data.jobtype === Booking.PERSONAL
        ) {
          return;
        } else {
          router.push({
            name: "existingbooking",
            params: { id: message.data.bookingid },
          });
        }
      }
    }
  }
};

const bookingConfirmResponse = async (message: any) => {
  if (message?.data) {
    if (message.data?.bookingid === route.params.id) {
      loadform();
      const modal = alertDialog8.value as any;
      if (modal) {
        modal.init("Warning", "The tenant has responded", "OK");
        modal.show();
      }
    }
  }
};

const startNewBooking = async (message: any) => {
  if (message?.data) {
    // Make sure the event is coming from the same pc
    if (message.data.deviceid != deviceid) return;

    if (message?.data) {
      if (hasUnsavedChanges.value) {
        channel.publish("unsavedChangesOnFormForNewBooking", message.data);
        const modal = alertDialog11.value as any;
        if (modal) {
          modal.init(
            "Warning",
            "There are unsaved changes. Please first save all the changes to start a new booking.",
            "OK"
          );
          modal.show();
        }
      } else {
        channel.publish("appointmentLoading", message.data);
        const newbooking: Booking = initnewbooking();
        setBooking(newbooking);
        channel.publish("unselectBooking", { deviceid: deviceid });
        router.push("/diary/new").catch((err) => {});
      }
    }
  }
};

const bookedbyaddresses = computed(() => {
  let toaddresses: string[] = [];
  if (booking.value && booking.value.bookedby && booking.value.bookedby.length)
    toaddresses = booking.value.bookedby
      .filter((b: Bookedby) => b.bbemail)
      .map((b: Bookedby) => b.bbemail);
  return [...toaddresses];
});

const fromaddress = computed(() => {
  let prop = systemproperties.value?.find(
    (sp: SystemProperty) => sp.propertykey === "booking.email.fromaddress"
  );
  return prop ? prop?.value : "";
});
</script>

<style scoped lang="scss">
.calendar-pane {
}

.diary-calendar {
}

.booking-pane {
  flex-grow: 1;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.booking-sections {
  min-width: 1258px;
  padding-bottom: 20px;
  height: 90vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.booking-sections::-webkit-scrollbar {
  width: 20px;
}

.vertical-panes {
  width: 100%;
  height: 90vh;
  max-height: 100%;
  border: 1px solid rgb(255, 255, 255);
}

.vertical-panes > .pane {
  text-align: left;
  padding: 15px;
  overflow: hidden;
  background: rgb(255, 255, 255);
}

.vertical-panes > .pane ~ .pane {
  border-left: 1px solid #ccc;
}

.multipane.layout-v .multipane-resizer {
  margin: 0;
  left: 0;
  /* reset default styling */
  width: 5px;
  background: #253746;
}

.multipane.layout-h .multipane-resizer {
  margin: 0;
  top: 0;
  /* reset default styling */
  height: 5px;
  background: #253746;
}

.card {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {
  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4caf50;
  border-color: #4caf50;
}

.status-bar-separator {
  color: #f8f9fa;
}

#status-bar-switches {
  margin-left: 2rem;
}

#status-bar-actions {
  width: 30rem;
  text-align: right;

  span {
    margin-right: 0.25rem;
  }
}

.disabledbutton {
  display: none;
}
</style>

<style lang="scss">
.booking-section {
  border-color: #253746 !important;
}

.booking-section-header {
  color: #eadb40;
  background-color: #253746 !important;
}

.transparent-background {
  background: transparent !important;
}

.cancelled-booking {
  background: repeating-linear-gradient(
    135deg,
    rgb(136, 8, 8, 0.2),
    rgb(136, 8, 8, 0.2) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}

.historical-booking {
  color: #808b96;
}
</style>
