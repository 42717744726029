<template>
  <div class="reports">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Reports</h1>
        </div>
        <div v-if="isAdministrator" class="col-sm-6 text-right">
          <button
            class="btn btn-lg btn-primary mr-3"
            data-toggle="modal"
            data-target="#report-migrate-modal"
          >
            <i class="fas fa-file-import"></i>
            Migrate Report
          </button>
          <router-link class="btn btn-lg btn-primary" to="/reports/new">
            <i class="fas fa-plus-square"></i>
            New Report
          </router-link>
        </div>
      </div>
    </header>

    <div class="row mb-3">
      <ReportsSearchByMonth class="col-sm-2" />
      <button
        type="submit"
        class="btn btn-outline-primary col-sm-1"
        @click="resetSearch()"
      >
        <i class="fas fa-calendar-day"></i>
        This month
      </button>
      <ReportsSearchByString
        :class="route.query.search &&  reports.length > 0 ? 'col-sm-3' : 'col-sm-6'"
        placeholder="Search all reports"
      />
      <FilterByString
        v-if="route.query.search && reports.length > 0"
        class="col-sm-3"
        placeholder="Filter all reports"
      />
      <div class="col-sm-3" v-if="isAdministrator">
        <singleselect-text
          v-model="currentoffice"
          :options="officeNameOptions"
          @select="onSelectOffice"
          :allow-empty="true"
          ><template v-slot:placeholder>
            <span>Region</span>
          </template>
          <template 
              #clear
              v-if="currentoffice">
            <i
              @mousedown.prevent.stop="clearCurrentOffice()"
              class="multiselect__clear fa fa-times"
              aria-label="Clear Region"
            ></i
          ></template>
        </singleselect-text>
      </div>
    </div>

    <ReportsList />

    <!-- Modals -->
    <ReportMigrateModal />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment-timezone';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

// Components
import ReportsList from "@/components/report/List.vue";
import ReportsSearchByMonth from "@/components/report/SearchByMonth.vue";
import ReportsSearchByString from "@/components/report/SearchByString.vue";
import ReportMigrateModal from "@/components/modals/ReportMigrateModal.vue";
import FilterByString from "@/components/report/FilterByString.vue";

// Types
import { Report, Office } from "@/models";

// Setup
const store = useStore();
const router = useRouter();
const route = useRoute();
const reportsState = ref(store.state.reports);
const isAdministrator = computed((): boolean => store.getters['auth/isAdministrator']);
const offices = computed((): Office[] => store.getters['offices/getOffices']);
const reports = computed((): Report[] => store.getters['reports/list']);

const currentoffice = ref();
// Methods
const getReports = (): Promise<any> => {
  return store.dispatch('reports/getReports');
};

const setMonth = (month: string): Promise<any> => {
  return store.dispatch('reports/setMonth', month);
};

const setSearch = (search: string): Promise<any> => {
  return store.dispatch('reports/setSearch', search);
};

const setFilter = (search: string): Promise<any> => {
  return store.dispatch('reports/setFilter', search);
};


const setCurrentPage = (page: number): Promise<any> => {
  return store.dispatch('reports/setCurrentPage', page);
};

const resetCurrentPage = (): Promise<any> => {
  return store.dispatch('reports/resetCurrentPage');
};

const setCurrentOffice = (office: string) => {
  return store.commit('reports/setCurrentOffice', office);
};

const officeNameOptions = computed(() => offices.value.map(o => o.name));

const onSelectOffice = async (officename: string) => {
  const office: Office = offices.value.find(o => o.name === officename);
  if (office?.id) {
    await setCurrentOffice(office.id);
    await getReports();
  }
};

const clearCurrentOffice = async () => {
  currentoffice.value = '';
  await setCurrentOffice('');
  await getReports();
};

const resetSearch = () => {
  if (route.query.month !== moment().format("YYYY-MM")) {
    router.push({
      path: "reports",
      query: { month: moment().format("YYYY-MM") },
    });
    resetCurrentPage();
  }
};

// Watchers
watch(() => route.query.month, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setMonth(newVal);
    await getReports();
  }
});

watch(() => route.query.search, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setSearch(newVal);
    await getReports();
  }
});

watch(() => route.query.filter, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setFilter(newVal);
    await getReports();
  }
});

watch(() => route.query.page, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setCurrentPage(newVal);
    await getReports();
  }
});

// Handle component creation logic
onMounted(() => {
  document.title = 'Reports';
  const queryParams = route.query;
  if (typeof queryParams.month === 'string' && queryParams.month.length) {
    const page = queryParams.page ? Number(queryParams.page) : 1;
    setCurrentPage(page);
    setMonth(queryParams.month).then(() => getReports());
  } else if ((typeof queryParams.search === 'string' && queryParams.search.length) || (typeof queryParams.filter === 'string' && queryParams.filter.length)) {
    if ((typeof queryParams.search === 'string' && queryParams.search.length) && (typeof queryParams.filter === 'string' && queryParams.filter.length)) {
      setSearch(queryParams.search);
      setFilter(queryParams.filter).then(() => getReports());
    } else if(typeof queryParams.search === 'string' && queryParams.search.length){
      setSearch(queryParams.search).then(() => getReports());
    } else if(typeof queryParams.filter === 'string' && queryParams.filter.length){
      setFilter(queryParams.filter).then(() => getReports());
    }
  } else if (reportsState.value.month) {
    router.push({
      path: "reports",
      query: { month: reportsState.value.month },
    });
  } else if (reportsState.value.search) {
    router.push({
      path: "reports",
      query: { text: reportsState.value.search },
    });
  } else {
   
    router.push({
      path: "reports",
      query: { month: moment().format("YYYY-MM") },
    });
  }

  /*
  Temperary code written for preparing JSON files for the reports
  const dataentry: string[] = [
    '01,Apartment 303 6 Wood Crescent London W12 7GS,173234',
    '02,Flat 29 Gun Place London E1W 2RX,173645',
    '03,18 Mountain Ash Tilden Road Winchester SO21 2DW,178183',
    '04,Flat 32 Ascot Court London NW8 9RY,178009',
    '05,12 Poppy Terrace Carterton OX18 1HH,171608',
    '06,Flat 1 Elm Park Chambers London SW10 9QA,173906',
    '07,16 Hartwell House Gibbs Couch Watford WD19 5EQ,172220',
    '08,78 Hargwyne Street London SW9 9RJ,169470',
    '09,6 Walpole Avenue Richmond TW9 2DJ,165777',
    '10,Flat 4 Mill Cleave West Byfleet KT14 6DY,164903',
    '11,90 White Lodge Close Isleworth TW7 6TR,166847',
    '12,Flat 92 Centrium Woking GU22 7PD,164815',
    '13,25 Kingfisher Place Reading RG1 8BQ,162744',
    '14,Flat 16 Charles Court Teddington TW11 0JT,152429',
    '15,6 Blenheim Terrace Blenheim Place Reading RG1 7ST,157560',
    '16,Top Floor Flat 29 Radnor Mews London W2 2SA,167067',
    '17,34a St Peter\'s Street London N1 8JT,163781',
    '18,Flat 603 Java House London E14 0LG,163316',
    '19,1 Hanover House Crown Square London SE1 2SE,164781',
    '20,F6 Cornwell House 59 The Avenue Richmond TW9 2AL,164766',
    '21,Flat 808 1 Pepys Street London EC3N 2NU,152089',
    '22,Flat 15 Sundial Court Surbiton KT5 9AN,152343',
    '23,Flat 3 37 St Augustines Road London NW1 9RL,150428',
    '24,Flat 4 2 Thames Avenue Windsor SL4 1QP,150912',
    '25,Flat 805 Modena House London E14 0RQ,152305',
    '26,Flat 7 28 Brackendale Close Camberley GU15 1HP,168973',
    '27,Room 5 37 Lamarsh Road Botley Oxford OX2 0LD,159223',
    '28,Flat 10 Bath House London SE1 6PU,163480',
    '29,Flat 12 Radnor House Reading RG1 6QP,124748',
    '30,2 Tamarind Court 18 Gainsford Street London SE1 2NE,177936',
    '31,86 Great Clarendon Street Oxford OX2 6AT,127216',
    '32,Flat 17 Central Park Southampton SO14 1LP,177810',
    '33,Flat A Cliveden House 66 St Leonards Road Windsor SL4 3BY,168651',
    '34,First Floor Flat 15 Field Road Reading Berkshire RG1 6AP,141749',
    '35,9 Bishops Court John Garne Way Headington OX3 0TX,144850',
    '36,5b Drayton Park London N5 1NU,168948',
    '37,Room 1 3 Brock Grove Oxford OX2 0FD,168883',
    '38,Room 3 3 Brock Grove Oxford OX2 0FD,174546',
    '39,Flat K 6 Welch Way Witney OX28 6FW,168827',
    '40,Flat 8 Icon Building London SW11 3JW,168760',
    '41,Flat 37 Tamarind Court London SE1 2NE,168615',
    '42,45 Park House Bridge Road Welwyn Garden City AL8 6TR,168564',
    '43,Apartment 3210 8 Walworth Road London SE1 6EJ,168453',
    '44,Flat 1 Chantrey House London SW1W 9LN,164971',
    '45,Apartment 509 Kingwood House London E1 8QF,164962',
    '46,43C Gaisford Street London NW5 2EB,164945',
    '47,21c Sheffield Terrace London W8 7NQ,164900',
    '48,9 Latimer House Angus Court Thame OX9 3JB,164825',
    '49,Flat 5 98 Beaufort Street London SW3 6BU,168259',
    '50,Flat 137 14 New Crane Place London E1W 3TU,168229',
    '51,833a Fulham Road London SW6 5HQ,168182',
    '52,48 Telfords Yard London E1W 2BQ,168071',
    '53,Flat 3 The Old Warehouse Witney OX28 1DD,167985',
    '54,41B Almeric Road London SW11 1HL,167908',
    '55,Flat 11 44 Denbigh Street London SW1V 2EU,167489',
    '56,Apartment 8 Baroc House London W6 8NL,151980',
    '57,Flat 8 Burlington House Waltham Cross EN8 7FG,151942',
    '58,Flat 3 1 Lorne Street Reading RG1 7YW,151933',
    '59,Flat 4 644 Wandsworth Road London SW8 3JW,151775',
    '60,4 2 Henry Liddon Court Marcham Road Abingdon OX14 1AF,151680',
    '61,Flat 4 Creffield House London W1G 8UA,151380',
    '62,12C Durham Road London W2 5PB,151364',
    '63,Room 1 83a West Way Oxford OX2 9JY,148903',
    '64,Flat 52 Chantrey House London SW1W 9LN,148849',
    '65,Flat 7 18 Cresswell Gardens London SW5 0BQ,148845',
    '66,Flat 21 Horley Court London W2 3JA,148701',
    '67,Flat 33 Hogarth House London SW1P 4HS,148544',
    '68,Flat 8 41 Craven Hill Gardens London W2 3EA,148497',
    '69,Apartment 4 25 Castlereagh Street London W1H 5BU,148335',
    '70,7 Leonard Court London W8 6NL,166841',
    '71,Flat 3 Rose Dene Witney OX29 0RB,169012',
    '72,Flat 104 Kingmaker House Barnet EN5 1FJ,168981',
    '73,99 Wetherby Road Bicester OX26 1BH,168941',
    '74,Flat 85 Wallbrook Gardens London E16 1RU,168886',
    '75,Apartment 6 Foundry House London SW8 4BE,168849',
    '76,Flat 22 The Heights London NW3 6XS,168838',
    '77,6 Beardsley Way London W3 7YQ,168825',
    '78,3a Mallinson Road London SW11 1BW,174608',
    '79,Apartment 19 Regency House London SW6 2SB,152187',
    '80,Flat 29 3 St. Pancras Way London NW1 0PB,171384',
    '81,Flat 1 Twyford Court Winchester SO23 7AL,171262',
    '82,Flat 34 Windsor House Winchester SO23 8DT,170908',
    '83,Second Floor Flat 33b Moorhouse Road London W2 5DH,171214',
    '84,56B Northcote Road London SW11 1PA,170829',
    '85,Flat 5 37 Queens Gardens London W2 3AA,170511',
    '86,11 Waterside Gardens Reading RG1 6QE,170707',
    '87,Flat 2 297 Sandycombe Road Richmond TW9 3LU,170234',
    '88,8 Edison House Flambard Way Godalming GU7 1FF,170097',
    '89,38 Tithe Barn Close Kingston upon Thames KT2 6RZ,169907',
    '90,Flat 702 5 Belvedere Road London SE1 7AF,173308',
    '91,Flat 56 Wallbrook Gardens London E16 1RU,162148',
    '92,Flat 3 Kenton Court Twickenham TW1 2HU,162105',
    '93,15a Onslow Road Richmond TW10 6QH,174260',
    '94,Flat 2206 Ontario Tower London E14 9JD,174160',
    '95,91 Harris Close Hounslow TW3 4JJ,174086',
    '96,43a Park Road Kingston upon Thames KT2 6DB,173741',
    '97,Flat 8 210 Bedford Hill London SW12 9HJ,173785',
    '98,Flat 1 Langley House Woking GU21 7RL,161928',
    '99,Flat 12 Axis Court London SE16 4UQ,147544',
    '100,Flat 34 Cobden Court 22a Southampton SO18 1DZ,147360'
  ];

  for(let i = 0; i < dataentry.length; i++){
    const data = dataentry[i].split(',');
    const partialreport: Report = await getSourceReport(data[2]);
    if(partialreport?.id) {
      const report: Report = await getReport(partialreport.id);
      if(report?.id) {
        // Save report JSON to local file
        const fileindex = i + 1;
        const filename = `${fileindex.toString().padStart(2, '0')} JSON - ${data[1].slice(0, 20)}.json`;
        const json = JSON.stringify(report);
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }
    */

});
</script>

