<template>
  <div id="app">
    <loading-modal></loading-modal>

    <!-- Conditionally render the navbar -->
    <navbar v-if="showNavbar" />

    <div class="container-fluid mt-3">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance, provide } from 'vue';
import store from "@/store";

import Navbar from "./components/Navbar.vue";
import LoadingModal from "./components/modals/LoadingModal.vue";

import { AppState } from "@/store/app/types";
import moment from "moment-timezone";

const appState: AppState = store.state.app;
const route = ref(getCurrentInstance()?.proxy!.$route);

// Reactive property to control navbar visibility
const showNavbar = ref(true);

// Provide `showNavbar` so child components can access and modify it
provide("showNavbar", showNavbar);

moment.tz.setDefault(appState.timezone);

window.addEventListener("beforeunload", (event) => {
  if (
    store.getters["customers/hasUnsavedChanges"] ||
    store.getters["dictionary/hasUnsavedChanges"] ||
    store.getters["reports/hasUnsavedChanges"] ||
    store.getters["customerdictionary/hasUnsavedChanges"]
  ) {
    event.preventDefault();
    event.returnValue = "";
  } else if (
    (route.value?.name === "newbooking" || route.value?.name === "existingbooking") &&
    store.getters["diary/hasUnsavedChanges"]
  ) {
    event.preventDefault();
    event.returnValue = "";
  }
});

onMounted(() => {
  document.title = "ACT Property CMS";
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style src="sweetalert2/dist/sweetalert2.min.css"></style>
<style src="vue-toastification/dist/index.css"></style>
<style src="@/assets/css/dx.generic.generic-blue-light-act.css"></style>

<style lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

html {
  background-color: $white;
}
body {
  background-color: $white;
  min-height: 100vh;
}

h1,
h2,
h3,
fieldset {
  margin-bottom: 1em;
}
#app {
  padding-bottom: 6em;
}
.navbar {
  &.fixed-top {
    border-bottom: 1px solid $black;
  }
  &.fixed-bottom {
    border-top: 1px solid $black;
  }
}
table {
  table-layout: fixed;
}
.btn-link {
  text-decoration: underline;
}
input[type="checkbox"] {
  line-height: 1.5;
}

// An optional custom "X" icon to clear Vue Multiselect
// https://github.com/shentao/vue-multiselect/issues/867
.multiselect__clear {
  background-color: white;
  color: #999;
  cursor: pointer;
  padding: 10px 7px;
  position: absolute;
  right: 30px;
  top: 2px;
  bottom: 2px;
  z-index: 1;
  &:hover {
    color: #dc3545;
  }
}

// Adjust the padding to allow for the Vue Multiselect "X" icon
.multiselect__clear + .multiselect__tags {
  padding-right: 55px;
}

.btn {
  cursor: pointer;
}

.color-picker {
  border: 3px solid !important;
}

.flag-icon {
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px, 22px;
  margin: auto;
  position: absolute;
}
.flag-icon::before {
  content: none !important;
}
.fa-bell-exclamation {
  background-image: url("~@/assets/images/fa-bell-exclamation.png");
}
.fa-bell-exclamation-red {
  background-image: url("~@/assets/images/fa-bell-exclamation-red.png");
}
.fa-bell-on {
  background-image: url("~@/assets/images/fa-bell-on.png");
}
.fa-brake-warning {
  background-image: url("~@/assets/images/fa-brake-warning.png");
}
.fa-brush {
  background-image: url("~@/assets/images/fa-brush.png");
}
.fa-hammer {
  background-image: url("~@/assets/images/fa-hammer.png");
}
.fa-lightbulb-exclamation {
  background-image: url("~@/assets/images/fa-lightbulb-exclamation.png");
}
.fa-screwdriver-wrench {
  background-image: url("~@/assets/images/fa-screwdriver-wrench.png");
}
.fa-triangle-exclamation {
  background-image: url("~@/assets/images/fa-triangle-exclamation.png");
}
.fa-wrench {
  background-image: url("~@/assets/images/fa-wrench.png");
}

.ck-list {
  max-height: 250px;
  overflow-y: scroll;
}

.outline .multiselect__tags {
  border: 2px solid red !important;
}

.multiselect__option .red {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  min-height: 40px !important;
  line-height: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.multiselect__option .amber {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  min-height: 40px !important;
  line-height: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.multiselect__option .black {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  min-height: 40px !important;
  line-height: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.multiselect__option .green {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  min-height: 40px !important;
  line-height: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.multiselect__option--highlight .red {
  background-color: orangered;
  color: white !important;
}

.multiselect__option--highlight .black {
  background-color: gray;
  color: white !important;
}
.multiselect__option--selected .red {
  background-color: orangered;
  color: white !important;
}
.multiselect__option--highlight .amber {
  background-color: orange;
  color: white !important;
}
.multiselect__option--selected .amber {
  background-color: orange;
  color: white !important;
}
.multiselect__option--selected .black {
  background-color: grey;
  color: white !important;
}
.multiselect__option--highlight .green {
  background-color: #41b883;
  color: white !important;
}
.multiselect__option--selected .green {
  background-color: #41b883;
}

.dx-switch-container {
  border: 0px;
}
.dx-texteditor-input {
 padding: 7px 9px 8px !important;
}
.dx-field-value:not(.dx-widget) > .dx-switch {
  float: left;
} 
.Vue-Toastification__toast {
    min-width: auto !important;
}
.Vue-Toastification__icon {
    margin: auto 5px auto 0px;
}

.multiselect .multiselect__content-wrapper {
    min-width: 100%;
    width: auto;
    border: none;
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,.1);
}

.multiselect--active .multiselect__tags {
    border-bottom: none;
}

.light-popper {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
