import moment from "moment-timezone";
import { Booking, Inspector } from "@/models";
export class BookingBlock extends Booking {
  public startdate: string = "";
  public enddate: string = "";
  public bookings: Booking[] = [];

  // get starttime() {
  //   const fb = this.firstbooking;
  //   if (fb) {
  //     return moment(fb.startdate).utc().format("h:mm a");
  //   } else {
  //     return moment(this.startdate).utc().format("h:mm a");
  //   }
  // }
  // get startdatefordiary() {
  //   const fb = this.firstbooking;
  //   if (fb) {
  //     return moment(fb.startdate).utc().format(bookingdateformat);
  //   } else {
  //     return moment(this.startdate).utc().format(bookingdateformat);
  //   }
  // }
  // set startdatefordiary(val: string) {
  //   this.startdate = val;
  // }
  // get startDate() {
  //   const fb = this.firstbooking;
  //   if (fb) {
  //     return moment(fb.starttime).utc().format("YYYY-MM-DD[T]HH:mm");
  //   } else {
  //     return moment(this.startdate).utc().format("YYYY-MM-DD[T]HH:mm");
  //   }
  // }
  // set startDate(val: string) {
  //   this.startdate =
  //     moment(val).utc(true).format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
  // }
  // get startdateAsDate(): Date {
  //   const fb = this.firstbooking;
  //   if (fb) {
  //     return moment(fb.startdate).utc().toDate();
  //   } else {
  //     return moment(this.startdate).utc().toDate();
  //   }
  // }

  // get endtime() {
  //   const lb = this.lastbooking;
  //   if (lb) {
  //     return moment(lb.enddate).utc().format("h:mm a");
  //   } else {
  //     return moment(this.enddate).utc().format("h:mm a");
  //   }
  // }
  // get enddatefordiary() {
  //   const lb = this.lastbooking;
  //   if (lb) {
  //     return moment(lb.enddate).utc().format(bookingdateformat);
  //   } else {
  //     return moment(this.enddate).utc().format(bookingdateformat);
  //   }
  // }
  // set enddatefordiary(val: string) {
  //   this.enddate = val;
  // }
  // get endDate() {
  //   const lb = this.lastbooking;
  //   if (lb) {
  //     return moment(lb.enddate).utc().format("YYYY-MM-DD[T]HH:mm");
  //   } else {
  //     return moment(this.enddate).utc().format("YYYY-MM-DD[T]HH:mm");
  //   }
  // }
  // set endDate(val: string) {
  //   this.enddate =
  //     moment(val).utc(true).format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
  // }
  // get enddateAsDate(): Date {
  //   const lb = this.lastbooking;
  //   if (lb) {
  //     return moment(lb.enddate).utc().toDate();
  //   } else {
  //     return moment(this.enddate).utc().toDate();
  //   }
  // }

  get firstbooking() {
    if (this.bookings?.length) {
      return this.sortedbookings[0];
    }
    return null;
  }
  get lastbooking() {
    if (this.bookings?.length) {
      return this.sortedbookings[this.bookings.length - 1];
    }
    return null;
  }
  get sortedbookings() {
    return this.bookings?.sort((b1: Booking, b2: Booking) => {
      if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
        return 1;
      } else if (moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
