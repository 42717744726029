<template>
  <div class="customer">
    <div class="row">
      <div class="col-md-6">
        <h1>Pv Bookings</h1>
      </div>
      <div class="col-sm-6 text-right">
        <label>
          <span class="btn btn-sm btn-outline-secondary document-upload-button">
            <div><i class="fas fa-file-upload"></i> XLSX File</div>
          </span>
          <input
            type="file"
            accept=".xlsx"
            ref="file"
            :key="fileInputKey"
            v-on:change="onFileChange"
          />
        </label>
      </div>
    </div>
  </div>
  <PvBookingsList :key="listKey" />
  <ErrorsModel :name="`errors-modal`" ref="errorsmodel" />
</template>

<script lang="ts" setup>
import { Address, Bookedby, Booking, Customer, Tenant } from "@/models";
import { useStore } from "vuex";
import * as XLSX from "xlsx";
import _ from "lodash";
import PvBookingsList from "@/components/pvbookings/PvBookingsList.vue";
import { useToast } from "vue-toastification";
import ErrorsModel from "@/components/modals/ErrorsModel.vue";
import { computed, inject, ref } from "vue";
import moment from "moment";
const store = useStore();
const file = ref(null);
const keyData = ref(1);
const listKey = ref(0);
const fileInputKey = ref(0);
const actProperty: any = inject("actProperty");
const toasted = useToast();
const customerlist = computed(
  (): Customer[] => store.getters["pvbookings/customerlist"]
);
const addPvBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/addPvBookings", bookings);
};
// Define a ref to hold file data
const fileData = ref<Array<Record<string, any>> | null>(null);
const filename = ref("");
const errorsmodel = ref(null);

async function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    filename.value = file.name;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      // Get the first sheet and convert it to an array of objects
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      fileData.value = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Get the last column dynamically
      const firstRow = fileData.value[0] as any[]; // Explicitly cast as an array
      const lastColumnIndex = firstRow.length - 1; // Get the last column index

      const resultArray: Record<string, string>[] = [];

      fileData.value.forEach((row, rowIndex) => {
        const rowData: Record<string, string> = {
          Row: (rowIndex + 1).toString(),
        };

        for (let colIndex = 0; colIndex <= lastColumnIndex; colIndex++) {
          const columnLetter = String.fromCharCode(65 + colIndex); // Convert index to A-Z
          rowData[columnLetter] = row[colIndex] || ""; // Assign value or empty string
        }

        // Check if all columns (except the "Row" property) are blank
        const isRowBlank = Object.keys(rowData)
          .filter((key) => key !== "Row")
          .every((key) => rowData[key].toString().trim() === "");

        if (!isRowBlank) {
          resultArray.push(rowData);
        }
      });
      // Use header row as keys and convert each row into an object
      fileData.value = resultArray.slice(1);
      // Transform the entire fileData array into an array with replaced keys
      const transformedData = fileData.value.map(replaceRowKeys);
      fileData.value = transformedData.map((item) => ({
        ...item,
        "Due Date":
          typeof item["Due Date"] === "number"
            ? excelDateToJSDate(item["Due Date"])
            : item["Due Date"],
        Property: parseAddress(item["Property"], item["Postcode"]),
      }));
      let errors = validateAndSendData(fileData.value); // Validate and send data to the server
      if (errors && errors.length > 0) {
        const modal = errorsmodel.value as any;
        keyData.value++;
        modal.init(errors);
        modal.show();
      } else {
        if (customerlist.value.length === 0) {
          await getCustomersAction();
        }
        let apiJson = convertToBookingModel(fileData.value);
        try {
          await addPvBookings(apiJson);
          listKey.value++;
          toasted.success("Successfully Inserted Records");
          fileData.value = null;
          filename.value = "";
        } catch (err: any) {
          actProperty.displayError(err);
        }
      }
      fileInputKey.value++;
    };
    reader.readAsArrayBuffer(file);
  }
}

const jobtypelist = [
  "inventory",
  "checkin",
  "checkout",
  "soc",
  "property visit",
  "legra",
  "vm",
];

const headerMapping = {
  A: "Client",
  B: "Job Type",
  C: "Template",
  D: "ACT ref",
  E: "Status",
  F: "Branch",
  G: "ID",
  H: "FixFlo Id",
  I: "Property",
  J: "Postcode",
  K: "Due Date",
  L: "Tenant 1",
  M: "Tenant 1 Email",
  N: "Tenant 1 Mobile",
  O: "Tenant 2",
  P: "Tenant 2 Email",
  Q: "Tenant 2 Mobile",
  R: "Property Manager",
  S: "Property Manager Email",
  T: "Comments for ACT",
};

const replaceRowKeys = (row: Record<string, any>): Record<string, any> => {
  const newRow: Record<string, any> = {};

  // Iterate over each key in the row
  Object.keys(row).forEach((key) => {
    // If the key is "Row", keep it as-is or decide to drop it
    if (key === "Row") {
      newRow[key] = row[key];
    } else if (headerMapping[key]) {
      // Replace the key with its corresponding header value
      newRow[headerMapping[key]] = row[key];
    }
    // Optionally, you can decide what to do with keys not in the mapping
  });

  return newRow;
};

function isValidDueDate(dueDate) {
  if (!(dueDate instanceof Date) && typeof dueDate !== "string") {
    return false; // Handle invalid types
  }

  // Convert to string if it's a Date object
  const dueDateString =
    dueDate instanceof Date ? dueDate.toISOString() : dueDate;

  const requiredFormatRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
  const extractedDate = dueDateString.split("T")[0]; // Extract the date part
  return requiredFormatRegex.test(extractedDate);
}

function validateAndSendData(sheetData: any[]) {
  const errors: string[] = [];
  const validData: any[] = [];
  sheetData.forEach((row, index) => {
    if (!row["Due Date"]) {
      errors.push(`Row ${index + 2}: Due Date is required.`);
    }
    if (row["Due Date"] && !isValidDueDate(row["Due Date"])) {
      errors.push(`Row ${index + 2}: Due Date is not valid format.`);
    }
    if (!row["ID"]) {
      errors.push(`Row ${index + 2}: ID is invalid.`);
    }
    if (
      row["Property Manager Email"] &&
      !emailvalidation(row["Property Manager Email"]?.trim())
    ) {
      errors.push(`Row ${index + 2}: Property Manager Email is invalid.`);
    }
    if (
      row["Tenant 1 Email"] &&
      !emailvalidation(row["Tenant 1 Email"]?.trim())
    ) {
      errors.push(`Row ${index + 2}: Tenant 1 Email is invalid.`);
    }
    if (
      row["Tenant 2 Email"] &&
      !emailvalidation(row["Tenant 2 Email"]?.trim())
    ) {
      errors.push(`Row ${index + 2}: Tenant 2 Email is invalid.`);
    }
    if (!jobtypelist.includes(row["Job Type"].trim().toLowerCase())) {
      errors.push(`Row ${index + 2}: Job Type is invalid.`);
    }

    if (errors.length === 0) validData.push(row);
  });

  return errors;
}

const getCustomersAction = async () => {
  try {
    await store.dispatch("pvbookings/getCustomers");
  } catch (err: any) {
    actProperty.displayError(err);
  }
};

const emailvalidation = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// Function to convert tenant data into an array of Tenant instances
const convertToTenantArray = (data: any): Tenant[] => {
  const tenants: Tenant[] = [];
  let i = 1;

  while (data[`Tenant ${i}`]) {
    tenants.push(
      new Tenant({
        ttname: data[`Tenant ${i}`],
        ttemail: data[`Tenant ${i} Email`],
        ttmobile: data[`Tenant ${i} Mobile`],
      })
    );
    i++;
  }

  return tenants;
};

// Function to convert tenant data into an array of Tenant instances
const convertToBookByArray = (data: any): Bookedby[] => {
  const bookbys: Bookedby[] = [];
  bookbys.push(
    new Bookedby({
      bbname: data[`Property Manager`],
      bbemail: data[`Property Manager Email`],
      bbmobile: data[``],
    })
  );
  return bookbys;
};

const subjobendDate = (time: string, date: Date) => {
  let value = "";
  if (time) {
    let dt: Date = date;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};

// Assuming you have a function parseAddress as defined earlier
function convertToBookingModel(Datas: any[]): Booking[] {
  return Datas.map((data) => {
    // Set the start and end times to 9:00 AM because the start and end times are required  fields
    // Changing this here has a negative effect in the backend lambda PutPvBookingsFunction
    const startTime = "T09:00:00.000Z";
    let startdate = subjobendDate(startTime, data["Due Date"]);
    let enddate = subjobendDate(startTime, data["Due Date"]);
    let tenants = convertToTenantArray(data);
    let bookbys = convertToBookByArray(data);
    let customer = customerlist.value.find(
      (f: Customer) =>
        f.companyName == data["Template"].trim() && f.branchName == data["Branch"].trim()
    );
    // Create a new BookingModel instance using the data
    const booking = new Booking({
      jobtype: data["Job Type"].trim().toLowerCase(),
      address: data["Property"],
      duedate: data["Due Date"],
      startdate: startdate,
      enddate: enddate,
      tenancyid: data["ID"].toString(),
      tenants: tenants,
      pmname: data["Property Manager"],
      pmemail: data["Property Manager Email"],
      customer: customer,
      internaljobtype: data["Job Type"].trim().toLowerCase() !== 'property visit' ? '' : 'Property Visit',
      agencyaddress: customer?.address,
      bookedby: bookbys,
      status: "Not contacted",
      fixfloId: data["FixFlo Id"] ? data["FixFlo Id"].toString().trim() : "",
    });
    delete booking.qc;
    delete booking.booking;
    return booking;
  });
}
// Function to parse address string into Address
function parseAddress(address: string, postcode: string): Address {
  // Flexible regex to handle addresses with building names, street, town, and postcode
  if (!address) return;
  const parts = address.split(",").map((part) => part.trim());
  if (!parts.length) {
    throw new Error("Address format not recognized");
  }
  // Last token is always city <space> postcode
  let city_postcode = parts[parts.length - 1].split(" ");
  let city = city_postcode[0];

  let line1 = "";
  let line2 = "";
  if (parts.length == 2) {
    line1 = parts[0];
  } else if (parts.length > 2 && parts.length <= 3) {
    line1 = parts[0];
    line2 = parts[1];
  } else if (parts.length >= 4) {
    line1 = parts[0];
    line2 = parts[1] + ", " + parts[2];
  }

  // Return new Address instance using the parsed data
  return new Address({
    line1: line1.trim(), // Street address
    line2: line2?.trim(), // Optional second address line
    town: city?.trim(), // Town or city
    postcode: postcode, // Postcode (passed directly)
    country: "UK",
  });
}
// Convert serial number to JavaScript date
function excelDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate()
  );
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.drag * {
  pointer-events: none;
}

.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;
  input[type="file"] {
    display: none;
  }
}
</style>
