<template>
  <div
  :id="id"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`fixedtime-booking-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="fixedtime-booking-modal-label">OTP</h5>
          <button
            id="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body text-center">
          <div class="row" v-if="remainingTime < 60">
            <div class="col-12">
              <div class="d-block fs-4 mb-3 generatedotp">{{ generatedotp }}</div>
              <div class="countdown-circle">
                <svg width="100" height="100" viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    stroke="currentColor"
                    stroke-width="10"
                    fill="none"
                    :stroke-dasharray="circumference"
                    :stroke-dashoffset="strokeDashoffset"
                  />
                </svg>
                <p class="mt-1 fs-5">
                  <b>{{ remainingTime }} seconds</b>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, defineEmits, defineExpose, defineProps, computed } from 'vue';
import { TOTP } from "totp-generator";

const generatedotp = ref(""); 
const expire = ref(0); 
const emit = defineEmits(['hide']);
const props = defineProps<{
  id: string;
}>();

const circumference = 2 * Math.PI * 45; // 2 * PI * radius (45 is the radius of the circle)
const remainingTime = ref(60); // Track remaining time

const strokeDashoffset = computed(() => {
  return circumference - (circumference * remainingTime.value) / 60;
});

const init = async () => {
  if (countdownInterval) clearInterval(countdownInterval); // Clear previous timer if exists
  await generate();
  startCountdown();
};

const generate = async () => {
  const now = Date.now();
  const { otp, expires } = await TOTP.generate("LNOEWU3IFFRQQ42W", {
    digits: 6,
    algorithm: "SHA-512",
    period: 60,
    timestamp: now,
  });
  generatedotp.value = otp;
  expire.value = expires;
};

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
};

const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};

let countdownInterval: any = null; // Store interval reference

const startCountdown = () => {
  if (countdownInterval) clearInterval(countdownInterval); // Clear previous timer if exists

  countdownInterval = setInterval(async () => {
    let now = Date.now();
    let remaining = Math.max(0, Math.floor((expire.value - now) / 1000)); // Calculate remaining seconds
    remainingTime.value = remaining; // Update the reactive value
    if (remainingTime.value === 0) {
      await generate();
      now = Date.now();
      remaining = Math.max(0, Math.floor((expire.value - now) / 1000));
    }
  }, 1000);
};

defineExpose({ init, show, hide });
</script>

<style scoped>
.countdown-circle {
  position: relative;
  display: inline-block;
}

.countdown-circle svg {
  transform: rotate(-90deg);
}

.countdown-circle p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.generatedotp {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

.modal-body {
  min-height: 184px;
}
</style>
