import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

import "vue-multiselect/dist/vue-multiselect.css?vue&type=style&index=0&lang=css&external"
import "sweetalert2/dist/sweetalert2.min.css?vue&type=style&index=1&lang=css&external"
import "vue-toastification/dist/index.css?vue&type=style&index=2&lang=css&external"
import "@/assets/css/dx.generic.generic-blue-light-act.css?vue&type=style&index=3&lang=css&external"
import "./App.vue?vue&type=style&index=4&id=ef38998a&lang=scss"

const __exports__ = script;

export default __exports__