<template>
  <div class="card bg-light booking-section">
    <h3
      class="card-header booking-section-header"
      @click="expanded = !expanded"
    >
      <span class="float-left">Client &amp; Cost</span>
      <span class="float-right" v-if="portfoliouser && portfoliouser.name">Portfolio belongs to {{ portfoliouser.name }}</span>
    </h3>
    <!--div class="card-body" v-if="isQCuser && !expanded">
      <div class="row mb-3" v-if="!isNewBooking()">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-4">
                  {{ managedtype }}
                </div>
                <div class="col-4">
                  {{ customerCompanyName }}
                </div>
                <div class="col-4">
                  {{ customerBranchName }}
                </div>
              </div>
            </div>
            <div v-if="bookedbyarray.length > 0" class="col-6">
              <div
                class="row"
                v-for="(bookedby, $bbindex) in bookedbyarray"
                :key="$bbindex"
              >
                <div class="col-4">
                  Booked :
                  {{ bookedby.bbname }}
                </div>
                <div class="col-4">
                  {{ bookedby.bbemail }}
                </div>
                <div class="col-4">
                  {{ bookedby.bbmobile }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </!--div-->
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="col-form-label">Managed/Non-managed</label>
            <div>
              <singleselect-text
                v-model="managedtype"
                :options="managedtypeOptions"
                @select="setManagedtypeString"
              >
                <template #clear v-if="managedtype">
                  <i
                    @mousedown.prevent.stop="setManagedtype('')"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear"
                  ></i>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="col-form-label">Client Name</label>
            <div>
              <singleselect-text
                v-model="customerCompanyName"
                :options="filteredcompanynameOptions"
                :allow-empty="true"
                :taggable="false"
                @select="onSelectCompanyName"
                :ref="
                  (el) => {
                    dynamicRefs['clientNameDropDown'] = el;
                  }
                "
                @open="dropDownOpened('clientNameDropDown')"
                @search-change="clientnamesearchfilter = $event"
              >
                <template
                  #clear
                  v-if="
                    customerCompanyName != null && customerCompanyName.length
                  "
                >
                  <i
                    @mousedown.prevent.stop="clearCustomer()"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Company Name"
                  ></i>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="col-form-label">Branch Name</label>
            <div>
              <singleselect-text
                v-model="customerBranchName"
                :options="filteredbranchnameOptions"
                :allow-empty="true"
                :disabled="
                  customerCompanyName == null || customerCompanyName.length < 1
                "
                :taggable="false"
                @select="onSelectBranchName"
                :ref="
                  (el) => {
                    dynamicRefs['branchNameDropDown'] = el;
                  }
                "
                @open="dropDownOpened('branchNameDropDown')"
                @search-change="branchnamesearchfilter = $event"
              >
                <template
                  #clear
                  v-if="customerBranchName != null && customerBranchName.length"
                >
                  <i
                    @mousedown.prevent.stop="clearBranch()"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Branch Name"
                  ></i>
                </template>

                <template #singleLabel="props">
                  <div class="row">
                    <div class="ml-3">
                      {{ props.option }} {{ hqpostfix(props.option) }}
                    </div>
                  </div>
                </template>

                <template #option="props">
                  <div class="row">
                    <div class="col-md-7">
                      {{ props.option }} {{ hqpostfix(props.option) }}
                    </div>
                  </div>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3">
              <label class="col-form-label">Invoice adr. - Line 1</label>
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="agencyaddressLine1"
                autocomplete="agencyaddressLine1"
                :disabled="!booking.customer.editable"
              />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Line 2</label>
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="agencyaddressLine2"
                autocomplete="agencyaddressLine2"
                :disabled="!booking.customer.editable"
              />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Town</label>
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="agencyaddressTown"
                autocomplete="agencyaddressTown"
                :disabled="!booking.customer.editable"
              />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Postcode</label>
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="agencyaddressPostcode"
                autocomplete="agencyaddressPostcode"
                :disabled="!booking.customer.editable"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <!-- Add button to show misc notes from customer.policies.uniquenotes -->
          <button
            class="btn btn-sm btn-outline-secondary"
            @click="showCustomerPoliciesMiscNotesDialog"
          >
            Mics Policies
          </button>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="col-form-label">Linked Development</label>
            <div>
              <singleselect-text
                v-model="selectedDevelopment"
                :options="developmentOptions"
                :allow-empty="true"
                :taggable="false"
                @select="onSelectDevelopment"
              >
                <template #clear v-if="selectedDevelopment">
                  <i
                    @mousedown.prevent.stop="onSelectDevelopment('')"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Company Name"
                  ></i>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group" v-if="hqbranch">
            <label class="col-form-label">Invoice Branch </label>
            <div>
              <singleselect-text
                v-model="invoiceBranchName"
                :options="branchNameOptions"
                :allow-empty="true"
                :disabled="
                  customerCompanyName == null || customerCompanyName.length < 1
                "
                :taggable="false"
                @select="onSelectInvoiceBranchName"
              >
                <template #clear v-if="invoiceBranchName.length">
                  <i
                    @mousedown.prevent.stop="clearInvoiceCustomer()"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Invoice Branch Name"
                  ></i>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <div
            class="row"
            :class="{ 'mt-1': $llindex > 0 }"
            v-for="(landlord, $llindex) in landlordsarray"
            :key="$llindex"
          >
            <div class="col-md-4">
              <label v-if="$llindex === 0" class="col-form-label"
                >Landlord Name</label
              >
              <input
                id="llname"
                ref="llname"
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="landlord.llname"
                @change="addUnsavedChange('llname')"
              />
            </div>
            <div class="col-md-4">
              <label v-if="$llindex === 0" class="col-form-label"
                >Landlord Email</label
              >
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="landlord.llemail"
                @change="addUnsavedChange('llemail')"
                autocomplete="llemail"
              />
            </div>
            <div class="col-md-3">
              <label v-if="$llindex === 0" class="col-form-label"
                >Landlord Mobile</label
              >
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="landlord.llmobile"
                @change="addUnsavedChange('llmobile')"
                autocomplete="llmobile"
              />
            </div>
            <div class="col-md-1 pt-2">
              <button
                class="btn btn-sm btn-outline-secondary"
                @click.prevent="removeLandlord(landlord)"
                v-if="$llindex > 0"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-11 text-right">
              <button
                class="btn btn-sm btn-outline-secondary mt-2"
                @click.prevent="addLandlord"
              >
                <i class="fas fa-plus"></i> Add
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-1 mt-auto mb-auto">
          <button
            class="btn btn-sm btn-outline-secondary"
            @click.prevent="copyLLContactToBB()"
            :disabled="
              booking.bookedbyname?.length > 0 ||
              booking.bookedbyemail?.length > 0 ||
              booking.bookedbymobile?.length > 0 ||
              (!booking.landlordname &&
                !booking.landlordemail &&
                !booking.landlordmobile)
            "
          >
            Copy <i class="fas fa-arrow-right"></i>
          </button>
        </div>
        <div class="col-md-6">
          <div
            class="row"
            v-for="(bookedby, $bbindex) in bookedbyarray"
            :key="$bbindex"
            :class="{ 'mt-1': $bbindex > 0 }"
          >
            <div class="col-md-4">
              <label v-if="$bbindex === 0" class="col-form-label"
                >Booked By Name</label
              >
              <multiselect-text
                v-model="bookedby.bbname"
                :options="customerListName"
                :allow-empty="true"
                :taggable="true"
                :id="$bbindex"
                @tag="onTagBookName"
                @select="selectBook(bookedby.bbname, $bbindex)"
              ></multiselect-text>
            </div>
            <div class="col-md-4">
              <label v-if="$bbindex === 0" class="col-form-label"
                >Booked By Email</label
              >
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="bookedby.bbemail"
                @change="addUnsavedChange('bbemail')"
                autocomplete="email"
              />
            </div>
            <div class="col-md-3">
              <label v-if="$bbindex === 0" class="col-form-label"
                >Booked By Phone</label
              >
              <input
                type="text"
                min="0"
                class="form-control height-43"
                v-model.lazy="bookedby.bbmobile"
                @change="addUnsavedChange('bbmobile')"
                autocomplete="mobile"
              />
            </div>
            <div class="col-md-1 pt-2">
              <button
                class="btn btn-sm btn-outline-secondary"
                @click.prevent="removeBookedby(bookedby)"
                v-if="$bbindex > 0"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-11 text-right">
              <button
                class="btn btn-sm btn-outline-secondary mt-2"
                @click.prevent="addBookedby"
              >
                <i class="fas fa-plus"></i> Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: -12px; margin-right: -12px">
        <div class="col-md-2 topborder">
          <div class="row">
            <!-- <div class="col-md-2">
              <div class="form-group">
                <label class="col-form-label">Paid</label>
                <div>
                  <DxSwitch :value="paid" @value-changed="setPaid($event.value); setPaymentDateToToday($event.value)" />
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label class="col-form-label">Payment Date</label>
                <div>
                  <DxDateBox :value="paymentdate" display-format="dd/MM/yyyy" type="date" picker-type="calendar"
                    apply-value-mode="instantly" :calendarOptions="{ firstDayOfWeek: 1 }"
                    @value-changed="setPaymentDate" />
                </div>
              </div>
            </div> -->
            <div class="col-md-12">
              <div class="form-group">
                <label class="col-form-label">W/O number</label>
                <input
                  type="text"
                  min="0"
                  class="form-control height-43"
                  v-model.lazy="worksorder"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1 topborder pr-0">
          <span>
            <label class="col-form-label">W/O document</label>
            <Documents
              :buttonclass="{ 'blink-red': booking.customer.workordernumberrequired}"
              style="margin-top: 0px !important"
              :buttontext="'Upload'"
              :entity="'booking'"
              :key="booking.worksorderdocuments.length"
              :documents="booking.worksorderdocuments"
              :path="'worksorderdocuments'"
              @clicked="showWorksorderDocument"
            />
          </span>
          <span>
            <button
              v-if="!worksordernotesPresent()"
              class="btn btn-sm btn-outline-secondary mt-2"
              :class="{ 'yellow-outline': worksordernotesPresent() }"
              @click.prevent="showWorksordernotesModal"
            >
              Text content
            </button>
            <i
              v-else
              class="far fa-4x fa-file-alt mt-2"
              @click.prevent="showWorksordernotesModal"
            ></i>
          </span>
        </div>
        <div class="col-md-7 topborder">
          <label
            class="col-form-label"
            v-if="invoices.length === 0"
            style="color: transparent"
            >.</label
          >
          <div
            v-for="(invoice, $invoiceindex) in invoices"
            :key="$invoiceindex"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <label
                    class="col-md-1 col-form-label"
                    v-if="$invoiceindex === 0"
                    >Paid</label
                  >
                  <label
                    class="col-md-1 col-form-label"
                    v-if="paymentAdvanceFlag && $invoiceindex === 0"
                    >Credit approved</label
                  >
                  <label
                    class="col-md-2 col-form-label"
                    v-if="$invoiceindex === 0"
                    >Payment Date</label
                  >
                  <label
                    :class="paymentAdvanceFlag ? 'col-md-4' : 'col-md-5'"
                    class="col-form-label"
                    v-if="$invoiceindex === 0"
                  ></label>
                  <label
                    class="col-md-4 col-form-label"
                    v-if="$invoiceindex === 0"
                    >(Not inclusive of VAT)</label
                  >
                </div>
                <div
                  v-for="(fee, $feeindex) in invoice.fees"
                  :key="$feeindex"
                  class="row mb-3"
                >
                  <div class="col-md-1">
                    <DxSwitch
                      class="height-43"
                      :value="fee.paid"
                      @value-changed="setFeesPaid($invoiceindex, $event.value)"
                    />
                  </div>
                  <div v-if="paymentAdvanceFlag" class="col-md-1">
                    <DxSwitch
                      class="height-43"
                      :value="creditapproved"
                      v-if="$invoiceindex === 0"
                      @value-changed="creditapproved = $event.value"
                    />
                  </div>
                  <div class="col-md-2">
                    <DxDateBox
                      class="form-control height-43"
                      :value="fee.paymentdate"
                      display-format="dd/MM/yyyy"
                      type="date"
                      picker-type="calendar"
                      apply-value-mode="instantly"
                      :calendarOptions="{ firstDayOfWeek: 1 }"
                      @value-changed="
                        setFeesPaymentDate($invoiceindex, $event.value)
                      "
                    />
                  </div>
                  <div :class="paymentAdvanceFlag ? 'col-md-4' : 'col-md-5'">
                    <input
                      type="text"
                      class="form-control height-43"
                      :value="fee.name"
                      @change="setFeesDetail($invoiceindex, $event)"
                      :placeholder="$invoiceindex === 0 ? jobtype : ''"
                      :disabled="$invoiceindex === 0"
                    />
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">&pound;</span>
                      </div>
                      <input
                        type="text"
                        class="form-control height-43"
                        size="6"
                        :value="fee.amount"
                        @change="setFeesValue($invoiceindex, $event)"
                      />
                    </div>
                  </div>
                  <div class="col-md-1">
                    <ProgressButton
                      v-if="invoice.id"
                      @click="
                        actProperty.copyDownloadButton(invoice, 'invoice')
                      "
                      @dblclick="downloadInvoice(invoice, $event)"
                      class="btn btn-outline-primary"
                      title="Invoice"
                    >
                      <i class="far fa-file-pdf"></i>
                    </ProgressButton>
                  </div>
                  <div class="col-1">
                    <button
                      v-if="$invoiceindex !== 0"
                      class="btn btn-outline-danger"
                      @click="removeInvoice($invoiceindex)"
                      tabindex="-1"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2 pr-0">
              <button
                class="btn btn-sm btn-outline-secondary"
                ref="defaultFee"
                @click="addNewInvoice()"
              >
                <i class="fas fa-plus"></i> Fees
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-2 topborder">
          <span v-if="booking.customer.pricelist.length">
            <label class="col-form-label">Price list</label>
            <Documents
              :readonly="true"
              :buttontext="'Upload'"
              :entity="'booking'"
              :key="booking.customer.pricelist.length"
              :documents="booking.customer.pricelist"
              :path="'customer.pricelist'"
              @clicked="showPricelistDocument"
            />
          </span>
        </div>
      </div>
    </div>

    <WorksordernotesDialog
      :name="`worksordernotes-modal`"
      ref="worksordernotesModal"
      :booking="booking"
    />
    <MiscNotesDialog
      ref="custPoliciesMiscNotesDialog"
      modalid="logiscticsrules"
      :title="`Logistics Rules: Notes`"
      :firsttitle="`Misc Notes`"
      :notes="booking.customer.policies.uniquenotes"
      :secondtitle="`PI Notes`"
      :secondnotes="booking.customer.policies.longtermpinotes"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  inject,
  ref,
  watch,
  onMounted,
  nextTick,
  defineEmits,
  onBeforeUnmount, 
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import moment from "moment-timezone";
import {
  Customer,
  Landlord,
  Bookedby,
  Address,
  Report,
  Fee,
  Dictionary,
  Booking,
  User,
} from "@/models";
import Ably from "ably";
import { DxSwitch } from "devextreme-vue/switch";
import { DxDateBox } from "devextreme-vue/date-box";
import { useRoute } from "vue-router";
import Documents from "@/components/document/Documents.vue";
import ProgressButton from "@/components/ProgressButton.vue";
import WorksordernotesDialog from "@/components/modals/WorksorderNotesModal.vue";
import MiscNotesDialog from "@/components/modals/MiscNotesDialog.vue";
const store = useStore();
const toasted = useToast();
const route = useRoute();
const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const emit = defineEmits([
  "showpricelistdocument",
  "showworksorderdocument",
  "showpricelistdocument",
]);

// Refs for reactive variables
const selectedCompanyName = ref("");
const selectedBranchName = ref("");
const selectedInvoiceBranchName = ref("");
const clientnamesearchfilter = ref("");
const branchnamesearchfilter = ref("");
const companyNameOptions = ref([]);
const branchNameOptions = ref([]);
const managedtypeOptions = ref(["Managed", "Non-managed"]);
const custPoliciesMiscNotesDialog = ref(null);

const llname = ref(null);
const defaultFee = ref(null);
const worksordernotesModal = ref(null);

const dynamicRefs = ref({});
const expanded = ref(false);
const development = computed(
  () => store.getters["diary/development"] as Customer
);
const customer = computed(() => store.getters["diary/customer"] as Customer);
const _managedtype = computed(
  () => store.getters["diary/managedtype"] as string
);
const landlordsarray = computed(
  () => store.getters["diary/landlordsarray"] as Landlord[]
);
const bookedbyarray = computed(
  () => store.getters["diary/bookedbyarray"] as Bookedby[]
);
const _worksorder = computed(() => store.getters["diary/worksorder"] as string);
const _paid = computed(() => store.getters["diary/paid"] as boolean);
const paymentdate = computed(
  () => store.getters["diary/paymentdate"] as string
);
const invoices = computed(() => store.getters["diary/invoices"] as Report[]);
const dictionary = computed(
  () => store.getters["dictionary/current"] as Dictionary
);
const jobtypeslug = computed(() => store.getters["diary/jobtype"] as string);
const booking = computed(() => store.getters["diary/booking"] as Booking);
const _keypickupbranchname = computed(
  () => store.getters["diary/keypickupbranchname"] as string
);
const agencyaddress = computed(
  () => store.getters["diary/agencyaddress"] as Address
);
const customers = computed(
  () => store.getters["diary/customerlist"] as Customer[]
);
const setDevelopment = (development: Customer) => {
  store.commit("diary/setDevelopment", development);
};

const setCustomer = (customer: Customer) => {
  store.commit("diary/setCustomer", customer);
};

const setInvoiceCustomer = (customer: Customer) => {
  store.commit("diary/setInvoiceCustomer", customer);
};

const setAgencyaddress = (address: Address) => {
  store.commit("diary/setAgencyaddress", address);
};

const setKeypickupbranchname = (keypickup: string) => {
  store.commit("diary/setKeypickupbranchname", keypickup);
};

const setReleasekeystobranchname = (releasekeysto: string) => {
  store.commit("diary/setReleasekeystobranchname", releasekeysto);
};

const setManagedtype = (managedtype: string) => {
  setBookingDeep({ path: "companyName", data: '' });
  setCustomer(new Customer());
  setBookingDeep({
    path: `agencyaddress`,
    data:new Address
  });
  selectedCompanyName.value = "";
  selectedBranchName.value = "";
  store.commit("diary/setManagedtype", managedtype);
};

const addStateLandlord = (landlord: Landlord) => {
  store.commit("diary/addLandlord", landlord);
};
const removeLandlord = (landlord: Landlord) => {
  store.commit("diary/removeLandlord", landlord);
};

const addStateBookedby = (bookedby: Bookedby) => {
  store.commit("diary/addBookedby", bookedby);
};

const removeBookedby = (bookedby: Bookedby) => {
  store.commit("diary/removeBookedby", bookedby);
};

const setWorksorder = (worksorder: string) => {
  store.commit("diary/setWorksorder", worksorder);
};

const setPaymentinadv = (paymentinadv: boolean) => {
  store.commit("diary/setPaymentinadv", paymentinadv);
};

const setPaid = (paid: boolean) => {
  store.commit("diary/setPaid", paid);
};

const setPaymentdate = (paymentdate: string) => {
  store.commit("diary/setPaymentdate", paymentdate);
};

const addUnsavedChange = (element: string) => {
  store.commit("diary/addUnsavedChange", element);
};

const setBookingDeep = (payload: { path: string; data: any }) => {
  return store.commit("diary/setBookingDeep", payload);
};

const addInvoice = (invoice: Report) => {
  return store.commit("diary/addInvoice", invoice);
};

const removeInvoiceFromState = (index: number) => {
  return store.commit("diary/removeInvoice", index);
};

const removeRequest = (id: string) => {
  store.commit("app/removeRequest", id);
};

const setSelectedcustomername = (name: string) => {
  store.commit("diary/setSelectedcustomername", name);
};

const downloadNormalInvoice = async (report: Report): Promise<any> => {
  return store.dispatch("reports/downloadInvoice", report);
};

const downloadProformaInvoice = async (report: Report): Promise<any> => {
  return store.dispatch("reports/downloadProformaInvoice", report);
};

const getCompanyNameOptions = (managedtype?: string): Promise<any> => {
  return store.dispatch("diary/getCompanyNameOptions", managedtype);
};

const setAgencyaddressDeep = (payload: { path: string; data: string }) => {
  store.commit("diary/setAgencyaddressDeep", payload);
};

const getCustomers = async (payload?: {
  starts_with?: string;
  company_name?: string;
  branch_name?: string;
  managedtype?: string;
}): Promise<Customer[]> => {
  return await store.dispatch("diary/getCustomers", payload);
};

const getBranchNameOptionsWithManagedtype = async (payload?: {
  company_name?: string;
  managedtype?: string;
}): Promise<any> => {
  return await store.dispatch(
    "diary/getBranchNameOptionsWithManagedtype",
    payload
  );
};

const getUserForCustomer = (customer: Customer): Promise<User> => {
  return store.dispatch("usermanagement/getUserForCustomer", customer);
};

onMounted(() => {
  channel.subscribe("commoncovertapplied", async (message: any) => {
    if (message?.data) {
      // Make sure the event is coming from the same pc
      if (message.data.deviceid != deviceid) return;
      if (message.data.bookingid != booking.value.id) return;
      await loadcompanyandbranchoptions();
      setManagedtype(booking.value.managedtype);
      if (booking.value?.customer?.companyName) {
        selectedCompanyName.value = booking.value.customer.companyName;
        setSelectedcustomername(booking.value.customer.companyName);
      } else if (booking.value?.companyName) {
        selectedCompanyName.value = booking.value.companyName;
        setSelectedcustomername(booking.value.companyName);
      }
      if (booking.value.customer.companyName != "Private Landlord")
        setAgencyaddress(new Address(booking.value.customer.address));
      setPaymentinadv(booking.value.customer.accountsettings.pobinvoice);
    }
  });

  setTimeout(() => {
    getCompanyNameOptions(booking.value.managedtype).then(
      (options: any) => (companyNameOptions.value = options)
    );
    if (invoices.value.length === 0) {
      nextTick(() => {
        let element: HTMLElement = defaultFee.value as HTMLElement;
        if (element) element.click();
      });
    }
  }, 1000);
});

onBeforeUnmount(() => {
  channel.unsubscribe('commoncovertapplied');
});

const clearform = () => {
  selectedCompanyName.value = "";
  selectedBranchName.value = "";
  invoiceBranchName.value = "";
  clientnamesearchfilter.value = "";
  branchnamesearchfilter.value = "";
  llname.value = null;
  defaultFee.value = null;
};

watch(
  () => booking.value.id,
  async () => {
    clearform();
    loadcompanyandbranchoptions();
    selectedCompanyName.value = booking.value?.customer?.companyName
      ? booking.value?.customer?.companyName
      : booking.value?.companyName;
    selectedBranchName.value = booking.value.customer.branchName;
    selectedInvoiceBranchName.value = booking.value?.invoicecustomer?.branchName;
  }
);
const loadcompanyandbranchoptions = async () => {
  await getCompanyNameOptions(managedtype.value).then(
    (options: any) => (companyNameOptions.value = options)
  );
  await getBranchNameOptionsWithManagedtype({
    company_name: booking.value?.customer?.companyName,
    managedtype: managedtype.value,
  }).then((options: any) => (branchNameOptions.value = options));
};

const customerCompanyName = computed({
  get: () => selectedCompanyName.value,
  set: (name: string) => {
    selectedCompanyName.value = name;
  },
});

const filteredcompanynameOptions = computed(() => {
  return companyNameOptions.value.filter((c: string) => {
    if (clientnamesearchfilter.value)
      return c
        .toLowerCase()
        .startsWith(clientnamesearchfilter.value.toLowerCase());
    else return true;
  });
});

const customerBranchName = computed({
  get: () => selectedBranchName.value,
  set: (name: string) => {
    selectedBranchName.value = name;
  },
});

const invoiceBranchName = computed({
  get: () => selectedInvoiceBranchName.value,
  set: (name: string) => {
    selectedInvoiceBranchName.value = name;
  },
});


const filteredbranchnameOptions = computed(() => {
  return branchNameOptions.value.filter((b: string) => {
    if (branchnamesearchfilter.value)
      return b
        .toLowerCase()
        .startsWith(branchnamesearchfilter.value.toLowerCase());
    else return true;
  });
});

const selectedDevelopment = computed({
  get: () => {
    let selected = "";
    if (customer.value && customer.value.establishments) {
      let dev = customer.value.establishments.find(
        (e) => e.id === development.value.id
      );
      if (dev) selected = dev.companyName;
    }
    return selected;
  },
  set: (val: string) => {
    if (customer.value && customer.value.establishments) {
      let dev = customer.value.establishments.find(
        (e) => e.companyName === val
      );
      if (dev) {
        setDevelopment(dev); // Assuming setDevelopment is a method defined elsewhere
      } else {
        setDevelopment(new Customer());
      }
    }
  },
});

const developmentOptions = computed(() => {
  let options: string[] = [];
  if (customer.value && customer.value.establishments) {
    options = customer.value.establishments.map((e) => e.companyName);
  }
  return options;
});

const customerListName = computed(() => {
  let options: string[] = [];
  if (
    customer.value &&
    booking.value.customer.companyName != "Private Landlord" &&
    customer.value.policies.contactlist.length
  ) {
    options = customer.value.policies.contactlist.map((m: any) => m.name);
  }
  return options;
});

const onSelectDevelopment = (val: string) => {
  selectedDevelopment.value = val;
};

const onSelectCompanyName = async (companyName: string) => {
  if (companyName) {
    setSelectedcustomername(companyName);
    setBookingDeep({ path: "companyName", data: companyName });
    selectedCompanyName.value = companyName;
    selectedBranchName.value = "";
    selectedInvoiceBranchName.value = "";
    setCustomer(new Customer());
    branchNameOptions.value = customers.value
      .filter((c1: Customer) => {
        if (managedtypeboolean.value) {
          return c1.companyName === companyName && c1.acClientCode != "NM";
        } else {
          return (
            c1.companyName === companyName &&
            (c1.acClientCode == "NM" || c1.acClientCode === "MISC")
          );
        }
      })
      .map((c2: Customer) => c2.branchName);
  }
};

const onSelectBranchName = async (branchName: string) => {
  const customer = customers.value.find((c: Customer) => {
    return (
      c.companyName === selectedCompanyName.value && c.branchName === branchName
    );
  });
  if (customer) {
    selectedBranchName.value = branchName;
    setCustomer(customer);
    if (selectedCompanyName.value != "Private Landlord")
      setAgencyaddress(new Address(customer.address));
    setPaymentinadv(customer.accountsettings.pobinvoice);
    if(isNewBooking()){
      setBookingDeep({path:'pinotes',data:customer.policies.longtermpinotes});
    }
  }
  if (
    booking.value.jobtype === "inventory" ||
    booking.value.jobtype === "checkin"
  ) {
    setBookingDeep({
      path: "donotcontacttt",
      data: customer.policies.donotcontactttforinvci,
    });
  } else if (
    booking.value.jobtype === "checkout" ||
    booking.value.jobtype === "soc"
  ) {
    setBookingDeep({
      path: "donotcontacttt",
      data: customer.policies.donotcontactttforcosoc,
    });
  }

};

const paymentAdvanceFlag = computed(() => {
  let paymentAdvanceFlag = false;
  if (booking.value.jobtype === "checkout" || booking.value.jobtype === "soc") {
    paymentAdvanceFlag = customer.value.accountsettings.paymentinadvcosoc;
  } else if (
    booking.value.jobtype === "inventory" ||
    booking.value.jobtype === "checkin"
  ) {
    paymentAdvanceFlag = customer.value.accountsettings.paymentinadvinvci;
  }
  return paymentAdvanceFlag;
});

const clearCustomer = async () => {
  booking.value.companyName = "";
  selectedCompanyName.value = "";
  selectedBranchName.value = "";
  selectedInvoiceBranchName.value = "";
  setCustomer(new Customer());
  setAgencyaddress(new Address());
  setKeypickupbranchname("");
  setReleasekeystobranchname("");
};

const clearBranch = async () => {
  selectedBranchName.value = "";
  selectedInvoiceBranchName.value = "";
  setCustomer(new Customer());
  setAgencyaddress(new Address());
  setKeypickupbranchname("");
  setReleasekeystobranchname("");
};

const hqbranch = () => {
  return customer.value.hqbranch;
};

const onSelectInvoiceBranchName = async (branchName: string) => {
  const customers: Customer[] = await getCustomers({
    company_name: customerCompanyName.value,
    branch_name: branchName || "empty",
    managedtype: managedtype.value || "Non-managed",
  });

  if (customers.length) {
    invoiceBranchName.value = branchName;
  }

  if (customers.length) {
    let customer = customers[0];
    setInvoiceCustomer(customer);

    if (!_keypickupbranchname.value) {
      setAgencyaddress(new Address(customer.address));
    }
    setPaymentinadv(customer.accountsettings.pobinvoice);
  }
};

const clearInvoiceCustomer = () => {
  invoiceBranchName.value = "";
  selectedInvoiceBranchName.value = "";
  setInvoiceCustomer(new Customer());
  onSelectBranchName(selectedBranchName.value);
};

// Computed properties
const managedtype = computed({
  get: () => _managedtype.value,
  set: (val: string) => setManagedtype(val),
});

const managedtypeboolean = computed(() => {
  return _managedtype.value === "Managed";
});

const setManagedtypeString = async (mt: string) => {
  setManagedtype(mt);
  setBookingDeep({ path: "companyName", data: '' });
  setCustomer(new Customer());
  setBookingDeep({
    path: `agencyaddress`,
    data:new Address
  });
  selectedCompanyName.value = "";
  selectedBranchName.value = "";
  await getCompanyNameOptions(mt).then(
    (options: any) => (companyNameOptions.value = options)
  );
  await getBranchNameOptionsWithManagedtype({
    company_name: selectedCompanyName.value,
    managedtype: mt,
  }).then((options: any) => (branchNameOptions.value = options));
};

const agencyaddressLine1 = computed({
  get: () => agencyaddress.value.line1,
  set: (data: string) => setAgencyaddressDeep({ path: "line1", data }),
});

const agencyaddressLine2 = computed({
  get: () => agencyaddress.value.line2,
  set: (data: string) => setAgencyaddressDeep({ path: "line2", data }),
});

const agencyaddressTown = computed({
  get: () => agencyaddress.value.town,
  set: (data: string) => setAgencyaddressDeep({ path: "town", data }),
});

const agencyaddressPostcode = computed({
  get: () => agencyaddress.value.postcode,
  set: (data: string) => setAgencyaddressDeep({ path: "postcode", data }),
});

const worksorder = computed({
  get: () => _worksorder.value,
  set: (val: string) => setWorksorder(val),
});

const paid = computed({
  get: () => _paid.value,
  set: (val: boolean) => setPaid(val),
});

const setPaymentDate = (e: any) => {
  setPaymentdate(moment(e.value).format("YYYY-MM-DD[T]HH:mm"));
};

const jobtype = computed(() => {
  let displayname = "";
  if (dictionary.value && dictionary.value.reporttypes) {
    const type = dictionary.value.reporttypes.find(
      (t) => t.slug === jobtypeslug.value
    );
    if (type) displayname = type.displayname;
  }
  return displayname;
});

const setPaymentDateToToday = (paid: boolean) => {
  if (paid) setPaymentdate(moment(new Date()).format("YYYY-MM-DD[T]HH:mm"));
};

const setFeesDetail = (index: number, $event: any) => {
  setBookingDeep({
    path: `invoices[${index}].fees[0].name`,
    data: $event.target.value,
  });
};

const setFeesValue = (index: number, $event: any) => {
  setBookingDeep({
    path: `invoices[${index}].fees[0].amount`,
    data: $event.target.value,
  });
};

const setFeesPaid = (index: number, $event: any) => {
  setBookingDeep({
    path: `invoices[${index}].fees[0].paid`,
    data: $event,
  });
};
const setFeesPaymentDate = (index: number, $event: any) => {
  setBookingDeep({
    path: `invoices[${index}].fees[0].paymentdate`,
    data: moment($event).format("YYYY-MM-DD[T]HH:mm"),
  });
};
const addNewInvoice = () => {
  addInvoice(new Report({ fees: [new Fee()] }));
};

const removeInvoice = (index: number) => {
  actProperty.confirmPrompt().then(() => {
    removeInvoiceFromState(index);
  });
};

const downloadInvoice = (report: Report, requestId: string) => {
  if (report && report.fees) {
    let fee = report.fees[0];
    if (!fee.name) {
      downloadProformaInvoice(report)
        .then((key: string) => {
          actProperty.downloadAttachment(key);
          toasted.success(`Proforma invoice ${report.ref} PDF downloaded`);
        })
        .finally(() => removeRequest(requestId))
        .catch((err: any) => actProperty.displayError(err));
    } else {
      downloadNormalInvoice(report)
        .then((key: string) => {
          actProperty.downloadAttachment(key);
          toasted.success(`Invoice ${report.ref} PDF downloaded`);
        })
        .finally(() => removeRequest(requestId))
        .catch((err: any) => actProperty.displayError(err));
    }
  }
};
const hqpostfix = (branchName: string): string => {
  let val = "";
  const customer = customers.value.find(
    (c: Customer) =>
      c.companyName === selectedCompanyName.value && c.branchName === branchName
  );
  if (customer && customer.hqbranch) {
    val = "(HQ)";
  }
  return val;
};

const addLandlord = () => {
  addStateLandlord(new Landlord());
};

const addBookedby = () => {
  addStateBookedby(new Bookedby());
};

const showWorksorderDocument = (index: number) => {
  emit("showworksorderdocument", index);
};

const showWorksordernotesModal = () => {
  const modal = worksordernotesModal.value;
  if (modal) {
    modal.init();
    modal.show();
  }
};

const worksordernotesPresent = () => {
  let plaintext = "";
  if (booking.value.worksordernotes) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = booking.value.worksordernotes;
    plaintext = tempDivElement.textContent || tempDivElement.innerText || "";
  }
  return plaintext.trim().length > 0;
};

const showPricelistDocument = (index: number) => {
  emit("showpricelistdocument", index);
};

const dropDownOpened = (refName: string) => {
  nextTick(() => {
    const multiselect = dynamicRefs.value[refName];
    const dropdownList = multiselect?.$el.querySelector(
      ".multiselect__content-wrapper"
    );

    const selectedOption = multiselect?.$el.querySelector(
      ".multiselect__option--selected"
    );

    if (selectedOption?.offsetTop) {
      dropdownList.scrollTop = selectedOption.offsetTop;
    }
  });
};

const copyLLContactToBB = () => {
  if (
    !booking.value.bookedbyname &&
    !booking.value.bookedbyemail &&
    !booking.value.bookedbymobile
  ) {
    for (let i = 0; i < booking.value.bookedby.length; i++) {
      if (booking.value.landlords.length > i) {
        let landlord: Landlord = booking.value.landlords[i];
        if (landlord.llname) {
          setBookingDeep({
            path: `bookedby[${i}].bbname`,
            data: landlord.llname,
          });
        }
        if (landlord.llemail) {
          setBookingDeep({
            path: `bookedby[${i}].bbemail`,
            data: landlord.llemail,
          });
        }
        if (landlord.llmobile) {
          setBookingDeep({
            path: `bookedby[${i}].bbmobile`,
            data: landlord.llmobile,
          });
        }
      }
    }
  }
};

const isNewBooking = () => {
  return route.params.id == "new";
};

const creditapproved = computed({
  get: () => booking.value.creditapproved,
  set: (data) => setBookingDeep({ path: "creditapproved", data }),
});

const showCustomerPoliciesMiscNotesDialog = () => {
  const modal = custPoliciesMiscNotesDialog.value as any;
  modal.show();
};

const onTagBookName = (val: string, index: number) => {
  if (index >= 0) {
    setBookingDeep({ path: `bookedby[${index}].bbname`, data: val });
  }
};

const selectBook = (suggestion: string, index: number) => {
  let data = customer.value.policies.contactlist.find(
    (m: any) => suggestion === m.name
  );
  if (data) {
    setBookingDeep({
      path: `bookedby[${index}].bbname`,
      data: data.name,
    });
    setBookingDeep({
      path: `bookedby[${index}].bbmobile`,
      data: data.mobile,
    });
    setBookingDeep({
      path: `bookedby[${index}].bbemail`,
      data: data.email,
    });
  }
};
const clearData = (index: number) => {
  let bookedby = bookedbyarray.value[index].bbname;
  let data = customer.value.policies.contactlist.find(
    (m: any) => bookedby === m.name
  );
  if (index >= 0) {
    setBookingDeep({
      path: `bookedby[${index}].bbname`,
      data: "",
    });
    if (data) {
      setBookingDeep({
        path: `bookedby[${index}].bbmobile`,
        data: "",
      });
      setBookingDeep({
        path: `bookedby[${index}].bbemail`,
        data: "",
      });
    }
  }
};

const portfoliouser = ref();
watch(
  () => booking.value.customer.id,
  async () => {
    portfoliouser.value = await getUserForCustomer(booking.value.customer);
});

</script>

<style scoped lang="scss">
.context-message {
  color: #a4a4a4;
}

h5 {
  color: #686868;
}

.booking-info {
  color: #253746;
}

.topborder {
  border-top: 2px solid #dee2e6;
}

.outline {
  border: 2px solid red !important;
}

.yellow-outline {
  border: 4px solid yellow !important;
}
</style>
