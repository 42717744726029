<template>
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="`emailmodal-label`"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="email-modal-label">
            {{ title }}
          </h5>
          <div>
         
         <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close"
           @click.prevent="hide()">
           <span aria-hidden="true">&times;</span>
         </button>
         <button type="button" class="close" @click="minimizeModal">
           <i class='fa' :class="minimized ? 'fa-clone' : 'fa-minus'"></i>
         </button>
         </div>
        </div>

        <div class="modal-body" style="  height: 80vh;      /* Set the height to 80% of the viewport height */
            overflow-y: auto;  /* Enable vertical scrollingif content overflows vertically */
            overflow-x: hidden;">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">To</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="to" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Cc</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="cc" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Bcc</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model.lazy="bcc" />
              </div>
            </div>

            <div class="form-group row" v-if="target === 'Managed Client' || target === 'Non-Managed Client' || target === 'Landlord'
              ">
              <label class="col-sm-2">Attachments</label>
              <div class="col-sm-10">
                <div class="row">
                  <div v-for="attachment in attachments" :key="`${attachment.path}-${attachment.generatingid}-${attachment.generatingstatus}`"
                    style="display: flex; align-items: center;" class="col-md-4">
                    <i :class="{
                      'text-success': selectedattachment(attachment),
                      'fas fa-2x fa-circle-notch fa-spin': attachment.generatingstatus == 'generating',
                      'far fa-2x fa-check-circle': attachment.generatingstatus == 'finished'
                    }" @click="toggleselection(attachment)"></i>
                    <span class="pl-1">{{ attachment.filename }}</span>
                  </div>
                </div>
                <Documents :buttontext="'Attach'" :entity="'booking'"
                  :key="`booking-${booking.emailattachmentdocuments.length}`" :documents="emailattachmentdocuments"
                  :path="'emailattachmentdocuments'" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Subject</label>
              <div class="col-sm-10 subject-editor">
                <input type="text" class="form-control" v-model.lazy="subject" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Message</label>
              <div class="col-sm-10 body-editor" :height="messageheight">
                <ckeditor :editor="bodyeditor" v-model="bodyeditordata" :config="bodyEditorConfig"></ckeditor>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer d-flex align-items-center justify-content-between">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide()">
            Cancel
          </button>
          
          <h5 v-if="booking.provisional" class="text-danger mb-0">This is a provisional booking.</h5>
          
          <button type="submit" class="btn btn-primary" data-dismiss="modal" @click.prevent="send()" :disabled="!canSend()">
            Send
          </button>
        </div>


      </div>
    </div>
  </div>
  <!-- Minimized Modal Placeholder -->
  <div v-if="minimized" class="minmaxCon" @click="restoreModal">
    <i :id="`Maximize ${id}`" class="fa fa-2x fas fa-window-maximize" style="color: yellow" :title="`Maximize ${label}`"></i>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import Editor from "ckeditor5/ckeditor";

import { defineProps, ref, computed, onMounted, inject, defineExpose, watch } from 'vue';
import _get from "lodash/get";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Report, Bookedby, Dictionary, Email, EmailAttachment, EmailLogs, EmailTemplate, Landlord, Tenant, Fee, Booking, User, Customer } from '@/models';
import Documents from "@/components/document/Documents.vue";

// Define props with their types
const props = defineProps<{
  id: string;
  name: string;
  title: string;
  reporttype: string;
  target: string;
  chronorder: string;
  customer?: string;
  fromaddress: string;
  toaddresses: string[];
  label?: string;
}>();



const actProperty: any = inject('actProperty');
const toasted = useToast();

// State and getters
const store = useStore();
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const useremail = computed(() => store.getters['auth/email']);
const clientemail = computed(() => store.getters['emailtemplates/clientemail']);
const feedbackemail = computed(() => store.getters['emailtemplates/feedbackemail']);
const landlordemail = computed(() => store.getters['emailtemplates/landlordemail']);
const tenantemail = computed(() => store.getters['emailtemplates/tenantemail']);
const dataentryemail = computed(() => store.getters['emailtemplates/dataentryemail']);
const deletebookingemail = computed(() => store.getters['emailtemplates/deletebookingemail']);
const booking = computed(() => store.getters['diary/booking']);
const invoices = computed(() => store.getters['diary/invoices']);
const systemproperties = computed(() => store.getters['dictionary/systemproperties']);
const height = ref(window.innerHeight);
const bodydata = ref("");
const attachments = ref<EmailAttachment[]>([]);
const autoattachments = ref<EmailAttachment[]>([]);
const minimized = ref(false);
const findEmailTemplates = async (query?: {
  reporttype?: string;
  target?: string;
  chronorder?: string[];
  managedtype?: string;
  customer?: string;
}): Promise<EmailTemplate> => {
  return await store.dispatch('emailtemplates/findEmailTemplates', query);
};

const user = computed((): User => store.getters['usermanagement/current']);

const filterEmailTemplate = async (query?: {
  reporttype?: string;
  target?: string;
  chronorder?: string[];
  managedtype?: string;
  customer?: string;
}): Promise<EmailTemplate> => {
  return await store.dispatch('emailtemplates/filterEmailTemplate', query);
};

const sendEmail = async (email: Email): Promise<string> => {
  return await store.dispatch('diary/sendEmail', email);
};

const setClientEmailDeep = async (payload: { path: string; data: any }): Promise<any> => {
  return await store.dispatch('emailtemplates/setClientEmailDeep', payload);
};

const setFeedbackEmailDeep = async (payload: { path: string; data: any }): Promise<any> => {
  return await store.dispatch('emailtemplates/setFeedbackEmailDeep', payload);
};

const setLandlordEmailDeep = async (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return await store.dispatch('emailtemplates/setLandlordEmailDeep', payload);
};

const setTenantEmailDeep = async (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return await store.dispatch('emailtemplates/setTenantEmailDeep', payload);
};

const setDataentryEmailDeep = async (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return await store.dispatch('emailtemplates/setDataentryEmailDeep', payload);
};

const setDeletebookingEmailDeep = async (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return await store.dispatch('emailtemplates/setDeletebookingEmailDeep', payload);
};


const updateLandlordEmailConfirmationSent = async (bookingid: string): Promise<any> => {
  return await store.dispatch('diary/updateLandlordEmailConfirmationSent', bookingid);
};

const updateTenantEmailConfirmationSent = async (bookingid: string): Promise<any> => {
  return await store.dispatch('diary/updateTenantEmailConfirmationSent', bookingid);
};

const updateDataentryEmailConfirmationSent = async (bookingid: string): Promise<any> => {
  return await store.dispatch('diary/updateDataentryEmailConfirmationSent', bookingid);
};

const getEmailLogs = async (bookingid: string): Promise<EmailLogs> => {
  return await store.dispatch('diary/getEmailLogs', bookingid);
};

const generateConfirmationNotePdfAction = (booking: Booking): Promise<any> => {
  return store.dispatch('diary/generateConfirmationNotePdf', booking);
};

const setClientEmail = (email: Email): void => {
  store.commit('emailtemplates/setClientEmail', email);
};

const setFeedbackEmail = (email: Email): void => {
  store.commit('emailtemplates/setFeedbackEmail', email);
};

const downloadNormalInvoice = async (report: Report): Promise<any> => {
  return await store.dispatch('reports/downloadInvoice', report);
};

const downloadProformaInvoice = async (report: Report): Promise<any> => {
  return await store.dispatch('reports/downloadProformaInvoice', report);
};

const removeRequest = (id: string): void => {
  store.commit('app/removeRequest', id);
};

const setLandlordEmail = (email: Email): void => {
  store.commit('emailtemplates/setLandlordEmail', email);
};

const setTenantEmail = (email: Email): void => {
  store.commit('emailtemplates/setTenantEmail', email);
};

const setDataentryEmail = (email: Email): void => {
  store.commit('emailtemplates/setDataentryEmail', email);
};

const setDeletebookingEmail = (email: Email): void => {
  store.commit('emailtemplates/setDeletebookingEmail', email);
};

onMounted(() => {
  //loadAllEmailTemplates();

});

const init = async () => {  
  bodydata.value = "";
  setTemplate();
  setEmail();
  booking.value.emailattachments = [];
  if (props.id !== 'clientfeedback-email-modal' && (props.target === "Managed Client" ||
    props.target === "Non-Managed Client" ||
    props.target === "Landlord")) {
    prepareEmailAttachmentList();
    email.value.attachments = [...autoattachments.value];
  } else {
    email.value.attachments = [];
  }
  setTimeout(() => {
    email.value.attachments = [...autoattachments.value];
  }, 1000);
};

const setTemplate = async () => {
  // Clear previous values
  setEmailDeep({ path: "bookingid", data: "", });
  setEmailDeep({ path: "target", data: [] });
  setEmailDeep({ path: "chronorder", data: [] });
  setEmailDeep({ path: "subject", data: "" });
  setEmailDeep({ path: "body", data: "" });

  // Check if this is a last minute booking
  let lastMinuteTemplate = await filterEmailTemplate({
    reporttype: props.reporttype,
    target: props.target,
    chronorder: [props.chronorder, 'Last Minute'],
    managedtype: booking.value.managedtype,
    customer: props.customer,
  })

  let chronorderfilter = [];
  if (props.chronorder)
    chronorderfilter.push(props.chronorder);
  if (lastMinuteTemplate?.id) {
    let lastminuteTemplate: EmailTemplate = lastMinuteTemplate;
    const todaymoment = moment().utc();
    const bookingmoment = moment(booking.value.startdate).utc().startOf("day");
    let daysleft = moment.duration(
      bookingmoment.diff(todaymoment)
    ).asDays();
    if (daysleft <= lastminuteTemplate.daysbefore) chronorderfilter.push('Last Minute');
  }

  let managedtype = booking.value.managedtype;
  if (props.target === 'Data Entry')
    managedtype = '';

  await findEmailTemplates({
    reporttype: props.reporttype,
    target: props.target,
    chronorder: chronorderfilter,
    managedtype: managedtype,
    customer: props.customer,
  })
    .then(async (emailtemplate: EmailTemplate) => {
      if (!emailtemplate || !emailtemplate.id) {
        emailtemplate = new EmailTemplate();
      }
      let newemail: Email = new Email(emailtemplate);
      newemail.fromaddress = email.value.fromaddress;
      newemail.toaddresses = email.value.toaddresses;
      newemail.ccaddresses = email.value.ccaddresses;
      newemail.bccaddresses = email.value.bccaddresses;

      if (emailtemplate.cctoagent && booking.value.bookedbyemail) {
        newemail.ccaddresses.push(booking.value.bookedbyemail)
      }
      if (emailtemplate.cctolandlord && props.id != 'clientfeedback-email-modal') {
        booking.value.landlords.forEach((l: Landlord) => {
          newemail.ccaddresses.push(l.llemail);
        })
      } else if(emailtemplate.cctolandlord && booking.value.customer.policies.onsitefeedbackto.landlord) {
        booking.value.landlords.forEach((l: Landlord) => {
          newemail.ccaddresses.push(l.llemail);
        })
      }

      if ((props.target === 'Managed Client' || props.target === 'Non-Managed Client') && props.chronorder === 'Correspondance/Feedback to Client')
        setFeedbackEmail(newemail);
      else if (props.target === 'Managed Client' || props.target === 'Non-Managed Client')
        setClientEmail(newemail);
      else if (props.target === 'Landlord')
        setLandlordEmail(newemail);
      else if (props.target === 'Tenant')
        setTenantEmail(newemail);
      else if (props.target === 'Data Entry')
        setDataentryEmail(newemail);
      else if (props.target === 'Booked By' && props.chronorder === 'Delete Booking')
        setDeletebookingEmail(newemail);

      if (booking.value && booking.value.id) {
        setEmailDeep({
          path: "bookingid",
          data: booking.value.id,
        });
      }
      setEmailDeep({ path: "target", data: props.target });
      if (newemail.subject) newemail.subject = convertToPlain(normalise(newemail.subject));
      setEmailDeep({
        path: "subject",
        data: newemail.subject,
      });
      let emailbody = await addSignature(normalise(newemail.body));
      setEmailDeep({ path: "body", data: emailbody });
    })
    .catch((err: any) => actProperty.displayError(err));
}

const setEmail = () => {
  const puser: User = findProfileUser();
  if(puser?.email) {
    from.value = puser.email;
  }
  else {
    from.value = props.fromaddress;
  }
  if (useremail.value) {
    from.value = useremail.value;
  }
  to.value = "";
  cc.value = "";
  bcc.value = "";
  let ccPropArray = [];
  let ccArray = [];
  if (props.toaddresses && props.toaddresses.length) {
    const semicolonSeparatedValue = props.toaddresses.join('; ');
    to.value = semicolonSeparatedValue.split(', ').join('; ');
  }
  if (ccaddresses.value && ccaddresses.value.length)
    ccPropArray = ccaddresses.value;
  if (bccaddresses.value && bccaddresses.value.length)
    bcc.value = bccaddresses.value.join("; ");
  let gettingEmailFromLogicticRulesContactList = [];
  if(props.id === 'clientconfirmation-email-modal'){
    gettingEmailFromLogicticRulesContactList = booking.value.customer.policies.contactlist.filter((s:any) => s.confirmationcc).map((m:any)=> m.email);
  }
  if(props.id === 'clientfeedback-email-modal'){
    gettingEmailFromLogicticRulesContactList = booking.value.customer.policies.contactlist.filter((s:any) => s.feedbackcc).map((m:any)=> m.email);
  }
  ccArray = [...ccPropArray,...gettingEmailFromLogicticRulesContactList];
  if(ccArray.length > 0 && to.value){
    ccArray = ccArray.filter(item => item.trim() !== to.value.trim());
  }
  cc.value = ccArray.join("; ");
}

const prepareEmailAttachmentList = () => {
  let invoicelist: EmailAttachment[] = [];
  if (invoices.value?.length) {
    invoices.value.forEach((inv: Report) => {
      if (inv.id) {
        if (!inv.fees[0].name) {
          if (inv.attachments?.invoice) {
            invoicelist.push(prepareEmailAttachment(inv.attachments?.invoice));
          }
          else {
            const att = prepareEmailAttachment('');
            att.generatingid = uuidv4();
            att.generatingstatus = 'generating';
            downloadInvoice(inv, att);
            invoicelist.push(att);
          }
        }
        if (inv.fees[0].name) {
          if (inv.fees[0].name && inv.attachments?.proforma) {
            invoicelist.push(prepareEmailAttachment(inv.attachments?.proforma));
          }
          else {
            const att = prepareEmailAttachment('');
            att.generatingid = uuidv4();
            att.generatingstatus = 'generating';
            downloadInvoice(inv, att);
            invoicelist.push(att);
          }
        }
      }
    });
  }

  let confirmationlist: EmailAttachment[] = [];
  if (
    booking.value.attachments &&
    booking.value.attachments?.clientconfirmation
  ) {
    const attachment = prepareEmailAttachment(
      booking.value.attachments?.clientconfirmation
    );
    attachment.generatingstatus = 'finished';
    confirmationlist.push(attachment);
  }
  else {
    // Generate booking confirmation and then attach
    const attachement = prepareEmailAttachment('');
    attachement.generatingid = uuidv4();
    attachement.generatingstatus = 'generating';
    confirmationlist.push(attachement);
    generateConfirmationNotePdfAction(booking.value)
      .then((key) => {
        const att = attachments.value.find((a) => a.generatingid === attachement.generatingid);
        if(att) {
          att.generatingstatus = 'finished';
          att.filename = getAttachmentDisplayname(key);
          att.path = actProperty.prependDownloadsURL(key);
        }
      })
      .catch((err: any) => actProperty.displayError(err));
  }
  attachments.value = [...invoicelist, ...confirmationlist];
  if (props.chronorder === "Correspondance/Feedback to Client") {
    autoattachments.value = [];
  }
  else if (booking.value?.customer?.accountsettings?.invoiceinadvance) {
    autoattachments.value = [...invoicelist, ...confirmationlist];
  }
  else {
    autoattachments.value = [...confirmationlist];
  }
}

const downloadInvoice = (report: Report, attachment: EmailAttachment): void => {
  if (report?.id && report.fees) {
    let fee: Fee = report.fees[0];
    if (!fee.name) {
      downloadProformaInvoice(report)
        .then((key: string) => {
          const att = attachments.value.find((a) => a.generatingid === attachment.generatingid);
          if(att) {
            att.generatingstatus = 'finished';
            att.filename = getAttachmentDisplayname(key);
            att.path = actProperty.prependDownloadsURL(key);
          }
        })
        .finally(() => removeRequest(attachment.generatingid))
        .catch((err: any) => actProperty.displayError(err));
    } else {
      downloadNormalInvoice(report)
        .then((key: string) => {
          const att2 = attachments.value.find((a) => a.generatingid === attachment.generatingid);
          att2.generatingstatus = 'finished';
          att2.filename = getAttachmentDisplayname(key);
          att2.path = actProperty.prependDownloadsURL(key);
        })
        .finally(() => removeRequest(attachment.generatingid))
        .catch((err: any) => actProperty.displayError(err));
    }
  }
}

const prepareEmailAttachment = (val: string): EmailAttachment => {
  const filename = getAttachmentDisplayname(val);
  let attachment: EmailAttachment = new EmailAttachment({
    filename: filename,
    path: actProperty.prependDownloadsURL(val),
  });
  return attachment;
}

const toggleselection = (attachment: EmailAttachment) => {
  const index = email.value.attachments.findIndex(a => a.path === attachment.path);
  if (index < 0) email.value.attachments.push(attachment);
  else email.value.attachments.splice(index, 1);
};

const selectedattachment = (attachment: EmailAttachment) => {
  const index = email.value.attachments.findIndex(a => a.path === attachment.path);
  return index >= 0;
};

const getAttachmentDisplayname = (val: string): string => {
  const lastindex = val.lastIndexOf("/");
  return lastindex >= 0 ? val.substring(lastindex + 1) : "";
};

const from = computed({
  get: () => email.value.fromaddress,
  set: (value: string) => {
    setEmailDeep({ path: 'fromaddress', data: value });
  }
});

const to = computed({
  get: () => email.value.toaddresses ? email.value.toaddresses.join("; ") : "",
  set: (value: string) => {
    const addresses = value && value.trim().length > 0
      ? value.split(";").map(s => s.trim())
      : [];
    setEmailDeep({ path: 'toaddresses', data: addresses });
  }
});

const cc = computed({
  get: () => email.value.ccaddresses ? email.value.ccaddresses.join("; ") : "",
  set: (value: string) => {
    const addresses = value && value.trim().length > 0
      ? value.split(";").map(s => s.trim())
      : [];
    setEmailDeep({ path: 'ccaddresses', data: addresses });
  }
});

const bcc = computed({
  get: () => email.value.bccaddresses ? email.value.bccaddresses.join("; ") : "",
  set: (value: string) => {
    const addresses = value && value.trim().length > 0
      ? value.split(";").map(s => s.trim()).filter(s => s)
      : [];
    setEmailDeep({ path: 'bccaddresses', data: addresses });
  }
});

const bodyeditordata = computed({
  get: () => email.value.body,
  set: (data: string) => {
    // setEmailDeep({ path: 'body', email.value.body });
    bodydata.value = data;
  }
});

const subject = computed({
  get: () => email.value.subject,
  set: (data: string) => {
    setEmailDeep({ path: 'subject', data });
  }
});


const addSignature = async (body: string) => {
  let withsignature = `${body}`;
  const puser: User = findProfileUser();
  if(puser?.signature) {
    withsignature = `${body}<br/>${user.value.signature}`
  }
  else {
    await fetch('/assets/html/signature.html')
      .then(response => response.text())
      .then(data => {
        withsignature = `${body}<br/>${data}`
      })
      .catch(error => {
        // Handle any errors
        console.error('Error:', error);
      });
  }
  return withsignature;
}

const users = computed((): User[] => store.getters['usermanagement/users']);
const findProfileUser = (): User => {
  let puser: User = undefined;
  if(users.value?.length && booking.value?.customer?.id) {
    puser = users.value.find((u: User) => u.customers.find((c: Customer) => c.id === booking.value.customer.id));
  }
  return puser;
}

const setEmailDeep = (payload: { path: string; data: any }) => {
  if ((props.target === "Managed Client" || props.target === "Non-Managed Client") && props.chronorder === 'Correspondance/Feedback to Client') {
    setFeedbackEmailDeep(payload);
  } else if (props.target === "Managed Client" || props.target === "Non-Managed Client") {
    setClientEmailDeep(payload);
  } else if (props.target === "Landlord") {
    setLandlordEmailDeep(payload);
  } else if (props.target === "Tenant") {
    setTenantEmailDeep(payload);
  } else if (props.target === "Data Entry") {
    setDataentryEmailDeep(payload);
  } else if (props.target === "Booked By" && props.chronorder === 'Delete Booking') {
    setDeletebookingEmailDeep(payload);
  }
}

const email = computed((): Email => {
  if ((props.target === "Managed Client" || props.target === "Non-Managed Client") && props.chronorder === 'Correspondance/Feedback to Client') return feedbackemail.value;
  else if (props.target === "Managed Client" || props.target === "Non-Managed Client") return clientemail.value;
  else if (props.target === "Landlord") return landlordemail.value;
  else if (props.target === "Tenant") return tenantemail.value;
  else if (props.target === "Data Entry") return dataentryemail.value;
  else if (props.target === "Booked By" && props.chronorder === 'Delete Booking') return deletebookingemail.value;
  else return new Email();
});

const convertToPlainText = (html) => {
  let text = html;
  text = text.replace(/\n/gi, "");
  text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
  text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
  text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
  text = text.replace(/<\/div>/gi, "\n\n");
  text = text.replace(/<\/li>/gi, "\n");
  text = text.replace(/<li.*?>/gi, "  *  ");
  text = text.replace(/<\/ul>/gi, "\n\n");
  text = text.replace(/<\/p>/gi, "\n\n");
  text = text.replace(/<br\s*[\/]?>/gi, "\n");
  text = text.replace(/<[^>]+>/gi, "");
  text = text.replace(/^\s*/gim, "");
  text = text.replace(/ ,/gi, ",");
  text = text.replace(/ +/gi, " ");
  text = text.replace(/\n+/gi, "\n\n");
  text = text.replace(/\&nbsp;/g, ' ');
  return text;
}

const findOptionText = (placeholder:string, html:string) => {
  let text = "";
  if (html) {
    let plaintext = convertToPlainText(html);
    let beginindex = plaintext.indexOf(`[${placeholder}]`);
    if (beginindex >= 0) {
      let endindex = plaintext.indexOf("\n", beginindex);
      if (endindex >= 0) {
        text = plaintext.substring(
          beginindex + placeholder.length + 2,
          endindex
        );
        text = text.replace(/^ - /, '');
        text = text.replace('^- ', '');
        text = text.replace('^-', '');
      }
    }
  }
  return text;
}

const normalise = (html: string): string => {
  placeholderMap.forEach((bookingpath: string, placeholder: string) => {
    let replacevalue = _get(booking.value, bookingpath, "**");
    if (placeholder === "Type of job external") {
      replacevalue = formatReportType(replacevalue);
    } else if (placeholder === "Booked with name") {
      replacevalue = preparebookedbyname(booking.value.bookedby);
    } else if (placeholder === "Client notes") {
      if (booking.value.clientnotes) replacevalue = booking.value.clientnotes;
      else replacevalue = undefined;
    } else if (placeholder === "Landlord name") {
      replacevalue = preparelandlordname(booking.value.landlords);
    } else if (placeholder === "Tenant name") {
      replacevalue = preparetenantname(booking.value.tenants);
    } else if (placeholder === "Invoice amount" && booking.value.invoices) {
      let total = booking.value.invoices.reduce(
        (sum: number, invoice: Report) => sum + invoice.totalFees(),
        0
      );
      if (isNaN(total)) total = 0;
      replacevalue = actProperty.formatCurrency(total);
    } else if (placeholder === "Date") {
      replacevalue = moment(replacevalue).utc().format("dddd Do MMMM YYYY");
    } else if (placeholder === "Appointment time") {
      let apptime = moment(booking.value.appointmenttime).utc().format("h:mm a");
      if (apptime === "12:00 am") apptime = "No Tenant but Fixed Time";
      if (apptime === "12:15 am") apptime = "Flexi all day";
      if (apptime === "12:20 am") apptime = "Flexi AM";
      if (apptime === "12:25 am") apptime = "Flexi PM";
      if (apptime === "12:30 am") apptime = "Flexi 9 till 1.30";
      if (apptime === "12:35 am") apptime = "Flexi 1.30 till 6";
      replacevalue = apptime;
    } else if (placeholder === "Confirm Yes Button") {
      replacevalue = generatebutton(
        "Yes",
        `${window.location.origin}/booking/confirmation/${booking.value.id}/yes?`
      );
    } else if (placeholder === "Confirm No Button") {
      replacevalue = generatebutton(
        "No",
        `${window.location.origin}/booking/confirmation/${booking.value.id}/no?`
      );
    } else if (placeholder === "Confirm Callback Button") {
      replacevalue = generatebutton(
        `Call Me`,
        `${window.location.origin}/booking/confirmation/${booking.value.id}/callback?`
      );
    } else if (placeholder === "Option 1 Button") {
      let optiontext = findOptionText(placeholder, html);
      replacevalue = generatebutton(
        "Option 1",
        `${window.location.origin}/booking/confirmation/${booking.value.id}/option1?response=${optiontext}&`
      );
    } else if (placeholder === "Option 2 Button") {
      let optiontext = findOptionText(placeholder, html);
      replacevalue = generatebutton(
        "Option 2",
        `${window.location.origin}/booking/confirmation/${booking.value.id}/option2?response=${optiontext}&`
      );
    } else if (placeholder === "Option 3 Button") {
      let optiontext = findOptionText(placeholder, html);
      replacevalue = generatebutton(
        "Option 3",
        `${window.location.origin}/booking/confirmation/${booking.value.id}/option3?response=${optiontext}&`
      );
    } else if (placeholder === "Option 4 Button") {
      let optiontext = findOptionText(placeholder, html);
      replacevalue = generatebutton(
        "Option 4",
        `${window.location.origin}/booking/confirmation/${booking.value.id}/option4?response=${optiontext}&`
      );
    } else if (placeholder === "Acknowledge Button") {
      replacevalue = generatebutton(
        "Acknowledge",
        `${window.location.origin}/dataentry/acknowledge/${booking.value?.id}?`
      );
    } else if (placeholder === "Download Dataentry Files Button") {
      replacevalue = generatebutton(
        "Download",
        `${window.location.origin}/dataentry/download/${booking.value?.id}?`
      );
    } else if (placeholder === "Booking Link") {
        replacevalue = `<a href="${window.location.origin}/bookinglink/${booking.value?.id}">Booking Link</a>`;
    } else if (placeholder === "Access Branch") {
      if (booking.value.keypickup === 'From Agency') {
        replacevalue = booking.value.keypickupbranchname;
      }
      else {
        const keypickupaddress = booking.value.keypickupfromaddress.addressPreview;
        if (keypickupaddress.trim().length > 0) {
          replacevalue = keypickupaddress;
        }
      }
    }
    else if (placeholder === "Dataentry delivery date/time") {
      var bdatemoment = moment.utc(booking.value.startdateAsDate);
      var nowmoment = moment.utc();
      const diff = moment.duration(bdatemoment.diff(nowmoment)).asDays();
      if (diff > 2) {
        bdatemoment = bdatemoment.add(-2, "days");
      }
      replacevalue = bdatemoment.format('dddd Do MMMM YYYY hh:mma');
    }
    else if (placeholder === "Previous CI date") {
      let basereport: Report = booking.value.basereport;
      if(basereport?.id) {
        var reportdatemoment = moment.utc(basereport.date);
        replacevalue = reportdatemoment.format("DD/MM/YYYY");
      }
    }
    else if (placeholder === "Previous CI reference") {
      let basereport: Report = booking.value.basereport;
      if(basereport?.id) {
        replacevalue = `${basereport.ref}`;
      }
    }
    else if (placeholder === "Customer Relationship Manager DD Number") {
      let puser: User = findProfileUser();
      if(puser?.phone) {
        replacevalue = `${puser.phone}`;
      }
    }  else if (placeholder === "CO-Tenancy startdate note") {
      if(booking.value.jobtype === "checkout") {
        if(booking.value.internaljobtype === 'Check-Out - off ACT report') {
          let basereport = booking.value.basereport;
          if(basereport?.ref) {
            let displaydate = moment.utc(basereport.date).format("DD MMMM YYYY");
            replacevalue = `At Check-Out, we will compare against the report completed on ${displaydate}. If this doesn’t correspond with the tenancy start date please let us know as soon as possible.`;
          }
        }
        else {
          replacevalue = '';
        }
      }
    }

    html = html.replaceAll(
      `<span class="placeholder">[${placeholder}]</span>`,
      replacevalue
    );
  });
  html = html.replaceAll("<li>undefined</li>", "");
  return html;
}

const messageheight = computed(() => {
  return height.value - height.value * 0.80;
});

const convertToPlain = (html: string) => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

const preparetenantname = (tenants: Tenant[]) => {
  let value: string[] = [];
  if (tenants && tenants.length) {
    value = tenants
      .filter((t: Tenant) => t.ttemail && t.ttname)
      .map((t: Tenant) => {
        return getFirstname(t.ttname);
      });
  }
  return commanames(value.join(", "));
}

const preparelandlordname = (landlords: Landlord[]) => {
  let value: string[] = [];
  if (landlords && landlords.length) {
    value = landlords
      .filter((l: Landlord) => l.llemail && l.llname)
      .map((l: Landlord) => {
        return getFirstname(l.llname);
      });
  }
  return commanames(value.join(", "));
}

const preparebookedbyname = (bookedby: Bookedby[]) => {
  let value: string[] = [];
  if (bookedby && bookedby.length) {
    value = bookedby
      .filter((b: Bookedby) => b.bbemail && b.bbname)
      .map((b: Bookedby) => {
        return getFirstname(b.bbname);
      });
  }
  return commanames(value.join(", "));
}

const getFirstname = (name: string) => {
  let firstname = '';
  if (name) {
    let splits = name.trim().split(" ");
    if (splits.length > 0) {
      firstname = splits[0];
      const lowercasename = firstname.toLowerCase();
      if (lowercasename === 'mr' || lowercasename === 'mrs' || lowercasename === 'miss' || lowercasename === 'ms') {
        if (splits.length > 1) {
          firstname = splits[1];
        }
      }
    }
  }
  return firstname;
}

const commanames = (val: string) => {
  let result = val;
  let index = val.lastIndexOf(",");
  if (index > 0) {
    result = val.substring(0, index) + ' and ' + val.substring(index + 2);
  }
  return result;
}

const generatebutton = (text: string, link: string) => {
  return `<span style="font-family: Helvetica, sans-serif;font-size: 100%;margin: 0;padding: 0;margin-top: 0">
      <!--[if mso]>
      <v:roundrect href="https://app.kontent.ai/gotoaction" style="width:273px;height:40px;v-text-anchor:middle;" arcsize="50%" stroke="f" fillcolor="#253746" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
          <w:anchorlock/>
          <v:textbox inset="0,0,0,0">
          <center>
      <![endif]-->
      <a href="${link}[targetemailaddress]" style="font-family: Helvetica, sans-serif;font-size: 14px;margin: 0;padding: 0 10px 0 10px;color: #e0db4c;margin-top: 0;font-weight: bold;line-height: 40px;letter-spacing: 0.1ch;text-transform: uppercase;background-color: #253746;border-radius: 5px;display: inline-block;text-align: center;text-decoration: none;white-space: nowrap;-webkit-text-size-adjust: none">&nbsp;&nbsp;<span style="color: #e0db4c;">${text}</span>&nbsp;&nbsp;</a>
      <!--[if mso]>
          </center>
          </v:textbox>
      </v:roundrect>
      <![endif]-->
  </span>`;
}

const placeholderMap: Map<string, string> = new Map<string, string>([
  ["Type of job external", "jobtype"],
  ["Address", "address.displayaddress"],
  ["Booked with name", "bookedbyname"],
  ["Appointment time", "appointmenttime"],
  ["Client", "customer.companyName"],
  ["Client notes", "clientnotes"],
  ["Branch", "customer.branchName"],
  ["Access Branch", "keypickupbranchname"],
  ["Landlord name", "llname"],
  ["Tenant name", "ttnme"],
  ["Date", "startdate"],
  ["Start time", "starttime"],
  ["End time", "endtime"],
  ["Invoice amount", ""],
  ["Confirm Yes Button", ""],
  ["Confirm No Button", ""],
  ["Confirm Callback Button", ""],
  ["Option 1 Button", ""],
  ["Option 2 Button", ""],
  ["Option 3 Button", ""],
  ["Option 4 Button", ""],
  ["Acknowledge Button", ""],
  ["Download Dataentry Files Button", ""],
  ["Booking Link", ""],
  ["Customer Relationship Manager DD Number", ""],
  ["Dataentry Report reference", "dataentryreport.ref"],
  ["Dataentry delivery date/time", ""],
  ["Previous CI date", ""],
  ["Previous CI reference", ""],
  ["CO-Tenancy startdate note", ""],
]);

const formatReportType = (type: string) => {
  return actProperty.formatReportType(type, dictionary.value);
}

const bodyeditor = computed(() => {
  return Editor;
});

const bodyEditorConfig = computed(() => {
  return {
    toolbar: {
      items: ['heading', '|', 'bold', 'italic', 'link', '|', 'alignment:left', 'alignment:right', 'alignment:center',
        'alignment:justify', '|', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'outdent', 'indent',
        '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo', '|', 'placeholder'],
    },
    licenseKey:
      "UUI3NHlwcFFOOXZpdFlqSFlhSUFYbWpCRHY4MnpuQWR1Mk95K2ZZWkxKSlVGclUrSFAvWEpmYndNRzNKLU1qQXlOREF4TURZPQ==",
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
  };
});

const canSend = () => {
  return email.value.subject && email.value.body;
}

const send = async () => {
  if(bodydata.value && bodydata.value.length > 0){
    setEmailDeep({ path: 'body', data:bodydata.value });
  }
  if (canSend()) {
    await sendEmail(email.value)
      .then((response: string) => {
        if (response) {
          toasted.success(`Email successfully sent`);
        }
      })
      .then(() => {
        if (props.chronorder != 'Issue Report') {
          // Issue report confrmation dates are set in the backend
          // if (
          //   props.target === "Managed Client" ||
          //   props.target === "Non-Managed Client"
          // ) {
          //   updateClientEmailConfirmationSent(booking.value.id);
          // }
          
          if (props.target === "Landlord") {
            updateLandlordEmailConfirmationSent(booking.value.id);
          } else if (props.target === "Tenant") {
            updateTenantEmailConfirmationSent(booking.value.id);
          } else if (props.target === "Data Entry") {
            updateDataentryEmailConfirmationSent(booking.value.id);
          } else if (props.target === "Booked By") {
            1 + 1;
          }
        }
      })
      .then(() => {
        if (props.chronorder != 'Delete Booking')
          getEmailLogs(booking.value.id);
      });
  }
  hide();
}


const show = () => {
  if(minimized.value){
    restoreModal();
  } else if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};


const emailattachmentdocuments = computed(() => {
  return booking.value.emailattachmentdocuments;
});

watch(() => booking.value.emailattachmentdocuments, () => {
  if(booking.value.emailattachments.length > 0){
    email.value.attachments = [...new Map([...email.value.attachments, ...booking.value.emailattachments].map(obj => [obj.path, obj])).values()];
    attachments.value = [...new Map([...attachments.value, ...booking.value.emailattachments].map(obj => [obj.path, obj])).values()];
  }
});

const ccaddresses = computed(() => {
  let prop = systemproperties.value?.find(sp => sp.propertykey === 'booking.email.ccaddress');
  return prop && prop.value ? [prop.value] : [];
});

const bccaddresses = computed(() => {
  let prop = systemproperties.value?.find(sp => sp.propertykey === 'booking.email.bccaddress');
  return prop && prop.value ? [prop.value] : [];
});

const minimizeModal = () => {
  minimized.value = true;
  hide();
};

const restoreModal = () => {
  minimized.value = false;
  show();
};
defineExpose({ init, show, hide });
</script>

<style lang="scss">
.placeholder {
  background-color: yellow;
}

.body-editor .ck-editor__editable_inline {
  min-height: 400px;
  max-height: 40vh;
  overflow-y: auto;
}

.subject-editor .ck-editor__editable_inline {
  max-height: 100px;
}
.minmaxCon {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 1036;
}
</style>