<template>
  <div class="autoreports list">
    <div
      v-if="!autoreports.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No Autoreports found</h2>
      <p class="mb-1">for the selected month or search</p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th class="date">Date</th>
          <th class="ref">Ref</th>
          <th class="type">Type</th>
          <th class="address">Address</th>
          <th class="exported" v-if="!isDataentry">Exported</th>
          <th class="report">Report</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, $index) in autoreports" :key="$index">
          <td>{{ actProperty.formatDateForDisplay(report.date) }}</td>
          <td>{{ report.ref }}</td>
          <td>{{ actProperty.formatReportType(report.type, dictionary) }}</td>
          <td v-html="actProperty.formatAddress(report.address)"></td>
          <td v-if="!isDataentry">
            <span
              v-if="report.exportedAt"
              v-html="actProperty.formatDatetimeForDisplay(report.exportedAt)"
            ></span>
          </td>
          <td>
            <div
              class="btn-group"
              role="group"
              aria-label="Download Report PDFs"
            >
              <!-- New generate report buttons to get around the pdf timeout issues -->
              <ProgressButton
                @click="actProperty.copyDownloadButton(report, 'report')"
                @dblclick="generateReportPdf(report, $event)"
                class="btn btn-outline-primary"
                :title="actProperty.formatReportType(report.type, dictionary)"
              >
                <i class="far fa-file-pdf"></i>
              </ProgressButton>

              <ProgressButton
                v-if="report.type == 'checkout'"
                @click="actProperty.copyDownloadButton(report, 'summary')"
                @dblclick="generateReportSummaryPdf(report, $event)"
                :disabled="!isCOSMActive(report)"
                class="btn btn-outline-primary"
                title="Check-Out Summary"
              >
                <i class="far fa-file-pdf"></i>
              </ProgressButton>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ReportPagination
      v-if="isDataentry"
      :total="autoreports.length"
      :current="autoreportsState.currentPage"
      :limit="autoreportsState.pageLimit"
      @pageReports="doReportsPaging"
    />

    <ReportPagination
      v-else
      :total="autoreportsState.monthlyReportCount"
      :current="autoreportsState.currentPage"
      :limit="autoreportsState.pageLimit"
      @pageReports="doReportsPaging"
    />

    <ul
      v-if="isAdministrator && autoreportsState.monthlyFeesTotal"
      class="fee-totals list-inline"
    >
      <li class="list-inline-item">Monthly Total</li>
      <li class="list-inline-item">
        <span>Reports:</span>
        {{ autoreportsState.monthlyReportCount!.toLocaleString() }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import 'bootstrap';
import { ref, computed, onMounted, inject, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { DepositoryTenancy, Dictionary, DictionaryReportType, Report, Room, Section, Type } from '@/models';
import { AutoReportsState } from '@/store/autoreports/types';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import sanitize from 'sanitize-filename';
import { checkitem } from '@/utilities';
import { useToast } from "vue-toastification";
import ProgressButton from "@/components/ProgressButton.vue";
import ReportPagination from "@/components/report/ReportPagination.vue";
const store = useStore();
const router = useRouter();
const route = useRoute();
const toasted = useToast();

const actProperty: any = inject('actProperty');

const autoreportsState = computed(() => store.state.autoreports as AutoReportsState);

const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const isAdministrator = computed(() => store.getters['auth/isAdministrator']);
const companyName = computed(() => store.getters['auth/companyName']);
const isCustomer = computed(() => store.getters['auth/isCustomer']);
const isDataentry = computed(() => store.getters['auth/isDataentry']);
const autoreports = computed((): Report[] => store.getters['autoreports/list']);
const statereport = computed(() => store.getters['autoreports/current']);
const downloadInvoiceAction = (report: Report): Promise<any> => {
  return store.dispatch('autoreports/downloadInvoice', report);
};

const generateReportPdfAction = (report: Report): Promise<any> => {
  return store.dispatch('autoreports/generateReportPdf', report);
};

const generateReportSummaryPdfAction = (report: Report): Promise<any> => {
  return store.dispatch('autoreports/generateReportSummaryPdf', report);
};

const downloadCSVAction = (payload: {month: string | null, search: string | null}): Promise<any> => {
  return store.dispatch('autoreports/downloadCSV', payload);
};

const downloadFeedbackCSVAction = (month: string | null): Promise<any> => {
  return store.dispatch('autoreports/downloadFeedbackCSV', month);
};

const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('autoreports/setReportDeep', payload);
}

const toggleReportLock = (report: Report): Promise<Report> => {
  return store.dispatch('autoreports/toggleReportLock', report);
};

const setCurrentPage = (page: number): Promise<any> => {
  return store.dispatch('autoreports/setCurrentPage', page);
};

const removeRequest = (id: string): void => {
  store.commit('app/removeRequest', id);
};
const setReports = (autoreports: Report[]): void => {
  store.commit('autoreports/setAutoReports', autoreports);
};
const isCOSMActive = (report: Report) => {
  let result = true;
  if (report && report.customer && report.customer.reportsettings) {
    result =
      report &&
      report.customer &&
      report.customer.reportsettings.cosummaryactive;
  }
  return result;
}

const doReportsPaging = (page: number): void => {
  setCurrentPage(page);
  const queryParams = { ...route.query };
  queryParams.page = page + ""; // hack to coerce to string
  if (isDataentry.value)
    router.push({ path: "deautoreports", query: queryParams });
  else router.push({ path: "autoreports", query: queryParams });
}
const generateReportPdf = (report: Report, requestId: string): void => {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
      nextTick(() => {
        setReports([...autoreports.value]);
      });
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

const generateReportSummaryPdf = (report: Report, requestId: string): void => {
  generateReportSummaryPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} Summary PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}
onMounted(() => {
  // Equivalent to mounted() lifecycle hook in Vue 2
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
tr {
  cursor: pointer;
}
.btn.disabled {
  cursor: not-allowed;
}

th {
  &.date {
    width: 7rem;
  }
  &.ref {
    width: 6rem;
  }
  &.type {
    width: 8rem;
  }
  &.address {
    width: 20rem;
  }
  &.exported {
    width: 9rem;
  }
  &.report {
    width: 7rem;
  }
  &.invoice {
    width: 9rem;
  }
  &.actions {
    width: 10rem;
  }
}

.fee-totals {
  font-weight: 500;
  span {
    font-weight: normal;
  }
}

.green-color {
  color: #28a745;
}

.amber-color {
  color: orange;
}

.grey-color {
  color: rgb(136, 136, 136);
}
</style>
