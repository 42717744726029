<template>
  <div>
    <multiselect
      v-model="monthOption"
      :options="options"
      track-by='value'
      label='label'
      @select="onSelect">
    </multiselect>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import { extendMoment } from "moment-range";
const momentRange = extendMoment(Moment);
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const options = ref<{ value: string, label: string }[]>([]);

const monthOption = computed(() => {
  return options.value.find(x => x.value === store.state.autoreports.month);
});

const resetCurrentPage = async () => {
  await store.dispatch('autoreports/resetCurrentPage');
};

const setOptions = () => {
  const startDate = new Date(2010, 0, 1); // January 1, 2010
  const endDate = moment().add(5, 'months').toDate(); // Adds 5 months to the current date
  const range = momentRange.range(startDate, endDate);
  options.value = Array.from(range.by("month"))
    .reverse()
    .map(date => ({
      value: date.format("YYYY-MM"),
      label: date.format("MMMM YYYY"),
    }));
};

const onSelect = async (selected: { value: string, label: string }) => {
  if (selected !== null) {
    await router.push({ path: 'autoreports', query: { month: selected.value }});
    await resetCurrentPage();
  }
};

onMounted(() => {
  setOptions();
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
