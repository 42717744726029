import { API, Auth } from "aws-amplify";

import _ from "lodash";
import axios from "axios";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { AutoReportsState } from "./types";

import {
  Job,
  Room,
  Section,
  Item,
  Type,
  Photo,
  Report,
  Attachments,
  DepositoryTenancy,
} from "@/models";

/**
 * https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
 *
 * @param seconds - number of seconds to wait
 */
const waitForSeconds = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

/**
 * Download an attachment (PDF/ZIP) using a Job (polling).
 *
 * @param {string} generateEndpoint Hit first, returns a Job
 * @param {string} downloadEndpoint Hit second, starts processing
 *
 * @returns {Promise} Attachment S3 Key
 */
const reportAttachmentJob = async (
  generateEndpoint: string,
  downloadEndpoint: string
) => {
  // The first API call returns a Job
  // return API.post('RestAPI', generateEndpoint, null)
  // use unauthenticated post request as the endpoint should require no authorisation
  return unAuthenticatedApiPostRequest(API, generateEndpoint)
    .then((response) => new Job(response.data))
    .then((job: Job) => poll(job, downloadEndpoint))
    .then((payload) => {
      if (payload.file === undefined) {
        throw new Error("Missing Attachment Key");
      }
      return payload.file;
    });
};
/**
 * Polls start/poll endpoints to see if a Job has completed.
 *
 * @param {string} job      Job
 * @param {string} startUrl Hit once to start Job
 *
 * @returns {Promise} Payload object
 */
const poll = async (job: Job, startUrl: string) => {
  // should put these constants into .env
  const POLL_TIMEOUT = 900; // seconds
  const POLL_INTERVAL = 2; // seconds

  const { id } = job;

  let count = 0;
  let errorMessage = "";
  unAuthenticatedApiPostRequest(API, `${startUrl}&token=${id}`).catch((err) => {
    // catch errors returned by pdf download
    // err.reponse will be undefined if API Gateway timeout which we will ignore
    // otherwise we want to return it so we can show back to the user
    if (err.response === undefined) {
      console.log("Ignore API Gateway timeout");
    } else {
      errorMessage = err.response.data.message;
    }
  });

  // Keep polling until the job is complete, or time limit is reached
  while (count * POLL_INTERVAL < POLL_TIMEOUT) {
    count++;

    if (errorMessage) {
      // for PDFs this error is likely to be from the Pug template
      throw new Error(errorMessage);
    }

    // const pollResult = await API.get('RestAPI', `/jobs/${id}`, null);
    const response = await unAuthenticatedApiRequest(API, `/jobs/${id}`);
    const pollResult = response.data;

    console.log(`Poll attempt #${count}`, pollResult);

    if (pollResult.completed === true) {
      if (pollResult.payload === undefined) {
        throw new Error("No Job payload");
      }
      return pollResult.payload;
    }

    await waitForSeconds(POLL_INTERVAL);
  }

  throw new Error("AWS Lambda time limit exceeded.");
};

const updateReportInReportsList = (
  store: ActionContext<AutoReportsState, any>,
  report: Report
): Report[] => {
  // Get Report index
  const reportIndex = store.state.list.findIndex(
    (r) => r._uuid === report._uuid
  );

  if (reportIndex == -1) {
    return store.state.list;
  }

  // Update Report in Reports list
  return [
    ...store.state.list.slice(0, reportIndex),
    report,
    ...store.state.list.slice(reportIndex + 1),
  ];
};

// Using API from aws-amplify was resulting in a "no current user" error
// So had to take authentication off the endpoint in cloudformation
// And use axios to make the request without authorisation so it doesn't try
// to use aws-amplify to return a user
// There may be an option to turn this feature off but I couldn't find it
const unAuthenticatedApiRequest = async (
  awsApi: any,
  endpoint: string,
  type = "get"
) => {
  const awsApiConfig = awsApi.configure({});
  // grab the AWS api domain from the config
  const restApiUrl = awsApiConfig.endpoints[0].endpoint + endpoint;
  // console.log('restApiUrl', restApiUrl)
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (type === "post") {
    return axios.post(restApiUrl, options);
  }
  return axios.get(restApiUrl, options);
};

const unAuthenticatedApiPostRequest = async (awsApi: any, endpoint: string) => {
  return unAuthenticatedApiRequest(awsApi, endpoint, "post");
};

const unAuthenticatedApiPutRequest = async (
  awsApi: any,
  endpoint: string,
  payload: any
) => {
  const awsApiConfig = awsApi.configure({});
  // grab the AWS api domain from the config
  const restApiUrl = awsApiConfig.endpoints[0].endpoint + endpoint;
  // console.log('restApiUrl', restApiUrl)
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.put(restApiUrl, payload.body);
};

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<AutoReportsState, RootState> = {
  /**
   * Get a list of Reports
   */
  async getAutoReports(
    store: ActionContext<AutoReportsState, any>,
    payload: { withbooking: boolean }
  ): Promise<Report[]> {
    const options = {
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

    // Check Vuex state for the month and search params
    if (store.state.month) {
      _.set(options, "queryStringParameters.month", store.state.month);
    } else if (store.state.search) {
      _.set(options, "queryStringParameters.search", store.state.search);
    } else {
      throw Error("Unable to search by either month or search text");
    }

    if (store.state.filter) {
      _.set(options, "queryStringParameters.filter", store.state.filter);
    }

    if (store.state.dataentrystatus) {
      _.set(
        options,
        "queryStringParameters.dataentrystatus",
        store.state.dataentrystatus
      );
    }

    // Set office
    if (store.state.currentoffice) {
      _.set(options, "queryStringParameters.office", store.state.currentoffice);
    }

    // Check for page params for paginaton and offset
    if (store.state.currentPage) {
      _.set(options, "queryStringParameters.page", store.state.currentPage);
    } else {
      _.set(options, "queryStringParameters.page", 1);
    }

    let endpoint = "/autoreports";
    if (payload?.withbooking) endpoint = "/autoreportswithbooking";

    store.commit("app/addRequest", "getAutoReports", { root: true });
    return API.get("RestAPI", endpoint, options)
      .then((response) => {
        if (response.headers.hasOwnProperty("x-act-monthly-report-count")) {
          store.commit(
            "setMonthlyReportCount",
            response.headers["x-act-monthly-report-count"]
          );
        } else {
          store.commit("setMonthlyReportCount", null);
        }
        if (response.headers.hasOwnProperty("x-act-report-page-limit")) {
          store.commit(
            "setPageLimit",
            response.headers["x-act-report-page-limit"]
          );
        }
        // console.log('response.headers', response.headers)
        return response.data.map((x: Partial<Report>) => new Report(x));
      })
      .then((autoreports: Report[]) => {
        store.commit("setAutoreports", autoreports);
        return autoreports;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getAutoReports", { root: true })
      );
  },

  /**
   * Get Reports Linked to a Report
   * - In the previous Merlin system Reports WERE NOT hard-linked on an object-basis,
   *   they were soft-linked by an Address-association (SQL query)
   * - In our new system Reports ARE hard-linked on an object-basis,
   *   when the Report is "converted"
   */
  async getLinkedReports(
    store: ActionContext<AutoReportsState, any>,
    id: string
  ): Promise<Report[]> {
    const options = {
      queryStringParameters: {
        linked: id,
      },
    };
    store.commit("app/addRequest", "getLinkedReports", { root: true });
    return API.get("RestAPI", "/autoreports", options)
      .then((data) => data.map((x: Partial<Report>) => new Report(x)))
      .then((autoreports: Report[]) => {
        store.commit("setLinkedReports", autoreports);
        return autoreports;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getLinkedReports", { root: true })
      );
  },

  /**
   * Get property list from depository
   */
  async getDepositoryPropertylist(
    store: ActionContext<AutoReportsState, any>,
    payload: {
      customerid: string;
      addressLine1: string;
      addressLine2: string;
      city: string;
      postcode: string;
    }
  ): Promise<DepositoryTenancy[]> {
    const options = {
      queryStringParameters: {
        ...payload,
      },
    };
    store.commit("app/addRequest", "getDepositoryPropertylist", { root: true });
    return API.get("RestAPI", "/depository/propertylist", options)
      .then((data) => {
        let tenancylist: DepositoryTenancy[] = [];
        if (data?.status === "OK" && data?.tenancies?.length)
          tenancylist = data.tenancies.map(
            (x: Partial<DepositoryTenancy>) => new DepositoryTenancy(x)
          );
        return tenancylist;
      })
      .then((tenancylist: DepositoryTenancy[]) => {
        return tenancylist;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getDepositoryPropertylist", {
          root: true,
        })
      );
  },
  /**
   * Get a single ReportFilter
   */
  async getReportCheckPostCode(
    store: ActionContext<AutoReportsState, any>,
    payload: { ref: string; postcode: string; type: string }
  ): Promise<any> {
    const options = {
      queryStringParameters: {
        ref: payload.ref,
        postcode: payload.postcode?.trim(),
        type: payload.type,
      },
    };
    // Search by Ref
    store.commit("app/addRequest", "getReportCheckPostCode", { root: true });
    return await API.get("RestAPI", `/autoreportspostcodecheck`, options)
      .then((data) => data)
      .then((id: any) => {
        if (id) {
          return id;
        } else {
          return id;
        }
      })
      .finally(() =>
        store.commit("app/removeRequest", "getReportCheckPostCode", {
          root: true,
        })
      );
  },
  /**
   * Get the Report Photos.
   *
   * This is an unauthenticated request that any user can make, because when a
   * link to view a photo is clicked in a PDF the tenant/landlord will not be
   * signed-in with a system account.
   */
  async getReportPhotos(
    store: ActionContext<AutoReportsState, any>,
    payload: { id: string; type?: string }
  ): Promise<Photo[]> {
    store.commit("app/addRequest", "getReportPhotos", { root: true });

    let restApiUrl = `/autoreports/${payload.id}/photos`;

    if (payload.type) {
      restApiUrl += `/${payload.type}`;
    }

    // return API.get('RestAPI', restApiUrl, null)
    return unAuthenticatedApiRequest(API, restApiUrl)
      .then((response) => {
        const data = response.data;
        // console.log('data', data)
        return data.map((x: object) => new Photo(x));
      })
      .then((photos: Photo[]) => {
        if (payload.type) {
          if (payload.type === "in") {
            store.commit("setInphotos", photos);
          } else if (payload.type === "out") {
            store.commit("setOutphotos", photos);
          }
        } else {
          store.commit("setPhotos", photos);
        }
        return photos;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getReportPhotos", { root: true })
      );
  },

  /**
   * Get a "Source" Report, used to copy data into the current Report.
   *
   * Because the CMS user has entered a Ref (e.g. "50000") we cannot use the standard
   * GetReport API endpoint. Instead we need to do a search for the Report, by Report Ref.
   *
   * We also need to let the API know that we want report.rooms to be returned, because we
   * usually omit this from search API queries. This is achieved using ?props=rooms.
   */
  async getSourceReport(
    store: ActionContext<AutoReportsState, any>,
    ref: string
  ): Promise<Report> {
    const options = {
      queryStringParameters: {
        search: ref,
        props: "rooms",
      },
    };

    store.commit("app/addRequest", "getAutoReports", { root: true });
    return API.get("RestAPI", "/autoreports", options)
      .then((data) => data.map((x: Partial<Report>) => new Report(x)))
      .then((autoreports: Report[]) => {
        if (!autoreports.length) {
          throw Error("Report not found.");
        }
        store.commit("setSourceReport", autoreports[0]);
        return autoreports[0];
      })
      .finally(() =>
        store.commit("app/removeRequest", "getSourceReport", { root: true })
      );
  },
  /**
   * Add an invoice
   */
  async addInvoice(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    const init = {
      body: report.toJSON(),
    };
    store.commit("app/addRequest", "addInvoice", { root: true });
    return API.post("RestAPI", `/autoreports`, init)
      .then((data) => new Report(data))
      .finally(() =>
        store.commit("app/removeRequest", "addInvoice", { root: true })
      );
  },

  /**
   * Updatte an invoice
   */
  async updateInvoice(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    const init = {
      body: report.toJSON(),
    };
    store.commit("app/addRequest", "updateInvoice", { root: true });
    return API.put("RestAPI", `/reportinvoice/${report.id}`, init)
      .then((data) => new Report(data))
      .finally(() =>
        store.commit("app/removeRequest", "updateInvoice", { root: true })
      );
  },

  /**
   * Register the report with master property record
   */
  async registerReportWithMasterProperty(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<void> {
    store.commit("app/addRequest", "registerReportWithMasterProperty", {
      root: true,
    });
    await API.put("RestAPI", `/autoreports/registertomaster/${report.id}`, {});
    store.commit("app/removeRequest", "registerReportWithMasterProperty", {
      root: true,
    });
  },

  /**
   * Add a data entry report
   */
  async addDateentry(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    const init = {
      body: report.toJSON(),
    };
    store.commit("app/addRequest", "addDateentry", { root: true });
    return API.post("RestAPI", `/autoreports`, init)
      .then((data) => new Report(data))
      .finally(() =>
        store.commit("app/removeRequest", "addDateentry", { root: true })
      );
  },

  /**
   * Updatte a invoice
   */
  async updateDataentry(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    const init = {
      body: report.toJSON(),
    };
    store.commit("app/addRequest", "updateDataentry", { root: true });
    return API.put("RestAPI", `/autoreports/${report.id}`, init)
      .then((data) => new Report(data))
      .finally(() =>
        store.commit("app/removeRequest", "updateDataentry", { root: true })
      );
  },
  /**
   * Update a Report
   */
  async updateReportUnauthenticated(
    store: ActionContext<AutoReportsState, any>,
    queryStringParameters?: { force: boolean }
  ): Promise<Report> {
    const report: Report = store.state.current;
    const init = {
      body: report.toJSON(),
      queryStringParameters,
    };
    // comment this out as client DOES NOT want the loading/busy screen when saving a report
    store.commit("app/addRequest", "updateReport", { root: true });

    // Reset unsaved changes so if a user has hit save and continues to update
    // the report, it should flag a prompt if they try to go away from the report
    // If we had left it at the original position of after saving, then the prompt
    // won't ever flag if user hit save, then modified the report and then moved away
    const hasUnsavedChange = store.state.updates.length > 1;
    store.commit("resetUnsavedChanges");

    // --- This is a hack to get report saving to work consistently ---
    // and to remove the weird notes/comments and signature issues
    // We fake a report saving, clear the attchements here and commit to store
    // Attachments are cleared to ensure a pdf will be regenerated after saving.
    // This is faked so that uuid's are generated for the condition notes and
    // signatures after a save operation.
    //
    // if we don't fake it, uuid's don't regenerate and we get weird disappearing
    // duplicating condition notes and is rather infuriating and am unsure of
    // the root cause of this issue.
    //
    // We fake it here so we have a fresh report. The user may make some changes
    // before we get the response back from the server...
    // Also, tried doing the commit later, but sometimes the screen will "jump"
    // especially when on the rooms page
    //
    // So refreshing the report now is a fudge and hack to get the uuid's to
    // refresh correctly, when really it should be done after we get the response
    // back from the server. But the client was found the saveing was too slow
    // and why we have this hacky saving process now
    const newReport = new Report(report);
    newReport.attachments = new Attachments();
    store.commit("setReport", newReport);
    store.commit("app/removeRequest", "updateReport", { root: true });

    return unAuthenticatedApiPutRequest(
      API,
      `/unauth/autoreports/${report.id}`,
      init
    )
      .catch((err) => {
        // Problem with saving the report
        // If there was an unsaved changes, then we should restore it
        // so the system should flag a warning if user tries to navigate away
        if (hasUnsavedChange) {
          // add back in the unsaved change
          // Doing it the dirty way and probably not the "right" way
          store.state.updates.push("CHANGE!");
        }

        throw err;
      })
      .then((data) => {
        // return new Report(data)
        // Just return the report, but we update the attachments with result from
        // the back-end. So report gets re-generated after the it has been saved
        // report.attachments = data.attachments
        // We do not want it to be what is returned from the API in case user has
        // updated it. We want what is in the browser currently!
        // BE CAREFUL OF COLLISIONS IF SOMEONE IS UPDATING THE SAME REPORT
        // WITH THE ANDROID APP OR A DIFFERENT COMPUTER!
        return report;
      })
      .then((report: Report) => {
        // comment this out
        // Don't commit to the store as we fudged it earlier in this method
        // store.commit('setReport', report);
        // // We only update a subset of the Report data in Vuex, to prevent
        // // Vue from rerendering Report.rooms.
        // store.commit('setReportDataThatTheAPIMayHaveChanged', report);
        // store.commit('resetUnsavedChanges');
        return report;
      })
      .finally(() => {
        // This resets the saving loader circle thing
        // store.commit('app/removeRequest', 'updateReport', { root: true })
      });
  },

  /**
   * Update a Report without resetting attachments
   */
  async updateReportWithAttachments(
    store: ActionContext<AutoReportsState, any>,
    queryStringParameters?: { force: boolean }
  ): Promise<Report> {
    const report: Report = store.state.current;
    const init = {
      body: report.toJSON(),
      queryStringParameters,
    };

    store.commit("app/addRequest", "updateReport", { root: true });

    const hasUnsavedChange = store.state.updates.length > 1;
    store.commit("resetUnsavedChanges");

    const newReport = new Report(report);
    store.commit("setReport", newReport);
    store.commit("app/removeRequest", "updateReport", { root: true });

    return API.put("RestAPI", `/autoreports/withattachments/${report.id}`, init)
      .catch((err) => {
        if (hasUnsavedChange) {
          store.state.updates.push("CHANGE!");
        }
        throw err;
      })
      .then((data) => {
        return report;
      });
  },

  /**
   * Lock a Report
   */
  async toggleReportLock(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    store.commit("app/addRequest", "toggleReportLock", { root: true });
    return API.put("RestAPI", `/autoreports/${report.id}`, {
      body: { locked: !report.locked },
    })
      .then((data) => new Report(data))
      .then((report: Report) => {
        return report;
      })
      .finally(() =>
        store.commit("app/removeRequest", "toggleReportLock", { root: true })
      );
  },

  /**
   * Flag a CO Report
   */
  async toggleReportFlag(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    store.commit("app/addRequest", "toggleReportFlag", { root: true });
    return API.put("RestAPI", `/autoreports/${report.id}`, {
      body: { flagged: !report.flagged },
    })
      .then((data) => new Report(data))
      .then((report: Report) => {
        return report;
      })
      .finally(() =>
        store.commit("app/removeRequest", "toggleReportFlag", { root: true })
      );
  },

  /**
   * Flag a COSM Report
   */
  async toggleCOSMReportFlag(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    store.commit("app/addRequest", "toggleCOSMReportFlag", { root: true });
    return API.put("RestAPI", `/autoreports/${report.id}`, {
      body: { flaggedCOSM: !report.flaggedCOSM },
    })
      .then((data) => new Report(data))
      .then((report: Report) => {
        return report;
      })
      .finally(() =>
        store.commit("app/removeRequest", "toggleCOSMReportFlag", {
          root: true,
        })
      );
  },

  /**
   * Append images, or display by Section
   */
  async toggleReportAppendImages(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<Report> {
    const appendImages: boolean = store.state.current.appendImages;
    store.commit("app/addRequest", "toggleReportAppendImages", { root: true });
    return API.put("RestAPI", `/autoreports/${report.id}`, {
      body: { append_images: appendImages },
    })
      .then((data) => new Report(data))
      .then((report: Report) => {
        store.commit("setCurrentReportAppendImages", report.appendImages);
        return report;
      })
      .finally(() =>
        store.commit("app/removeRequest", "toggleReportAppendImages", {
          root: true,
        })
      );
  },

  /**
   * Set the current Report "Same as Check-In" for all Items without any conditions
   *
   * @param {AutoReportsState} state
   * @returns {number}
   */
  setCurrentReportSameAsCheckin(
    store: ActionContext<AutoReportsState, any>
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      const amends = store.state.current.rooms.reduce(
        (acc: number, room: Room, roomIndex: number) => {
          room.sections.forEach((section: Section, sectionIndex: number) => {
            section.types.forEach((type: Type, typeIndex: number) => {
              type.items.forEach((item: Item, itemIndex: number) => {
                if (
                  !item.condition.out.notes.length &&
                  section.slug != "overview"
                ) {
                  store.commit("setItemSameAsCheckIn", {
                    roomIndex,
                    sectionIndex,
                    typeIndex,
                    itemIndex,
                  });
                  acc += 1;
                }
              });
            });
          });
          return acc;
        },
        0
      );
      if (amends > 0) {
        store.commit("addUnsavedChange");
      }
      resolve(amends);
    });
  },

  /**
   * Change report dataentry status
   */
  async updateReportDataentryStatus(
    store: ActionContext<AutoReportsState, any>,
    payload: { id: string | undefined; status: string }
  ): Promise<Report> {
    store.commit("app/addRequest", "updateReportDataentryStatus", {
      root: true,
    });

    const options = {};

    // Set office
    if (payload.status) {
      _.set(options, "queryStringParameters.dataentrystatus", payload.status);
    }

    return API.put("RestAPI", `/reportdataentrystatus/${payload.id}`, options)
      .then((data) => new Report(data))
      .then((report: Report) => {
        let index = store.state.list.findIndex(
          (r: Report) => r.id === report.id
        );
        if (index >= 0) {
          const storereport = store.state.list[index];
          storereport.dataentrystatus = payload.status;
          let newstate = [...store.state.list];
          store.commit("setReports", newstate);
        }
        return report;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updateReportDataentryStatus", {
          root: true,
        })
      );
  },
  /**
   * Generate "Invoice" PDF (NOT using Job polling - these are quick to generate)
   */
  async downloadInvoice(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<any> {
    const { attachments, id } = report;

    try {
      // Does this attachment exist in Vuex..?
      if (attachments.invoice) {
        return attachments.invoice;
      }

      const result = await API.post(
        "RestAPI",
        `/autoreports/${id}/download/invoice`,
        null
      );
      const key = result.url;

      if (key) {
        // Set the S3 Key for the attachment
        report.attachments.invoice = key;

        // Update Report in Vuex
        updateReportInReportsList(store, report);

        return key;
      } else {
        throw new Error("Unable to generate Report PDF");
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  },

  /**
   * Generate "Proforma Invoice" PDF (NOT using Job polling - these are quick to generate)
   */
  async downloadProformaInvoice(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<any> {
    const { attachments, id } = report;

    try {
      // Does this attachment exist in Vuex..?
      if (attachments.proforma) {
        return attachments.proforma;
      }

      const result = await API.post(
        "RestAPI",
        `/autoreports/${id}/download/invoice/proforma`,
        null
      );
      const key = result.url;

      if (key) {
        // Set the S3 Key for the attachment
        report.attachments.proforma = key;

        // Update Report in Vuex
        updateReportInReportsList(store, report);

        return key;
      } else {
        throw new Error("Unable to generate Report PDF");
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  },

  async downloadCSV(
    store: ActionContext<AutoReportsState, any>,
    payload: { month: string | null; search: string | null }
  ) {
    // hit the endpoint tp return json of the autoreports we want
    const options = {
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

    // Set office
    if (store.state.currentoffice) {
      _.set(options, "queryStringParameters.office", store.state.currentoffice);
    }
    // Set search parameter
    if (store.state.search) {
      _.set(options, "queryStringParameters.search", store.state.search);
    }
    const downloadCsvUrl = `/autoreports/csv?month=${payload.month}`;
    return API.get("RestAPI", downloadCsvUrl, options).then((result) => {
      return result;
    });
  },

  async downloadFeedbackCSV(
    store: ActionContext<AutoReportsState, any>,
    month: string | null
  ) {
    const options = {
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

    // Set office
    if (store.state.currentoffice) {
      _.set(options, "queryStringParameters.office", store.state.currentoffice);
    }
    const downloadFeedbackCsvUrl = `/autoreports/feedback-flag-csv?month=${month}`;
    return API.get("RestAPI", downloadFeedbackCsvUrl, options).then(
      (result) => {
        return result;
      }
    );
  },

  /**
   * Generate "Report" PDF (Uses Job polling)
   *
   * @todo not DRY! See generateReportSummaryPdf(), generatePhotos()
   */
  async generateReportPdf(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<any> {
    const { attachments, id } = report;

    try {
      // Does this attachment exist in Vuex..?
      if (attachments.report) {
        return attachments.report;
      }

      const generateEndpoint = `/reports/${id}/generate/report?auto=true`;
      const downloadEndpoint = `/reports/${id}/download/report?auto=true`;

      const key = await reportAttachmentJob(generateEndpoint, downloadEndpoint);

      if (key) {
        // Set the S3 Key for the attachment
        report.attachments.report = key;

        // Update Report in Vuex
        updateReportInReportsList(store, report);

        store.commit("updateReportInReportsList", report);

        return key;
      } else {
        throw new Error("Unable to generate Report PDF");
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  },

  /**
   * Generate "Report Summary" PDF (Uses Job polling)
   *
   * @todo not DRY! See generateReportPdf(), generatePhotos()
   */
  async generateReportSummaryPdf(
    store: ActionContext<AutoReportsState, any>,
    report: Report
  ): Promise<any> {
    const { attachments, id } = report;

    try {
      // Does this attachment exist in Vuex..?
      if (attachments.summary) {
        return attachments.summary;
      }

      const generateEndpoint = `/autoreports/${id}/generate/report/summary`;
      const downloadEndpoint = `/autoreports/${id}/download/report/summary`;

      const key = await reportAttachmentJob(generateEndpoint, downloadEndpoint);

      if (key) {
        // Set the S3 Key for the attachment
        report.attachments.summary = key;

        // Update Report in Vuex
        updateReportInReportsList(store, report);

        return key;
      } else {
        throw new Error("Unable to generate Report Summary PDF");
      }
    } catch (err: any) {
      console.error(err);
      throw new Error(err.message);
    }
  },
  /**
   * Sets any deep report property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setReportDeep(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setReportDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep report property, but do not increment unsaved changes
   * this is for changed automatically done by system
   * @param store
   * @param data
   */
  setReportDeepWithoutCounting(
    store: ActionContext<AutoReportsState, any>,
    data
  ): void {
    store.commit("setReportDeep", data);
  },

  /**
   * Move a Room up
   * @param store
   * @param uuid
   */
  moveRoomUp(store: ActionContext<AutoReportsState, any>, uuid: string): void {
    store.commit("moveRoomUp", uuid);
    store.commit("addUnsavedChange");
  },

  /**
   * Move a Room down
   * @param store
   * @param uuid
   */
  moveRoomDown(
    store: ActionContext<AutoReportsState, any>,
    uuid: string
  ): void {
    store.commit("moveRoomDown", uuid);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a Room, and increments the "unsaved changes" count
   */
  addRoom(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("addRoom", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Copies Rooms into the current Report, and increments the "unsaved changes" count
   */
  copyRooms(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("copyRooms", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds an Rooms Item, and increments the "unsaved changes" count
   */
  addItem(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("addItem", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a Item to a Room, and increments the "unsaved changes" count
   */
  removeFromRooms(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("removeFromRooms", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Add a Signature and increments the "unsaved changes" count
   *
   * @param store
   * @param data
   */
  addSignature(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("addSignature", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Remove a Signature and increments the "unsaved changes" count
   *
   * @param store
   * @param data
   */
  removeSignature(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("removeSignature", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Set a Signature property (signature.type, signature.name, etc) and increment
   * the "unsaved changes" count
   *
   * @param store
   * @param data
   */
  setSignatureProperty(
    store: ActionContext<AutoReportsState, any>,
    data
  ): void {
    store.commit("setSignatureProperty", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Set the month for the Report query.
   *
   * @param store
   * @param data
   */
  setMonth(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setMonth", data);
    // When the `month` param is set we want to clear the `search` param
    store.commit("setSearch", "");
    store.commit("setFilter", "");
    store.commit("setDataentrystatus", "");
  },

  /**
   * Set the search text for the Report query.
   *
   * @param store
   * @param data
   */
  setSearch(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setSearch", data);
    // When the `search` param is set we want to clear the `month` param
    store.commit("setMonth", "");
    store.commit("setDataentrystatus", "");
  },

  /**
   * Set the search text for the Report query.
   *
   * @param store
   * @param data
   */
  setFilter(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setFilter", data);
  },

  /**
   * Set the dataentrystatus for the Report query.
   *
   * @param store
   * @param data
   */
  setDataentrystatus(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setDataentrystatus", data);
  },

  /**
   * Set the page limit for pagination
   *
   * @param store
   * @param data
   */
  setPageLimit(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setPageLimit", data);
  },

  /**
   * Set the page for the Reports query
   *
   * @param store
   * @param data
   */
  setCurrentPage(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setCurrentPage", data);
  },

  /**
   * Set the page for the Reports query
   *
   * @param store
   * @param data
   */
  resetCurrentPage(store: ActionContext<AutoReportsState, any>): void {
    store.commit("setCurrentPage", 1);
  },

  /**
   * Set current office
   *
   * @param store
   * @param data
   */
  setCurrentOffice(store: ActionContext<AutoReportsState, any>, data): void {
    store.commit("setCurrentOffice", data);
  },
};
