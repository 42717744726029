import _findIndex from "lodash/findIndex";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isUndefined from "lodash/isUndefined";
import _reject from "lodash/reject";
import _set from "lodash/set";
import _snakeCase from "lodash/snakeCase";
import { v4 as uuidv4 } from "uuid";
import { MutationTree } from "vuex";
import { AutoReportsState } from "./types";
import { displayError, swapArrayItems } from "@/utilities";

import {
  Item,
  LinkedPhoto,
  Report,
  Room,
  SameAsCheckin,
  Signature,
  Section,
  Type,
} from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type e.g. `store.commit('setUser', user)`
 */
export const mutations: MutationTree<AutoReportsState> = {
  /**
   * Set the report currently being viewed/edited
   *
   * @param state AutoReportsState
   * @param report Report
   * @returns void
   */
  setCurrentReport(state: AutoReportsState, report: Report): void {
    state.current = report;
  },

  /**
   * Set the current Report Locked status
   *
   * @param state AutoReportsState
   * @param locked boolean
   * @returns void
   */
  setCurrentReportLocked(state: AutoReportsState, locked: boolean): void {
    state.current["locked"] = locked;
  },

  /**
   * Set the current CO Report Flagged status
   *
   * @param state AutoReportsState
   * @param flagged boolean
   * @returns void
   */
  setCurrentReportFlagged(state: AutoReportsState, flagged: boolean): void {
    // console.log('set flagged to ', flagged);
    state.current["flagged"] = flagged;
  },

  /**
   * Set the current COSM Report Flagged status
   *
   * @param state AutoreportsSate
   * @param flaggedCOSM boolean
   * @returns void
   */
  setCurrentCOSMReportFlagged(state: AutoReportsState, flaggedCOSM: boolean): void {
    // console.log('set flagged COSM to ', flaggedCOSM);
    state.current["flaggedCOSM"] = flaggedCOSM;
  },

  /**
   * Set the current Report "Append Images" status
   *
   * @param state AutoReportsState
   * @param appendImages boolean
   * @returns void
   */
  setCurrentReportAppendImages(
    state: AutoReportsState,
    appendImages: boolean
  ): void {
    state.current["appendImages"] = appendImages;
  },

  /**
   * Set current office
   *
   * @param state AutoReportsState
   * @param office string
   * @returns void
   */
  setCurrentOffice(state: AutoReportsState, office: string): void {
    state.currentoffice = office;
  },

  /**
   * Set an Item's "Same as Check-In" property to true
   *
   * @param {AutoReportsState} state
   * @param {object} payload
   * @returns {number}
   */
  setItemSameAsCheckIn(
    state: AutoReportsState,
    payload: {
      roomIndex: number;
      sectionIndex: number;
      typeIndex: number;
      itemIndex: number;
    }
  ): void {
    state.current.rooms[payload.roomIndex].sections[payload.sectionIndex].types[
      payload.typeIndex
    ].items[payload.itemIndex].condition.out["sameAsCheckin"] =
      new SameAsCheckin({ active: true });
  },

  /**
   * Set the month for the Autoreports query.
   *
   * @param state AutoReportsState
   * @param month Month in YYYY-MM format
   * @returns void
   */
  setMonth(state: AutoReportsState, month?: string): void {
    state.month = month;
  },

  /**
   * Set the month for the Autoreports query.
   *
   * @param state AutoReportsState
   * @param month Month in YYYY-MM format
   * @returns void
   */
  setCurrentPage(state: AutoReportsState, currentPage: number): void {
    state.currentPage = currentPage;
  },

  /**
   * Set the search for the Autoreports query.
   *
   * @param state AutoReportsState
   * @param search Search text
   * @returns void
   */
  setSearch(state: AutoReportsState, search?: string): void {
    state.search = search;
  },

  /**
   * Set the filter for the Autoreports query.
   *
   * @param state AutoReportsState
   * @param filter Filter text
   * @returns void
   */
  setFilter(state: AutoReportsState, filter?: string): void {
    state.filter = filter;
  },

  /**
   * Set the dataentrystatus for the Autoreports query.
   *
   * @param state AutoReportsState
   * @param status
   * @returns void
   */
  setDataentrystatus(state: AutoReportsState, status?: string): void {
    state.dataentrystatus = status;
  },

  /**
   * Set the reports list
   *
   * @param state AutoReportsState
   * @param reports Report[]
   * @returns void
   */
  setAutoreports(state: AutoReportsState, reports: Report[]): void {
    state.list = reports;
  },

  /**
   * Set the monthly Fees total
   * - This is the total of all the fees, for the reports, in the current month
   *
   * @param state AutoReportsState
   * @param total number
   * @returns void
   */
  setMonthlyFeesTotal(state: AutoReportsState, total: number | null): void {
    state.monthlyFeesTotal = total;
  },

  /**
   * Set the monthly Report count
   * - This is the total number Autoreports, in the current month
   *
   * @param state AutoReportsState
   * @param count number
   * @returns void
   */
  setMonthlyReportCount(state: AutoReportsState, count: number | null): void {
    state.monthlyReportCount = count;
  },

  /**
   * Set the reports list
   *
   * @param state AutoReportsState
   * @param reports Report[]
   * @returns void
   */
  setLinkedAutoreports(state: AutoReportsState, reports: Report[]): void {
    state.linked = reports;
  },

  /**
   * Set the currently edited/viewed report
   */
  setReport(state: AutoReportsState, report: Report): void {
    state.current = report;
  },
  /**
   * When we set (overwite) an entire Report in Vuex we lose whether each Room was
   * expanded/collapsed, because new _uuid properties in Rooms/Sections/Types/Items
   * cause Vuex to assume they are new/different objects with a fresh state.
   *
   * To prevent this, after saving/updating a Report in the CMS we assume that
   * most of the data such as Report.rooms, Report.address, etc in Vuex is a direct
   * match for the data in the API response.
   *
   * We only update the Report data that we really need to - the data which the
   * API may have changed.
   *
   * This prevents the UI from re-rendering, and we keep the expanded/collapsed state.
   */
  setReportDataThatTheAPIMayHaveChanged(
    state: AutoReportsState,
    report: Report
  ): void {
    state.current.attachments = report.attachments;
    state.current.customer = report.customer;
    state.current.customerId = report.customerId;
    state.current.date = report.date;
    state.current.id = report.id;
    state.current.locked = report.locked;
    state.current.preparedBy = report.preparedBy;
    state.current.ref = report.ref;
  },

  /**
   * Set the photos (photos) for the current Report
   */
  setPhotos(state: AutoReportsState, photos: LinkedPhoto[]): void {
    state.photos = photos;
  },

  /**
   * Set the currently edited/viewed report
   */
  setReportDeep(
    state: AutoReportsState,
    payload: { path: string; data: any }
  ): void {
    state.current = Object.assign(
      new Report(),
      _set(state.current, payload.path, payload.data)
    );
  },

  /**
   * Unset the current Autoreports list (to free up resources)
   */
  unsetAutoreports(state: AutoReportsState): void {
    state.list = [];
  },

  updateReportInAutoreportsList(state: AutoReportsState, report: Report): void {
    // Get Report index
    const reportIndex = state.list.findIndex((r) => r.id === report.id);

    if (reportIndex) {
      state.list = [
        ...state.list.slice(0, reportIndex),
        report,
        ...state.list.slice(reportIndex + 1),
      ];
    }
  },

  /**
   * Unset the current Report (to free up resources)
   */
  unsetCurrentReport(state: AutoReportsState): void {
    state.current = new Report({});
  },

  /**
   * Move Room down
   */
  moveRoomDown(state: AutoReportsState, uuid: string): void {
    const index = state.current.rooms.findIndex(
      (room: Room) => room._uuid === uuid
    );
    state.current = Object.assign(
      new Report(),
      _set(
        state.current,
        "rooms",
        swapArrayItems(state.current.rooms, index, index + 1)
      )
    );
  },

  /**
   * Move Room down
   */
  moveRoomUp(state: AutoReportsState, uuid: string): void {
    const index = state.current.rooms.findIndex(
      (room: Room) => room._uuid === uuid
    );
    state.current = Object.assign(
      new Report(),
      _set(
        state.current,
        "rooms",
        swapArrayItems(state.current.rooms, index, index - 1)
      )
    );
  },

  /**
   * Sets a deep report property and increments the "unsaved changes" count
   * Adds a Room
   */
  addRoom(state: AutoReportsState, data: { room: Room; name: string }): void {
    state.current["rooms"] = state.current.rooms.concat(
      new Room({
        name: data.name,
        type: _snakeCase(data.room.name),
        sections: [],
      })
    );
  },

  /**
   * Copies Rooms into the current Report
   */
  copyRooms(
    state: AutoReportsState,
    data: { rooms: Room[]; targetIndex: number }
  ): void {
    state.current["rooms"] = state.current.rooms.concat(data.rooms);
  },

  /**
   * Adds a Room item
   *
   * - Adds a new Section if required
   * - Adds a new Type if required
   * - Places the item under the correct Room/Category/Type if existing ones are supplied
   */
  addItem(
    state: AutoReportsState,
    data: {
      room: { name: string; slug: string }; // room.slug here is actually Room.type
      section: { name: string; slug: string };
      type: { name: string; slug: string };
      item: Item;
    }
  ): void {
    // Check we have an existing Room with the same name
    // (we need to match by name, because Rooms do not have unique slugs like Sections, Types & Items)
    const roomIndex = state.current.rooms.findIndex(
      (room) => room.name === _get(data.room, "name")
    );

    // Do we have an existing Room?
    if (roomIndex === -1) {
      displayError("Could not find Room");
      return;
    }
    // console.log('roomIndex', rooomIndex)

    // check if section already exists in room
    // if it does we append this 'item' to the types array
    // else we append this 'item' to the sections array
    // This is so the 'items' are grouped correctly into ther rooms sections
    // Before it was adding each item to the room as a section with no grouping
    // So it should be something like
    // Room=Entrance
    //   Section=Doors
    //     Type=Frame
    //       Item=Foo
    //       Item=Bar
    //       Item=Baz
    //     Type=Knob
    //       Item=Foo
    //     Type=Hook
    //       Item=Foo
    //   Section=Ceiling
    //     Type=Decor
    //       Item=Foo
    //     Type=Lighting
    //       Item=Foo
    //   Section=Walls
    //     Type=Decor
    //       Item=Foo

    const sectionIndex = state.current.rooms[roomIndex].sections.findIndex(
      (section) => section.name === _get(data.section, "name")
    );
    // console.log('sectionIndex', sectionIndex)

    // Do we have an existing Section?
    if (sectionIndex === -1) {
      // Add new Section, Type & Item
      const section = new Section({
        slug: data.section.slug,
        name: data.section.name,
        types: [
          {
            slug: data.type.slug,
            name: data.type.name,
            items: [data.item],
          } as Type,
        ],
      });

      state.current.rooms[roomIndex]["sections"] =
        state.current.rooms[roomIndex].sections.concat(section);
    } else {
      // Do we have an existing Type?
      const typeIndex = state.current.rooms[roomIndex].sections[
        sectionIndex
      ].types.findIndex((type: Type) => type.name === _get(data.type, "name"));
      // console.log('typeIndex', typeIndex)

      if (typeIndex === -1) {
        // Add new Type & Item into existing Section
        const type = new Type({
          slug: data.type.slug,
          name: data.type.name,
          items: [data.item],
        });

        state.current.rooms[roomIndex].sections[sectionIndex]["types"] =
          state.current.rooms[roomIndex].sections[sectionIndex].types.concat(
            type
          );
      } else {
        // Add new Item into existing Type
        state.current.rooms[roomIndex].sections[sectionIndex].types[typeIndex][
          "items"
        ] = state.current.rooms[roomIndex].sections[sectionIndex].types[
          typeIndex
        ].items.concat(data.item);
      }
    }
  },

  /**
   * ++ Unsaved changes
   */
  addUnsavedChange(state: AutoReportsState, id: string): void {
    if (_isEmpty(id)) {
      id = uuidv4();
    }
    state.updates = [...new Set(state.updates.concat([id]))];
  },

  /**
   * Reset Unsaved changes
   */
  resetUnsavedChanges(state: AutoReportsState): void {
    state.updates = [];
  },

  removeFromRooms(
    state: AutoReportsState,
    { roomIndex, sectionIndex, typeIndex, itemIndex, inOrOut, noteIndex }
  ): void {
    // console.log(
    //   "roomIndex",
    //   roomIndex,
    //   "sectionIndex",
    //   sectionIndex,
    //   "typeIndex",
    //   typeIndex,
    //   "itemIndex",
    //   itemIndex
    // );
    switch (true) {
      // Remove Item Condition Note (In)
      case !_isUndefined(roomIndex) &&
        !_isUndefined(sectionIndex) &&
        !_isUndefined(typeIndex) &&
        !_isUndefined(itemIndex) &&
        !_isUndefined(inOrOut) &&
        inOrOut === "in" &&
        !_isUndefined(noteIndex):
        // console.log(
        //   "🗑 Condition Note, In",
        //   roomIndex,
        //   sectionIndex,
        //   typeIndex,
        //   itemIndex,
        //   inOrOut,
        //   noteIndex
        // );
        const item =
          state.current.rooms[roomIndex].sections[sectionIndex].types[typeIndex]
            .items[itemIndex];
        item.condition.in.notes.splice(noteIndex, 1);
        break;
      // Remove Item Condition Note (Out)
      case !_isUndefined(roomIndex) &&
        !_isUndefined(sectionIndex) &&
        !_isUndefined(typeIndex) &&
        !_isUndefined(itemIndex) &&
        !_isUndefined(inOrOut) &&
        inOrOut === "out" &&
        !_isUndefined(noteIndex):
        // console.log(
        //   "🗑 Condition Note, Out",
        //   roomIndex,
        //   sectionIndex,
        //   typeIndex,
        //   itemIndex,
        //   inOrOut,
        //   noteIndex
        // );
        state.current.rooms[roomIndex].sections[sectionIndex].types[
          typeIndex
        ].items[itemIndex].condition.out.notes.splice(noteIndex, 1);
        break;
      // Remove Item
      case !_isUndefined(roomIndex) &&
        !_isUndefined(sectionIndex) &&
        !_isUndefined(typeIndex) &&
        !_isUndefined(itemIndex):
        // console.log("🗑 Item", roomIndex, sectionIndex, typeIndex, itemIndex);
        state.current.rooms[roomIndex].sections[sectionIndex].types[
          typeIndex
        ].items.splice(itemIndex, 1);
        break;
      // Remove Type
      case !_isUndefined(roomIndex) &&
        !_isUndefined(sectionIndex) &&
        !_isUndefined(typeIndex):
        //console.log("🗑 Type", roomIndex, sectionIndex, typeIndex);
        state.current.rooms[roomIndex].sections[sectionIndex].types.splice(
          typeIndex,
          1
        );
        break;
      // Remove Section
      case !_isUndefined(roomIndex) && !_isUndefined(sectionIndex):
        //console.log("🗑 Section", roomIndex, sectionIndex);
        state.current.rooms[roomIndex].sections.splice(sectionIndex, 1);
        break;
      // Remove Room
      case !_isUndefined(roomIndex):
        //console.log("🗑 Room", roomIndex);
        state.current.rooms.splice(roomIndex, 1);
        break;
      default:
        throw Error("Unable to remove entity from Rooms");
    }
  },

  /**
   * Add a Signature
   */
  addSignature(state: AutoReportsState): void {
    state.current["signatures"] = state.current.signatures.concat([
      new Signature(),
    ]);
  },

  /**
   * Remove a Signature
   */
  removeSignature(state: AutoReportsState, signature: Signature): void {
    state.current["signatures"] = _reject(
      state.current.signatures,
      (x) => x._uuid === signature._uuid
    );
  },

  /**
   * Set a Signature property (signature.type, signature.name, etc)
   */
  setSignatureProperty(
    state: AutoReportsState,
    payload: { signature: Signature; property: string; val: string }
  ): void {
    const index = _findIndex(
      state.current.signatures,
      (x) => x._uuid === payload.signature._uuid
    );

    if (index === -1) {
      throw "Unable to find Signature";
    }

    state.current.signatures[index][payload.property] = payload.val;
  },

  setPageLimit(state: AutoReportsState, data: any): void {},

  setGallerydisplaymode(state: AutoReportsState, data: any): void {
    state.gallerydisplaymode = data;
  },
};
